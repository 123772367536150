import React, { useState } from "react";

import icon from ".";
import { toast } from "react-toastify";

function CheckboxGroup({
  options,
  register,
  watch,
  placholder,
  Ans,
  name,
  setValue,
  error,
  isImage, // Receive isImage prop to conditionally show file input
}) {
  const selectAns = watch(Ans);
  const [fileNames, setFileNames] = useState({});

  const handleCheckboxChange = (option) => {
    setValue("answerHi.option", option);
    setValue("answerEn.option", option);
  };

  const handleFileChange = (option, e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 6 ? name.slice(0, 6) + "..." : name;

      const img = new Image();
      img.onload = () => {
        if (
          Math.abs(img.width - 400) <= 100 &&
          Math.abs(img.height - 400) <= 100
        ) {
          setFileNames((prev) => ({
            ...prev,
            [option]: shortenedName + "." + extension,
          }));
        } else {
          toast.info("Option image dimensions should be approx 400 x 400");
          setFileNames("");
          setFileNames((prev) => ({
            ...prev,
            [option]:""
          }));
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };
  const handleTranslateInput = (input, optionName) => {
    fetch(
      `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
        input
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data[0] && data[0][0] && data[0][0][0]) {
          setValue(`optionsHi.${optionName}`, data[0][0][0]);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className=" flex gap-1">
      <div>
        {options.map((option, index) => (
          <div className="flex gap-4 items-center p-2 " key={index}>
            <div className="sm:text-center">
              <input
                id={`checkbox-option-${option?.option}`}
                type="checkbox"
                value={option?.option}
                checked={selectAns === option?.option}
                onChange={() => handleCheckboxChange(option?.option)}
                className="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 form-checkbox focus:ring-green-500 focus:border-green-500"
              />
            </div>
            <div className="w-2/2 flex p-1">
              {isImage ? (
                <label
                  htmlFor={`file-input-${option?.option}`}
                  className="flex items-center gap-1 w-max h-[2.70rem] mt-1 text-sm text-gray-500 pr-14 pl-2 py-0 border border-gray-50 rounded-lg bg-gray-50 cursor-pointer hover:bg-gray-100"
                >
                  <input
                    type="file"
                    id={`file-input-${option?.option}`} // Unique ID for file input
                    className="hidden"
                    onChange={(e) => handleFileChange(option?.option, e)}
                  />
                  <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                  <span className="w-full truncate">
                    {fileNames[option?.option]
                      ? fileNames[option?.option]
                      : option?.prevImage
                      ? (() => {
                          // Extract the filename and remove any query parameters
                          const fullFileName = option.prevImage
                            .split("/o/")[1]
                            ?.split("?")[0];
                          const nameParts = fullFileName.split(".");
                          const baseName = nameParts[0];
                          const extension = nameParts[nameParts.length - 1];

                          // Truncate the base name and add the extension
                          return baseName.length > 5
                            ? `${baseName.slice(0, 5)}...${extension}`
                            : fullFileName;
                        })()
                      : "Choose a file"}
                  </span>
                </label>
              ) : (
                // Text input shown when isImage is false
                <input
                  {...register(`${name}.${option?.option}`)}
                  type="text"
                  name={`${name}.${option?.option}`}
                  required
                  onChange={(e) => {
                    name == "optionsEn" &&
                      handleTranslateInput(e?.target?.value, option?.option);
                  }}
                  placeholder={`${placholder} ${option?.option}`}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 font-medium"
                />
              )}
            </div>

            {selectAns === option?.option && (
              <p className="text-green-600 self-center">Right Answer</p>
            )}
          </div>
        ))}
      </div>
      {/* <div className=" flex flex-col gap-3 ml-2 mt-4 ">
         {options?.map((item, ind) => {
           return <img src={item?.prevImage} className=" h-[18%] w-[30%]" />;
         })}
       </div> */}
    </div>
  );
}

export default CheckboxGroup;

// below is code for If Image preview is needed

// import React, { useState } from "react";
// import icon from ".";

// function CheckboxGroup({
//   options,
//   register,
//   watch,
//   placholder,
//   Ans,
//   name,
//   setValue,
//   error,
//   isImage, // Receive isImage prop to conditionally show file input
// }) {
//   const selectAns = watch(Ans);
//   const [fileNames, setFileNames] = useState({});
//   const [updatedImages, setUpdatedImages] = useState({}); // New state to store updated images

//   const handleCheckboxChange = (option) => {
//     setValue("answerHi.option", option);
//     setValue("answerEn.option", option);
//   };

//   const handleFileChange = (option, e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const name = file.name;
//       const extension = name.split(".").pop();
//       const shortenedName = name.length > 6 ? name.slice(0, 6) + "..." : name;

//       setFileNames((prev) => ({
//         ...prev,
//         [option]: shortenedName + "." + extension,
//       }));

//       const reader = new FileReader();
//       reader.onload = () => {
//         setUpdatedImages((prev) => ({
//           ...prev,
//           [option]: reader.result, // Store the base64 string of the image
//         }));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <div className="flex gap-1">
//       <div>
//         {options.map((option, index) => (
//           <div className="flex gap-4 items-center p-2" key={index}>
//             <div className="sm:text-center">
//               <input
//                 id={`checkbox-option-${option?.option}`}
//                 type="checkbox"
//                 value={option?.option}
//                 checked={selectAns === option?.option}
//                 onChange={() => handleCheckboxChange(option?.option)}
//                 className="w-5 h-5 text-green-600 bg-gray-100 border-gray-300 form-checkbox focus:ring-green-500 focus:border-green-500"
//               />
//             </div>
//             <div className="w-2/2 flex p-1">
//               {isImage ? (
//                 <>
//                   <label
//                     htmlFor={`file-input-${option?.option}`}
//                     className="flex items-center gap-1 w-max h-[2.70rem] mt-1 text-sm text-gray-500 pr-14 pl-2 py-0 border border-gray-50 rounded-lg bg-gray-50 cursor-pointer hover:bg-gray-100"
//                   >
//                     <input
//                       type="file"
//                       id={`file-input-${option?.option}`} // Unique ID for file input
//                       className="hidden"
//                       onChange={(e) => handleFileChange(option?.option, e)}
//                     />
//                     <img src={icon} className="w-6 h-6 p-1" alt="icon" />
//                     <span className="w-full truncate">
//                       {fileNames[option?.option]
//                         ? fileNames[option?.option]
//                         : option?.prevImage
//                         ? (() => {
//                             const fullFileName = option.prevImage
//                               .split("/o/")[1]
//                               ?.split("?")[0];
//                             const nameParts = fullFileName.split(".");
//                             const baseName = nameParts[0];
//                             const extension = nameParts[nameParts.length - 1];
//                             return baseName.length > 5
//                               ? `${baseName.slice(0, 5)}...${extension}`
//                               : fullFileName;
//                           })()
//                         : "Choose a file"}
//                     </span>
//                   </label>
//                   <div className=" h-[50px] w-[80px] ml-8 pl-6">
//                     {updatedImages[option.option] || option?.prevImage ? (
//                       <img
//                         src={
//                           updatedImages[option.option] || option?.prevImage // Show updated image if available, otherwise fallback to prevImage
//                         }
//                         className=" w-full object-fill h-full"
//                         alt="Uploaded or default"
//                         key={index}
//                       />
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 </>
//               ) : (
//                 <input
//                   {...register(`${name}.${option?.option}`)}
//                   type="text"
//                   name={`${name}.${option?.option}`}
//                   required
//                   placeholder={`${placholder} ${option?.option}`}
//                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 font-medium"
//                 />
//               )}
//             </div>

//             {selectAns === option?.option && (
//               <p className="text-green-600 self-center">Right Answer</p>
//             )}
//           </div>
//         ))}
//       </div>
//       {/* {isImage && (
//         <div className="flex flex-col gap-3 ml-2 mt-4">
//           {options?.map((item, ind) =>
//             updatedImages[item.option] || item?.prevImage ? (
//               <img
//                 src={
//                   updatedImages[item.option] || item?.prevImage // Show updated image if available, otherwise fallback to prevImage
//                 }
//                 className="h-[19%] w-[30%]"
//                 alt="Uploaded or default"
//                 key={ind}
//               />
//             ) : (
//               ""
//             )
//           )}
//         </div>
//       )} */}
//     </div>
//   );
// }

// export default CheckboxGroup;
