import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { getRewardDataRequest, updateRewardRequest } from "../../Actions";

import Loading from "../../components/loading";
import { useNavigate } from "react-router";
import { MdDelete, MdModeEditOutline } from "react-icons/md";
import { IoIosSend } from "react-icons/io";
import { PasswordModal } from "../../components/modal/passwordModal";
import TooltipATM from "../../components/ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

const RewardManagement = ({ rewardData, updateRewardData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedItem, setClickedItem] = useState("");
  const [rewardDataList, setRewardDataList] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);

  let rewardNames = [
    { Referral: "REFERRAL" },
    { "Referred Sign-Up": "REFERRED_SIGNUP" },
    { "Sign-Up": "SIGNUP" },
    { "First Quiz": "FIRST_QUIZ" },
    { "Second Quiz": "SECOND_QUIZ" },
    { "Third Quiz": "THIRD_QUIZ" },
  ];

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const onSubmit = (minAmount, maxAmount, isEnabled) => {
    const rewardName = rewardNames?.find(
      (itm) => itm?.[clickedItem?.rewardType]
    );
    const body = {
      data: {
        id: clickedItem?.id,
        rewardType: rewardName?.[clickedItem?.rewardType],
        minAmount: minAmount,
        maxAmount: maxAmount,
        isEnabled: isEnabled,
      },
      token: token,
    };
    dispatch(updateRewardRequest(body));
  };

  const getRewardData = () => {
    const body = {
      token: token,
    };
    dispatch(getRewardDataRequest(body));
  };

  useEffect(() => {
    getRewardData();
  }, [updateRewardData]);

  useEffect(() => {
    if (rewardData?.loading) {
      setIsLoading(true);
    }

    if (rewardData?.error) {
      setIsLoading(false);
    }

    if (rewardData?.rewardData?.data) {
      setRewardDataList(rewardData?.rewardData?.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [rewardData]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  const tableHeaders = [
    { name: "Scenario Label" },
    { name: "Minimum Amount" },
    { name: "Maximum Amount " },
    {
      name: "Active",
      msg: "If disabled, the reward will not be generated for the specific scenario. <br/>To stop providing rewards, disable the scenario instead of setting <br/>the minimum and maximum amount to 0.",
    },
    { name: "Action" },
  ];

  const handleEditClick = (item) => {
    let dataFormat = {
      id: item?.id,
      rewardType: item?.rewardType,
      name: item?.minAmount,
      maxAmount: item?.maxAmount,
      isEnabled: item?.isEnabled,
    };
    setClickedItem(dataFormat);
    setEditModalOpen(true);
  };

  const handleActiveToggleChange = (itm) => {
    const rewardName = rewardNames?.find((item) => item?.[itm?.rewardType]);
    const body = {
      data: {
        id: itm?.id,
        rewardType: rewardName?.[itm?.rewardType],
        minAmount: itm?.minAmount,
        maxAmount: itm?.maxAmount,
        isEnabled: !itm?.isEnabled,
      },
      token: token,
    };
    dispatch(updateRewardRequest(body));
  };

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4  rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="flex justify-start gap-1 items-center">
              <div className="font-bold uppercase text-lg text-center">
                Reward Management
              </div>
              <TooltipATM
                dataTooltipId={"reward-tooltip"}
                dataTooltipPlace={"right"}
                dataTooltipHtml={
                  "Lists all the scenarios where users will receive rewards. New scenarios<br/> can only be added from the backend, not from the admin panel."
                }
                className={" cursor-pointer"}
              />
            </div>
          </div>
          <>
            <div className="relative overflow-x-auto  shadow-md  sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        <div className=" flex gap-1 items-center justify-center">
                          {item.name}
                          {item?.msg && (
                            <TooltipATM
                              dataTooltipId={"reward-tooltip"}
                              dataTooltipPlace={"top-end"}
                              dataTooltipHtml={item?.msg}
                              className={"cursor-pointer"}
                            />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {rewardDataList?.map((item, index) => {
                    return (
                      <tr className="bg-white border-b " key={index}>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.rewardType}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.minAmount} </div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div>
                            <div>{item?.maxAmount}</div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center">
                          <div className="">
                            <div onClick={() => handleActiveToggleChange(item)}>
                              {item?.isEnabled ? (
                                <span className=" cursor-pointer border-2 rounded-lg px-4 py-2 border-green-300 text-green-500 hover:bg-green-50">
                                  Enabled
                                </span>
                              ) : (
                                <span className=" cursor-pointer border-2 px-4 py-2 rounded-lg border-red-300 hover:bg-red-50 text-red-500">
                                  Disabled
                                </span>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-2 text-center ">
                          <div className="flex gap-x-3 justify-center">
                            <div
                              onClick={() => handleEditClick(item)}
                              className={`hover:bg-gray-100 p-2 rounded-full cursor-pointer ${
                                //   !checkAccess("QUIZBOTPROBABILITY", "U")
                                //     ? "accessDenied"
                                //     : "hover:cursor-pointer"
                                ""
                              }`}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {isEditModalOpen && (
        <PasswordModal
          heading={`Update ${clickedItem?.rewardType} Scenario`}
          nameLabel={"Enter Min Amount"}
          secondNameLable={"Enter Max Amount"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={isEditModalOpen}
          setOpenModal={setEditModalOpen}
          flage={"reward"}
          data={clickedItem}
          // prevValue={`${rateArray.userThresholdForBotJoin}`}
        />
      )}

      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="reward-tooltip"
        className=" font-semibold"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    rewardData: state.getRewardDataReducer,
    updateRewardData: state?.updateRewardReducer,
  };
};

export default connect(mapStateToProps)(RewardManagement);
