import React from "react";
import "./Select.css";
import TooltipATM from "../ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const ModalOptionsSelector = ({
  id,
  header,
  initialValue,
  options,
  callbackFn,
  content="",place="top"
}) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-900 md:mb-2 "
      >
        {content !=="" ? (
          <div className="flex flex-row justify-start align-middle gap-1">
          {header}
          <div className="my-auto">
          <TooltipATM
            dataTooltipId={"add-question-tooltip"}
            dataTooltipPlace={place}
            dataTooltipHtml={content}
            className={"cursor-pointer"}
          />
        </div>
        </div>)
        :       ( header)      }
      </label>
      <select
        id={id}
        onChange={(e) => callbackFn(id, e.target.value)}
        className=" focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 mb-1 md:mb-2  font-medium  bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 sm:w-full"
      >
        {initialValue && (
          <option
            value={""}
            defaultValue={initialValue}
            className="sm:text-sm text-[#000000]"
          >
            {initialValue}
          </option>
        )}
        {options?.map((option, index) => (
          <option
            value={option.id}
            key={index}
            className="custom-option text-[#000000]"
          >
            {option.name}
          </option>
        ))}
      </select>
      
      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="add-question-tooltip"
        className=" font-semibold"
      />
    </div>
  );
};
