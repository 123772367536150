import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import BulkUpload from "../table/bulkUploadTable";
import { SECRET_KEY } from "../../Utils/Constants";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router";
import { MdArrowBack } from "react-icons/md";
import { Link } from "react-router-dom";
import ExamplePdf from "../../assets/Example-PDF-document.xlsx";
import { toast } from "react-toastify";
import { Pagination } from "../Pagination";
import refresh from "../../assets/images/refresh-arrow (1).png";

function MainComponent() {
  const [excelFile, setExcelFile] = useState(null);
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
      // "application/pdf", // pdf
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFile(e.target.result);
        };
      } else {
        setTypeError("Please select only csv ,xlsx & excel file types");
        setExcelFile(null);
      }
    }
  };

  const transformDataFormat = (originalData) => {
    const requiredColumns = [
      "questionEn",
      "questionHi",
      "optionAEn",
      "optionAHi",
      "optionBEn",
      "optionBHi",
      "optionCEn",
      "optionCHi",
      "optionDEn",
      "optionDHi",
      "correctOption",
      "level",
      "topicId",
    ];

    const missingColumns = requiredColumns?.filter(
      (column) => !originalData[0]?.hasOwnProperty(column)
    );

    if (missingColumns.length > 0) {
      toast.warning(`Missing columns: ${missingColumns?.join(", ")}`);
      return null;
    }

    return originalData.map((item) => {
      const topicId = parseInt(item?.topicId);

      if (isNaN(topicId) || typeof topicId !== "number") {
        toast.warning(
          "Invalid topicId. Please check that the topicId is a number."
        );
        return null;
      }

      const correctOption = item?.correctOption?.toUpperCase();
      if (!["A", "B", "C", "D"].includes(correctOption)) {
        toast.warning(
          "Please check the correctOption column in the Excel file."
        );
        return null;
      }
      const correctAnswerMapEn = {
        A: `A) ${item.optionAEn}`,
        B: `B) ${item.optionBEn}`,
        C: `C) ${item.optionCEn}`,
        D: `D) ${item.optionDEn}`,
      };
      const correctAnswerMapHi = {
        A: `A) ${item.optionAHi}`,
        B: `B) ${item.optionBHi}`,
        C: `C) ${item.optionCHi}`,
        D: `D) ${item.optionDHi}`,
      };

      return {
        questionEn: item.questionEn,
        questionHi: item.questionHi,
        optionAEn: item.optionAEn,
        optionAHi: item.optionAHi,
        optionBEn: item.optionBEn,
        optionBHi: item.optionBHi,
        optionCEn: item.optionCEn,
        optionCHi: item.optionCHi,
        optionDEn: item.optionDEn,
        optionDHi: item.optionDHi,
        correctOption: correctOption,
        correctOptionEn: correctAnswerMapEn[correctOption],
        correctOptionHi: correctAnswerMapHi[correctOption],
        level: item.level,
        topicId: topicId,
      };
      // const correctOptionEn = item?.correctOptionEn;
      // const correctOptionHi = item?.correctOptionHi;

      // if (
      //   correctOptionEn?.length !== 1 ||
      //   correctOptionHi?.length !== 1 ||
      //   typeof correctOptionEn !== "string" ||
      //   typeof correctOptionHi !== "string" ||
      //   correctOptionEn !== correctOptionEn.toUpperCase() ||
      //   correctOptionHi !== correctOptionHi.toUpperCase()
      // ) {
      //   toast.warning(
      //     "Please check the correct Option column in excel &  csv."
      //   );
      //   return null;
      // }

      // return {
      //   descriptionEn: item.questionEn,
      //   descriptionHi: item.questionHi,
      //   answerEn: { option: correctOptionEn, answer: item.answerEn },
      //   answerHi: { option: correctOptionHi, answer: item.answerHi },
      //   optionsEn: { A: item.A, B: item.B, C: item.C, D: item.D },
      //   optionsHi: { A: item.A1, B: item.B1, C: item.C1, D: item.D1 },
      //   level: item.level,
      //   topicId: topicId,
      // };
    });
  };

  const handleRefresh = () => {
    setExcelFile(null);
    setExcelData(null);
    setTypeError(null);
    document.getElementById("fileInput").value = "";
  };

  const [uploadCount, setUploadCount] = useState(null);

  useEffect(() => {
    if (uploadCount !== null) {
      toast.info(`Total ${uploadCount} Questions Uploaded`);
    }
  }, [uploadCount]);

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, {
        type: "buffer",
        encoding: "UTF-8",
        codepage: 65001,
        raw:false
      });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet, { raw: false });

      const levels = data.map((row) => row.level);
      const validLevels = ["Easy", "Medium", "Difficult"];
      const isValid = levels.every(
        (level) => level && validLevels.includes(level.trim())
      );

      if (!isValid) {
        toast.warning(
          "Please check the level. It should be 'Easy', 'Medium', or 'Difficult'."
        );
        return;
      }

      const transformedData = transformDataFormat(data);
      if (transformedData?.some((item) => item === null)) {
        console.error("Error in topicId validation");
      } else {
        setExcelData(transformedData);
        setCurrentPage(1);
        document.getElementById("fileInput").value = "";
        setUploadCount(transformedData?.length);
      }
    }
  };

  const navigate = useNavigate();
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = excelData?.slice(indexOfFirstItem, indexOfLastItem);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="mt-12 p-4">
      <div className="flex justify-between ">
        <div className="cursor-pointer">
          <span
            onClick={() => navigate(-1)}
            className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
          >
            <MdArrowBack className="text-base" /> Back
          </span>
        </div>
        <div>
          <Link
            to={ExamplePdf}
            download="Example-PDF-document"
            target="_blank"
            rel="noreferrer"
          >
            Download sample file
          </Link>
        </div>
      </div>
      <div className="mt-6">
        <div className="flex gap-4">
          <div>
            <h3 className="text-2xl font-bold mb-4">Upload Question File</h3>
            <p className="text-sm text-gray-400">
              upload only csv ,xlsx & excel
            </p>
          </div>
          <img
            src={refresh}
            alt="refresh"
            className="w-10 h-10 cursor-pointer"
            onClick={handleRefresh}
          />
        </div>
        <form className="flex items-center" onSubmit={handleFileSubmit}>
          <input
            id="fileInput"
            type="file"
            className="border rounded mr-2"
            required
            onChange={handleFile}
          />
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded"
          >
            UPLOAD
          </button>
          {typeError && <div className="text-red-600 ml-2">{typeError}</div>}
        </form>
      </div>
      <div className="viewer">
        {currentItems ? (
          <>
            <BulkUpload
              itemsArray={currentItems}
              uploadQuestions={excelData}
              token={token}
            />
            {Math.ceil(excelData?.length / itemsPerPage) > 1 && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={Math.ceil(excelData?.length / itemsPerPage)}
                  currentPage={currentPage}
                  setCurrentPage={paginate}
                  isLoading={false}
                />
              </div>
            )}
          </>
        ) : (
          <div className="mt-2">No File is uploaded yet!</div>
        )}
      </div>
    </div>
  );
}

export default MainComponent;
