import {
  REMOVE_ASSIGNED_QUESTIONS_REQUEST,
  REMOVE_ASSIGNED_QUESTIONS_SUCCESS,
  REMOVE_ASSIGNED_QUESTIONS_FAILURE,
} from "../Utils/Constants";
const initialState = {
  removeAssignedQuestions: {},
  loading: false,
  error: null,
};
const RemoveAssignedQuestionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ASSIGNED_QUESTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_ASSIGNED_QUESTIONS_SUCCESS:
      return {
        ...state,
        removeAssignedQuestions: action.payload,
        loading: false,
        error: null,
      };
    case REMOVE_ASSIGNED_QUESTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default RemoveAssignedQuestionsReducer;
