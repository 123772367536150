import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { loginRequest } from "../Actions/LogInAction";
import "bootstrap/dist/css/bootstrap.min.css";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { QuizHeaderLogo, LoginLogo } from "../assets/svg";
import { SECRET_KEY } from "../Utils/Constants";
import { SpinnerLoader } from "../components/Loaders/SpinnerLoader";
import { AlignLeft, Eye, EyeOff } from "react-feather";
import classNames from "classnames";
import { decrypt, encrypt } from "../Utils/RoleBasedRoute";
import {
  sendOtpLoginRequest,
  sendOtpLoginReset,
  verifyOtpLoginRequest,
} from "../Actions";
import { toast } from "react-toastify";
const excludedModules = ["BANK", "LEADERBOARD"];
const AdminLogin = ({ logInData, logInDataError, sendOtp, verifyOtp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [windowWidth1, setWindowWidth1] = useState(window.innerWidth);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timer, setTimer] = useState(30); // Timer set to 30 seconds
  const isMobileButton = windowWidth1 <= 380;
  const dispatch = useDispatch();
  // eslint-disable-line
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
    if (userToken) {
      const dataArray = decrypt("PRIVILEGES");
      const firstReadTrueFrontendPath = dataArray.find(
        (item) => item.readKey && !excludedModules.includes(item.moduleName)
      )?.frontendRoute;
      navigate(firstReadTrueFrontendPath);
    }
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth1(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (verifyOtp?.verifyOtp?.data?.accessToken) {
      const encrypted = CryptoJS.AES.encrypt(
        verifyOtp.verifyOtp.data.accessToken,
        SECRET_KEY
      ).toString();
      sessionStorage.setItem("myKey", encrypted);
    }
  }, [verifyOtp]);

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!email) {
      setEmailError("Please enter your email");
      return;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Please enter your password");
      return;
    } else if (password.length < 4) {
      setPasswordError("Password must be at least 4 characters long");
      return;
    } else {
      setPasswordError("");
    }

    const body = {
      emailId: email,
      password: password,
    };

    try {
      await dispatch(sendOtpLoginRequest(body));
      setIsOtpSent(true);
      startTimer(); // Start the timer when OTP is sent
    } catch (error) {
      console.error("Error sending OTP:", error);
      setIsOtpSent(false);
    }
  };

  useEffect(() => {
    if (sendOtp?.sendOtp?.statusCode === 200) {
      setShowOtpForm(true);
    } else {
      setShowOtpForm(false);
    }
  }, [sendOtp?.sendOtp?.statusCode]);

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    const body = {
      emailId: email,

      otp: otp,
    };
    dispatch(verifyOtpLoginRequest(body));
  };

  useEffect(() => {
    if (verifyOtp?.loading) {
      setIsLoggingIn(true);
    } else if (verifyOtp?.verifyOtp?.statusCode === 400) {
      setIsLoggingIn(false);
      toast.error("Invalid Username or Password");
    } else if (verifyOtp?.verifyOtp?.statusCode === 200) {
      // console.log(verifyOtp);

      setIsLoggingIn(false);
      encrypt("ROLE", verifyOtp?.verifyOtp?.data?.role, "S");
      encrypt(
        "PRIVILEGES",
        JSON.stringify(verifyOtp?.verifyOtp?.data?.privileges),
        "S"
      );
      const firstReadTrueFrontendPath =
        verifyOtp?.verifyOtp?.data?.privileges?.find(
          (item) => item.readKey && !excludedModules.includes(item.moduleName)
        )?.frontendRoute;

      navigate(firstReadTrueFrontendPath);
      setIsLoggingIn(false);
      // navigate("/categories");
    } else if (verifyOtp?.verifyOtp?.error) {
      toast.error("Error!!! Please try again");
    }
  }, [verifyOtp]);

  // Function to start the timer
  const startTimer = () => {
    setTimer(30); // Reset timer to 30 seconds
    setIsTimerActive(true); // Set timer active
  };

  // Function to handle timer countdown
  useEffect(() => {
    let interval;
    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1); // Decrement timer every second
      }, 1000);
    }
    // Clear interval when timer reaches 0
    if (timer === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timer]);

  // Function to handle resend OTP action
  const handleResendOtp = async () => {
    // Implement the logic to resend OTP here
    try {
      // Dispatch the action to resend OTP
      await dispatch(sendOtpLoginRequest({ emailId: email }));
      setIsOtpSent(true); // Update the state to reflect OTP resend
      startTimer(); // Start the timer again when OTP is resent
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  return (
    <div className="bg-light min-vh-100 align-items-center justify-content-center">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-10">
            <div className="card-group d-block d-md-flex row p-5 mb-0 mt-5 ">
              <div className="card p-0 d-block">
                <div className="card-body ">
                  <div className="flex justify-center items-center ">
                    <QuizHeaderLogo />
                  </div>
                  <h3 className="row justify-content-center">Login</h3>
                  <Form
                    onSubmit={showOtpForm ? handleVerifyOtp : handleSendOtp}
                  >
                    <Form.Group controlId="formEmail">
                      <Form.Label style={{ fontWeight: "bold" }}>
                        Email
                      </Form.Label>

                      <input
                        type="email"
                        className=" border border-gray-300 text-gray-900 text- rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => {
                          // if (showOtpForm) {
                          dispatch(sendOtpLoginReset());
                          // }
                          setEmail(e.target.value);
                        }}
                      />
                      {emailError && (
                        <Form.Label style={{ color: "red" }}>
                          {emailError}
                        </Form.Label>
                      )}
                    </Form.Group>

                    <div className="mt-2">
                      <Form.Group controlId="formPassword">
                        <Form.Label style={{ fontWeight: "bold" }}>
                          Password
                        </Form.Label>
                        <div className=" flex">
                          <input
                            type={showPassword ? "text" : "password"}
                            className=" border border-gray-300 text-gray-900 text- rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />

                          <div className="absolute right-4 ">
                            <button
                              type="button"
                              className="absolute right-4 top-3"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <i>
                                {showPassword ? (
                                  <EyeOff size={20} />
                                ) : (
                                  <Eye size={20} />
                                )}
                              </i>
                            </button>
                          </div>
                        </div>
                        {passwordError && (
                          <Form.Label style={{ color: "red" }}>
                            {passwordError}
                          </Form.Label>
                        )}
                      </Form.Group>
                    </div>

                    <div className="row mt-5 row align-items-center justify-content-center">
                      {!showOtpForm && (
                        <div className="row mt-5 row align-items-center justify-content-center">
                          <div
                            className={
                              !isMobileButton
                                ? "col-4 justify-content-center row align-items-center"
                                : "col-7 justify-content-center row align-items-center"
                            }
                          >
                            <Button type="button" onClick={handleSendOtp}>
                              <div className="flex items-center justify-center gap-2 font-semibold">
                                {sendOtp.loading ? (
                                  <SpinnerLoader size={"sm"} />
                                ) : (
                                  "Login"
                                )}
                              </div>
                            </Button>
                          </div>
                        </div>
                      )}

                      {/* Timer display */}

                      {/* Resend OTP Button */}
                    </div>

                    <div className="flex justify-center  items-center">
                      {showOtpForm && sendOtp?.sendOtp?.statusCode === 200 && (
                        <div className="flex justify-center items-center">
                          <div className="flex items-center flex-col gap-2">
                            <formLabel
                              style={{ fontWeight: "bold", AlignLeft }}
                            >
                              Enter a Valid otp
                            </formLabel>
                            <input
                              type="text"
                              className="w-150 h-10 border-1 px-2 my-2 rounded-md "
                              placeholder="Enter valid otp"
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            <Button
                              type="submit"
                              className="w-full font-semibold"
                            >
                              {verifyOtp.loading ? (
                                <SpinnerLoader size={"sm"} />
                              ) : (
                                "Verify & Login"
                              )}
                            </Button>
                            {showOtpForm && !isTimerActive && (
                              <div className=" ">
                                {/* <Button type="button" onClick={handleSendOtp}>
                                  Resend OTP
                                </Button> */}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
              <div
                className={
                  "card d-flex flex-row align-items-center justify-content-center d-none d-md-flex"
                }
              >
                <LoginLogo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    logInData: state?.LogInReducer,
    logInDataError: state?.LogInReducer?.error?.response?.data,
    sendOtp: state?.sendOtpWithLoginReducer,
    verifyOtp: state?.verifyOtpWithLoginReducer,
  };
};

export default connect(mapStateToProps)(AdminLogin);

// import React, { useEffect, useState } from "react";
// import { Button, Form } from "react-bootstrap";
// import { connect, useDispatch } from "react-redux";
// import { loginRequest } from "../Actions/LogInAction";
// import "bootstrap/dist/css/bootstrap.min.css";
// import CryptoJS from "crypto-js";
// import { useNavigate } from "react-router-dom";
// import { QuizHeaderLogo, LoginLogo } from "../assets/svg";

// import { SECRET_KEY } from "../Utils/Constants";
// import { SpinnerLoader } from "../components/Loaders/SpinnerLoader";
// import { toast } from "react-toastify";
// import { decrypt, encrypt } from "../Utils/RoleBasedRoute";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
// const excludedModules = ["BANK", "LEADERBOARD"];
// const AdminLogin = ({ logInData, logInDataError }) => {
//   const [showPassword, setShowPassword] = useState(false);
//   const handleTogglePassword = () => setShowPassword(!showPassword);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [validEmail, setValidEmail] = useState(false);
//   const [windowWidth1, setWindowWidth1] = useState(window.innerWidth);
//   const [isLoggingIn, setIsLoggingIn] = useState(false);

//   const isMobileButton = windowWidth1 <= 380;
//   const dispatch = useDispatch();
//   const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userToken =
//       sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
//     if (userToken) {
//       const dataArray = decrypt("PRIVILEGES");
//       const firstReadTrueFrontendPath = dataArray.find(
//         (item) => item?.readKey && !excludedModules.includes(item.moduleName)
//       )?.frontendRoute;
//       navigate(firstReadTrueFrontendPath);
//     }
//   }, [navigate]);

//   useEffect(() => {
//     const handleResize = () => {
//       setWindowWidth1(window.innerWidth);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   useEffect(() => {
//     if (logInData?.user?.data?.accessToken) {
//       const encrypted = CryptoJS.AES.encrypt(
//         logInData.user.data.accessToken,
//         SECRET_KEY
//       ).toString();
//       sessionStorage.setItem("myKey", encrypted);
//       localStorage.setItem("myKey", encrypted);
//     }
//   }, [logInData]);

//   // TODO: send notification token to login api
//   const handleLogin = (e) => {
//     e.preventDefault();
//     dispatch(loginRequest(email, password));
//   };

//   useEffect(() => {
//     const reg = emailRegex;
//     setValidEmail(!reg.test(email) && email !== "");
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [email]);

//   useEffect(() => {
//     if (logInData?.loading) {
//       setIsLoggingIn(true);
//     } else if (logInData?.user?.status === 400) {
//       setIsLoggingIn(false);
//       toast.error("Invalid Username or Password");
//     } else if (logInData?.user?.statusCode === 200) {
//       setIsLoggingIn(false);
//       encrypt("ROLE", logInData?.user?.data?.role, "B");
//       encrypt(
//         "PRIVILEGES",
//         JSON.stringify(logInData?.user?.data?.privileges),
//         "B"
//       );
//       const firstReadTrueFrontendPath = !excludedModules.includes('DASHBOARD')?"/": logInData?.user?.data?.privileges?.find(
//         (item) => item?.readKey && !excludedModules.includes(item.moduleName)
//       )?.frontendRoute;

//       navigate(firstReadTrueFrontendPath);
//     } else if (logInData?.error) {
//       toast.error("Error!!! Please try again");
//     }
//     if (logInData?.user?.status === 403) {
//       window.location.href = "/";
//     }
//   }, [logInData, navigate]);

//   return (
//     <div className="bg-[#e8f5e9] min-vh-100 align-items-center justify-content-center">
//       <div className="container">
//         <div className="row justify-content-center align-items-center">
//           <div className="col-lg-10">
//             <div className="card-group d-block d-md-flex row p-5 mb-0 mt-5">
//               <div className="card p-0 d-block">
//                 <div className="card-body">
//                   <div className="row justify-content-center">
//                     <QuizHeaderLogo />
//                   </div>
//                   <h3 className="row justify-content-center">Login</h3>
//                   <Form onSubmit={handleLogin}>
//                     <Form.Group controlId="formEmail">
//                       <Form.Label style={{ fontWeight: "bold" }}>
//                         Email
//                       </Form.Label>
//                       <Form.Control
//                         type="email"
//                         placeholder="Enter email"
//                         value={email}
//                         onChange={(e) => setEmail(e.target.value)}
//                         style={{ borderRadius: "" }}
//                       />
//                       {validEmail && (
//                         <Form.Label style={{ color: "red" }}>
//                           Please enter a valid email address
//                         </Form.Label>
//                       )}
//                     </Form.Group>
//                     <div className="mt-2">
//                       <Form.Group controlId="formPassword">
//                         <Form.Label className="font-weight-bold">
//                           Password
//                         </Form.Label>
//                         <div className="position-relative">
//                           <Form.Control
//                             type={showPassword ? "text" : "password"}
//                             placeholder="Enter password"
//                             value={password}
//                             onChange={(e) => setPassword(e.target.value)}
//                           />
//                           <span
//                             className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
//                             onClick={handleTogglePassword}
//                           >
//                             {showPassword ? <FaEyeSlash /> : <FaEye />}
//                           </span>
//                         </div>
//                       </Form.Group>
//                     </div>
//                     <div className="row mt-5 row align-items-center justify-content-center">
//                       <div
//                         className={
//                           !isMobileButton
//                             ? "col-4 justify-content-center row align-items-center"
//                             : "col-7 justify-content-center row align-items-center"
//                         }
//                       >
//                         <Button type="submit">
//                           <div className="flex items-center justify-center gap-2 font-semibold">
//                             {isLoggingIn ? (
//                               <SpinnerLoader size={"sm"} />
//                             ) : (
//                               "Login"
//                             )}
//                           </div>
//                         </Button>
//                       </div>
//                     </div>
//                   </Form>
//                 </div>
//               </div>
//               <div
//                 className={
//                   "card d-flex flex-row align-items-center justify-content-center d-none d-md-flex"
//                 }
//               >
//                 <LoginLogo />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     logInData: state?.LogInReducer,
//     logInDataError: state?.LogInReducer?.error?.response?.data,
//   };
// };

// export default connect(mapStateToProps)(AdminLogin);
