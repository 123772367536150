
import { put, call } from "redux-saga/effects";
import {GetAvailableQuestionsCount} from "../../components/Config/Apiinterface";
import {
    availableQuestionsCountFailure,
    availableQuestionsCountSuccess,
} from "../../Actions";
import debounce from "../../Utils/useDebounce";

// ---------------get available questions-------------------

export function* availableQuestionsCountSaga(action) {
  const topicId  = action.payload.topicId;
  const isFree = action.payload.isFree;
  const path = `quiz/quiz/availableQuestionsCount?topicId=${topicId}&isFree=${isFree}`;
  try {
    const quiestionAvailable = yield debounce(GetAvailableQuestionsCount, action.payload.token, path);    
    yield put(availableQuestionsCountSuccess(quiestionAvailable));
  } catch (error) {
    yield put(availableQuestionsCountFailure(error));
  }
}

