// import React, { useState, useEffect, useMemo } from "react";
// import { useNavigate } from "react-router";
// import { capitalizeWords } from "../../Utils/capitalizeFn";
// import { fetchAnalyticsData } from "../../Utils/fetchAnalyticsData";
// import { Pagination } from "../../components/Pagination"; // Assuming you already have this component

// const tableHeaders = ["Scenario", "Total Impressions", "Action"];

// const DashboardTable = ({ data }) => {
//   const navigate = useNavigate();

//   // Memoize the result from fetchAnalyticsData to avoid recalculating on every render
//   const { scenarioMap, uniqueScenarios } = useMemo(
//     () => fetchAnalyticsData(data),
//     [data]
//   );

//   const [paginatedData, setPaginatedData] = useState([]);
//   const [totalPages, setTotalPages] = useState(0);
//   const [currentPage, setCurrentPage] = useState(1);

//   const itemsPerPage = 20; 

//   useEffect(() => {
//     if (uniqueScenarios.length) {
//       setTotalPages(Math.ceil(uniqueScenarios.length / itemsPerPage));
//       paginateData(uniqueScenarios, 1);
//     }
//   }, [uniqueScenarios]); 

//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//     paginateData(uniqueScenarios, newPage);
//   };

//   const paginateData = (data, page) => {
//     const startIndex = (page - 1) * itemsPerPage;
//     const endIndex = startIndex + itemsPerPage;
//     setPaginatedData(data.slice(startIndex, endIndex));
//   };

//   return (
//     <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
//       <table className="w-full text-sm text-left text-gray-700">
//         <thead className="text-xs text-gray-700 uppercase bg-gray-50">
//           <tr>
//             {tableHeaders.map((item, index) => (
//               <th key={index} scope="col" className="px-6 py-3 text-start">
//                 {item}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {paginatedData.map((scenario, index) => {
//             const platforms = scenarioMap[scenario].platforms;
//             const platformDetails = Object.keys(platforms)
//               .map((platform) => `${platform} (${platforms[platform].count})`)
//               .join(", ");
//             const eventList = Object.values(platforms).flatMap(
//               (platformData) => platformData.eventDetails
//             );

//             return (
//               <tr className="bg-white border-b" key={index}>
//                 <td className="px-6 py-2 text-start">
//                   <div>{capitalizeWords(scenario)}</div>
//                 </td>
//                 <td className="px-6 py-2 text-start">
//                   <div>
//                     {Object.values(platforms).reduce(
//                       (total, platformData) => total + platformData.count,
//                       0
//                     )}
//                   </div>
//                 </td>
//                 <td className="px-6 py-2 text-start">
//                   <div
//                     className="text-blue-500 cursor-pointer"
//                     onClick={() => {
//                       navigate("/dashboardAnalytics", {
//                         state: { scenario, eventList },
//                       });
//                     }}
//                   >
//                     View Details
//                   </div>
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>

//       {totalPages > 1 && (
//         <div className="flex justify-end pr-5 pt-4">
//           <Pagination
//             totalPages={totalPages}
//             currentPage={currentPage}
//             setCurrentPage={handlePageChange}
//             isLoading={false} // Adjust if you have loading state
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default DashboardTable;

// // import React, { useState, useEffect } from "react";
// // import { useNavigate } from "react-router";
// // import { capitalizeWords } from "../../Utils/capitalizeFn";
// // import { fetchAnalyticsData } from "../../Utils/fetchAnalyticsData";
// // import { Pagination } from "../../components/Pagination";

// // const tableHeaders = ["Scenario", "Total Impressions", "Action"];

// // const DashboardTable = ({ data }) => {
// //   const navigate = useNavigate();

// //   // Pagination states
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [totalPages, setTotalPages] = useState(0);
// //   const [paginatedData, setPaginatedData] = useState([]);

// //   const itemsPerPage = 20;

// //   // Fetch and structure data
// //   const { scenarioMap, uniqueScenarios } = fetchAnalyticsData(data);

// //   // Pagination logic
// //   const paginateData = (data, page) => {
// //     const startIndex = (page - 1) * itemsPerPage;
// //     const endIndex = startIndex + itemsPerPage;
// //     return data.slice(startIndex, endIndex);
// //   };

// //   useEffect(() => {
// //     // Calculate total pages
// //     setTotalPages(Math.ceil(uniqueScenarios.length / itemsPerPage));

// //     // Paginate data
// //     const paginated = paginateData(uniqueScenarios, currentPage);
// //     setPaginatedData(paginated);
// //   }, [uniqueScenarios, currentPage]);

// //   const handlePageChange = (newPage) => {
// //     setCurrentPage(newPage);
// //   };

// //   return (
// //     <>
// //       <table className="w-full text-sm text-left text-gray-700">
// //         <thead className="text-xs text-gray-700 uppercase bg-gray-50">
// //           <tr>
// //             {tableHeaders.map((item, index) => (
// //               <th key={index} scope="col" className="px-6 py-3 text-start">
// //                 {item}
// //               </th>
// //             ))}
// //           </tr>
// //         </thead>
// //         <tbody>
// //           {paginatedData.map((scenario, index) => {
// //             const platforms = scenarioMap[scenario].platforms;
// //             const platformDetails = Object.keys(platforms)
// //               .map((platform) => `${platform} (${platforms[platform].count})`)
// //               .join(", ");
// //             const eventList = Object.values(platforms).flatMap(
// //               (platformData) => platformData.eventDetails
// //             );

// //             return (
// //               <tr className="bg-white border-b" key={index}>
// //                 <td className="px-6 py-2 text-start">
// //                   <div>{capitalizeWords(scenario)}</div>
// //                 </td>
// //                 <td className="px-6 py-2 text-start">
// //                   <div>
// //                     {Object.values(platforms).reduce(
// //                       (total, platformData) => total + platformData.count,
// //                       0
// //                     )}
// //                   </div>
// //                 </td>
// //                 <td className="px-6 py-2 text-start">
// //                   <div
// //                     className="text-blue-500 cursor-pointer"
// //                     onClick={() => {
// //                       navigate("/dashboardAnalytics", {
// //                         state: { scenario, eventList },
// //                       });
// //                     }}
// //                   >
// //                     View Details
// //                   </div>
// //                 </td>
// //               </tr>
// //             );
// //           })}
// //         </tbody>
// //       </table>

// //       {totalPages > 1 && (
// //         <div className="flex justify-end pr-5 pt-4">
// //           <Pagination
// //             totalPages={totalPages}
// //             currentPage={currentPage}
// //             setCurrentPage={handlePageChange}
// //             isLoading={false}
// //           />
// //         </div>
// //       )}
// //     </>
// //   );
// // };

// // export default DashboardTable;
import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import { capitalizeWords } from "../../Utils/capitalizeFn";
import { fetchAnalyticsData } from "../../Utils/fetchAnalyticsData";
import { Pagination } from "../../components/Pagination"; // Assuming you already have this component

const tableHeaders = [
  "Scenario",
  "Total Impressions",
  "Action",
];

const DashboardTable = ({ data }) => {
  const navigate = useNavigate();

  // Memoize the result from fetchAnalyticsData to avoid recalculating on every render
  const { scenarioMap, uniqueScenarios } = useMemo(
    () => fetchAnalyticsData(data),
    [data]
  );
 
  const [paginatedData, setPaginatedData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 20;

  useEffect(() => {
    if (uniqueScenarios.length) {
      setTotalPages(Math.ceil(uniqueScenarios.length / itemsPerPage));
      paginateData(uniqueScenarios, 1);
    }
  }, [uniqueScenarios]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    paginateData(uniqueScenarios, newPage);
  };

  const paginateData = (data, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setPaginatedData(data.slice(startIndex, endIndex));
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
      <table className="w-full text-sm text-left text-gray-700">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {tableHeaders.map((item, index) => (
              <th key={index} scope="col" className="px-6 py-3 text-start">
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((scenario, index) => {
            const platforms = scenarioMap[scenario].platforms;
            const platformDetails = Object.keys(platforms)
              .map((platform) => `${platform} (${platforms[platform].count})`)
              .join(", ");
            const eventList = Object.values(platforms).flatMap(
              (platformData) => platformData.eventDetails
            );
            const screenName = data[index].screen_name; 
            const datetime = new Date(data[index].datetime).toLocaleString(); 

            return (
              <tr className="bg-white border-b" key={index}>
                <td className="px-6 py-2 text-start">
                  <div>{capitalizeWords(scenario)}</div>
                </td>
                <td className="px-6 py-2 text-start">
                  <div>
                    {Object.values(platforms).reduce(
                      (total, platformData) => total + platformData.count,
                      0
                    )}
                  </div>
                </td>
                <td className="px-6 py-2 text-start">
                  <div
                    className="text-blue-500 cursor-pointer"
                    onClick={() => {
                      navigate("/dashboardAnalytics", {
                        state: { scenario, eventList, screenName, datetime },
                      });
                    }}
                  >
                    View Details
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {totalPages > 1 && (
        <div className="flex justify-end pr-5 pt-4">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
            isLoading={false} // Adjust if you have loading state
          />
        </div>
      )}
    </div>
  );
};

export default DashboardTable;
