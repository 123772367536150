import {
    getReferredToFailure,
    getReferredToSuccess,
} from "../../Actions";
import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import { toast } from "react-toastify";

export function* getReferredToSaga(action) {

  const token = action.payload.token;
  const referrerId = action.payload.referrerId;
  const pageNo = action.payload.pageNo;
  const path = `admin/user/referredBy?pageNo=${pageNo}&pageSize=4&referrerId=${referrerId}`;
  
//   console.log("GET REFERRED TO SAGA FETCHING", referrerId,'pgno:',pageNo)

  try {
    const response = yield call(GetAPI, token, path);
    yield put(getReferredToSuccess(response));
    // toast.success(response.message);
  } catch (error) {
    yield put(getReferredToFailure(error));
  }
}
