import * as CONST from "../../Utils/Constants";

export const availableQuestionsCountRequest = (payload) => {
  return {
    type: CONST.GET_AVAILABLE_QUESTION_COUNT_REQUEST,
    payload: payload,
  };
};

export const availableQuestionsCountSuccess = (data) => {
  return {
    type: CONST.GET_AVAILABLE_QUESTION_COUNT_SUCCESS,
    payload: data,
  };
};

export const availableQuestionsCountFailure = (error) => {
  return {
    type: CONST.GET_AVAILABLE_QUESTION_COUNT_FAILURE,
    payload: error,
  };
};
