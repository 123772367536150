import React, { useState } from "react";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { style } from "../constant";
import doenloadPng from "../../assets/images/download.png";
import jsPDF from "jspdf";
import { toast } from "react-toastify";

export default function Table2(props) {
  const {
    itemsArray,
    searchByQuestionId,
    handleCheckboxChange,
    checkedBoxes,
    handleCheckboxId,
    noOfQuestions,
    questions,
  } = props;

  

  return (
    <div className="bg-white  rounded-lg min-h-[55vh] gap-2 top-10">
      <div>
        <button
          className={` my-4 ${style.buttonClass}`}
          onClick={() => handleCheckboxId()}
        >
          Save Questions
        </button>
      </div>
      {itemsArray?.length ? (
        <>
          <div className="relative overflow-x-auto sm:rounded-lg mt-4">
            {itemsArray?.map((item, index) => (
              <div className="bg-white border-b" key={index}>
                <div className="flex flex-col sm:flex-row items-center px-3 py-2 sm:py-4 gap-4">
                  <input
                    type="checkbox"
                    onClick={() => handleCheckboxChange(item.id)}
                    checked={checkedBoxes?.includes(item.id)}
                  />
                  <h5>{`Question Id #${item.id}`}</h5>
                  <h5>{`Utilization Score - ${item.selectCount}`}</h5>
                  <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div>{item.level}</div>
                  </div>
                  <div className="flex self-center bg-yellow-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div className="">{item.topic.name}</div>
                  </div>
                </div>

                <div className="flex flex-wrap flex-row">
                  <div>
                    <td className="px-6 py-2 ">
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}
                      <ReadMoreLess
                        descriptionEn={item.descriptionEn}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">
                      A :
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsEn?.A}
                    </h6>
                    <h6 className="px-6 py-2">
                      B :
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsEn?.B}
                    </h6>
                    <h6 className="px-6 py-2">
                      C :{" "}
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}
                      {item.optionsEn?.C}
                    </h6>
                    <h6 className="px-6 py-2">
                      D :
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsEn?.D}
                    </h6>{" "}
                    <h6 className="px-6 py-2">
                      {" "}
                      {`Ans:- ${item.answerEn?.option}) ` +
                        item.answerEn?.answer}
                    </h6>
                  </div>
                  <div>
                    <td className="px-6 py-2 ">
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}
                      <ReadMoreLess
                        descriptionEn={item.descriptionHi}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">
                      A :{" "}
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsHi?.A}
                    </h6>
                    <h6 className="px-6 py-2">
                      B :
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsHi?.B}
                    </h6>
                    <h6 className="px-6 py-2">
                      C :
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}{" "}
                      {item.optionsHi?.C}
                    </h6>
                    <h6 className="px-6 py-2">
                      D :{" "}
                      {item.imageUrl ? (
                        <img src={item?.imageUrl} alt={item.imageUrl} />
                      ) : (
                        ""
                      )}
                      {item.optionsHi?.D}
                    </h6>{" "}
                    <h6 className="px-6 py-2">
                      {`Ans:- ${item.answerHi?.option}) ` +
                        item.answerHi?.answer}
                    </h6>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="font-semibold text-center pt-24">
          No Question Found !!!
        </div>
      )}
    </div>
  );
}
