import { put, call } from "redux-saga/effects";
import { AddCategoriesFailure, AddCategoriesSuccess } from "../Actions";
import { AddCategoryAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Add Category request
function* AddCategorySaga(action) {

  const formdata = new FormData();
  formdata.append("categoryImage", action.payload.image);

  const name = action.payload.nameEng;
  const nameHin = action.payload.nameHin;
  const token = action.payload.token;
  try {
    const NewCategory = yield debounce(AddCategoryAPI, token, name,nameHin, formdata);
    yield put(AddCategoriesSuccess(NewCategory));
  } catch (error) {
    yield put(AddCategoriesFailure(error));
  }
}

export default AddCategorySaga;
