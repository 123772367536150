export const responseObject = {
  statusCode: 200,
  message: "Question fetched successfully",
  data: {
    totalRecords: 11518,
    recordsPresent: 2,
    totalPages: 5759,
    currentPage: 1,
    questions: [
      {
        createDate: new Date("2024-12-02T11:23:56"),
        updateDate: new Date("2024-12-02T11:23:56"),
        id: 11539,
        descriptionEn: {
          text: "What food item is being shown in the image?",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/7d8ed7cc-ba7f-4556-be62-3b45f4e06bbd.jpg?alt=media",
        },
        descriptionHi: {
          text: "चित्र में कौन सा खाद्य पदार्थ दिखाया जा रहा है?",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/7d8ed7cc-ba7f-4556-be62-3b45f4e06bbd.jpg?alt=media",
        },
        optionsEn: {
          A: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/bee39091-91a1-4e27-bc5c-9e7e7f4f995e.jpg?alt=media",
          },
          B: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/8dd269a3-c592-40ad-a102-a42a39a095f0.png?alt=media",
          },
          C: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/9e063e92-606e-41a3-abd5-374bd9a323be.png?alt=media",
          },
          D: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/95561eb1-090e-43d4-a5ba-e289f65bbd9b.png?alt=media",
          },
        },
        optionsHi: {
          A: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/bee39091-91a1-4e27-bc5c-9e7e7f4f995e.jpg?alt=media",
          },
          B: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/8dd269a3-c592-40ad-a102-a42a39a095f0.png?alt=media",
          },
          C: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/9e063e92-606e-41a3-abd5-374bd9a323be.png?alt=media",
          },
          D: {
            text: "",
            imageUrl:
              "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/95561eb1-090e-43d4-a5ba-e289f65bbd9b.png?alt=media",
          },
        },
        answerEn: {
          option: "A",
          answer: "",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/bee39091-91a1-4e27-bc5c-9e7e7f4f995e.jpg?alt=media",
        },
        answerHi: {
          option: "A",
          answer: "",
          imageUrl:
            "https://firebasestorage.googleapis.com/v0/b/quiznation-cosmostaker.appspot.com/o/bee39091-91a1-4e27-bc5c-9e7e7f4f995e.jpg?alt=media",
        },
        level: "Easy",
        isFree: true,
        categoryId: 23,
        topicId: 21,
        category: {
          createDate: new Date("2024-11-30T11:29:39"),
          updateDate: new Date("2024-11-30T11:29:39"),
          id: 23,
          name: "ImageQuestionCategory",
          imagePath: "",
          enabled: true,
        },
        topic: {
          createDate: new Date("2024-11-30T11:29:55"),
          updateDate: new Date("2024-11-30T11:29:55"),
          id: 21,
          name: "ImageQuestionTopic",
          imagePath: null,
          enabled: true,
          categoryId: 23,
        },
        isImagePresentInQuestion: true,
        isImagePresentInOptions: true,
      },
      {
        createDate: new Date("2024-08-03T13:32:52"),
        updateDate: new Date("2024-08-03T13:32:52"),
        id: 11520,
        descriptionEn: {
          text: "Select the pair which is different: (10:30, 12:36, 14:42, 16:45).",
          imageUrl: "",
        },
        descriptionHi: {
          text: "वह जोड़ी चुनें जो भिन्न हो: (10:30, 12:36, 14:42, 16:45)।",
          imageUrl: "",
        },
        optionsEn: {
          A: {
            text: "10:30",
            imageUrl: "",
          },
          B: {
            text: "12:36",
            imageUrl: "",
          },
          C: {
            text: "14:42",
            imageUrl: "",
          },
          D: {
            text: "16:45",
            imageUrl: "",
          },
        },
        optionsHi: {
          A: {
            text: "10:30",
            imageUrl: "",
          },
          B: {
            text: "12:36",
            imageUrl: "",
          },
          C: {
            text: "14:42",
            imageUrl: "",
          },
          D: {
            text: "16:45",
            imageUrl: "",
          },
        },
        answerEn: {
          option: "D",
          answer: "16:45",
          imageUrl: "",
        },
        answerHi: {
          option: "D",
          answer: "16:45",
          imageUrl: "",
        },
        level: "Easy",
        isFree: true,
        categoryId: 23,
        topicId: 21,
        category: {
          createDate: new Date("2024-11-30T11:29:39"),
          updateDate: new Date("2024-11-30T11:29:39"),
          id: 23,
          name: "ImageQuestionCategory",
          imagePath: "",
          enabled: true,
        },
        topic: {
          createDate: new Date("2024-11-30T11:29:55"),
          updateDate: new Date("2024-11-30T11:29:55"),
          id: 21,
          name: "ImageQuestionTopic",
          imagePath: null,
          enabled: true,
          categoryId: 23,
        },
        isImagePresentInQuestion: false,
        isImagePresentInOptions: false,
      },
    ],
  },
};
