import {
  GET_BANNER_DATA_REQUEST,
  GET_BANNER_DATA_SUCCESS,
  GET_BANNER_DATA_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  bannerData: {},
  loading: false,
  error: null,
};

const getBannerDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNER_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_BANNER_DATA_SUCCESS:
      return {
        ...state,
        bannerData: action.payload,
        loading: false,
        error: null,
      };

    case GET_BANNER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getBannerDataReducer;
