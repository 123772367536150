import React, { useState } from "react";

const ReadMoreLess = (props) => {
  const { descriptionEn, maxLength } = props;
  const [isFullDescriptionVisible, setIsFullDescriptionVisible] =
    useState(false);

  const toggleDescription = () => {
    setIsFullDescriptionVisible(!isFullDescriptionVisible);
  };

  // Ensure descriptionEn is a string
  const safeDescription =
    typeof descriptionEn === "string" ? descriptionEn : "";

  return (
    <div className={`max-w-md`}>
      <p
        className={`font-bold text-sm ${
          isFullDescriptionVisible ? "block" : "line-clamp-3"
        } ${safeDescription.length > maxLength && "text-center"}`}
      >
        {isFullDescriptionVisible
          ? safeDescription
          : safeDescription.slice(0, maxLength)}
      </p>
      {safeDescription.length > maxLength && (
        <button
          onClick={toggleDescription}
          className="text-blue-500 flex float-right -mt-4 text-sm focus:outline-none"
        >
          {isFullDescriptionVisible ? "Read Less" : "Read More"}
        </button>
      )}
    </div>
  );
};

export default ReadMoreLess;
