import {
  GET_BANNER_DATA_REQUEST,
  GET_BANNER_DATA_SUCCESS,
  GET_BANNER_DATA_FAILURE,
} from "../../Utils/Constants";

export const getBannerDataRequest = (payload) => {
  return {
    type: GET_BANNER_DATA_REQUEST,
    payload: payload,
  };
};

export const getBannerDataSuccess = (data) => {
  return {
    type: GET_BANNER_DATA_SUCCESS,
    payload: data,
  };
};

export const getBannerDataFailure = (error) => {
  return {
    type: GET_BANNER_DATA_FAILURE,
    payload: error,
  };
};
