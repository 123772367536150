import { put, call, delay } from "redux-saga/effects";
import { AddTopicFailure, AddTopicSuccess } from "../Actions";
import { AddTopicAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";

// Worker saga for handling Add Topic request
function* AddTopicSaga(action) {
  const formdata = new FormData();
  formdata.append("topicImage", action.payload.image);

  const categoryId = action.payload.categoryId;
  const topicName = action.payload.nameEng;
  const topicNameHindi = action.payload.nameHin;
  const token = action.payload.token;
  try {
    const NewTopic = yield debounce(
      AddTopicAPI,
      token,
      topicName,
      topicNameHindi,
      formdata,
      categoryId
    );
    yield delay(2000);
    yield put(AddTopicSuccess(NewTopic));
  } catch (error) {
    yield put(AddTopicFailure(error));
  }
}

export default AddTopicSaga;
