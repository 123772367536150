import { put, call } from "redux-saga/effects";
import { removeAssignedQuestionSuccess, removeAssignQuestionFailure } from "../Actions";
import { DeleteAPI } from "../components/Config/Apiinterface";
import debounce from "../Utils/useDebounce";
import { toast } from "react-toastify";

// Worker saga for handling DeleteCtegory request
function* RemoveAssignedQuestionsSaga(action) {
  const token = action.payload.token;
  const quizId = action.payload.data.quizId;
  const userId = action.payload.data.userId;
  const path = `admin/quiz/player/deleteAssignedQuestions?quizId=${quizId}&userId=`;

  const ToastId = toast.loading('Deleting ..')
  try {
    const response = yield debounce(DeleteAPI, token, userId, path);
    
    yield put(removeAssignedQuestionSuccess(response));

    if (response.statusCode === 200) {
      toast.update(ToastId, {
        render: response?.message,
        autoClose: 2000,
        isLoading: false,
        type: "success",
      });
    } else {
      // console.log(response,'err response')
      toast.update(ToastId, {
        render: response?.message || 'Already on Random Mode',
        type: "info",
        autoClose: 2000,
        isLoading: false,
      });
    }
  } catch (error) {
    // console.log(error,'errr')
    yield put(removeAssignQuestionFailure(error));
  }
}

export default RemoveAssignedQuestionsSaga;
