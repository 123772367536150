import {
  ASSIGN_QUESTION_REQUEST,
  ASSIGN_QUESTION_SUCCESS,
  ASSIGN_QUESTION_FAILURE,
  ASSIGN_QUESTION_RESET,
} from "../Utils/Constants";

const initialState = {
  assignQuestion: {},
  loading: false,
  error: null,
};

const AssignQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_QUESTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ASSIGN_QUESTION_SUCCESS:
      return {
        ...state,
        assignQuestion: action.payload,
        loading: false,
        error: null,
      };
    case ASSIGN_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ASSIGN_QUESTION_RESET:
      return {
        assignQuestion: {},
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default AssignQuestionReducer;
