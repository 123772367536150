import { getCurrentWeekDateRange, getDateRange } from "./weekDates";

//  SECRET KEY FOR TOKEN ENCRYPTION
export const SECRET_KEY = "djg@d43$7Vtys&Dv";

// PROPERTY ID FOR ANALYTICS
export const PROPERTY_ID = "405837494";
// export const PROPERTY_ID = "420053425";

//  SET ENV FOR APPLICATION
// export const APPLICATION_ENVIRONMENT = "STAGING";
export const APPLICATION_ENVIRONMENT = "PRODUCTION";

//  URL FOR
export const STAGING_URL = "https://stag-api.quiznation.in/quiznation/v1/";
// export const STAGING_URL = "http://192.168.1.31:8000/quiznation/v1/";
export const PRODUCTION_URL = "https://api.quiznation.in/quiznation/v1/";

// debouncing timeout
export const delayTime = 1000;

// DONT CHNAGES POSSTION OF QURIES FOR DASHBOARD ANALYTICS
export const queries = [
  // 1. NUMBER OF ACTIVE USER IN PREVIOUS AND CURRENT YEAR
  {
    dimensions: [{ name: "year" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [
      {
        startDate: `${new Date().getFullYear() - 1}-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
      },
    ],
    orderBys: [
      {
        dimension: {
          orderType: "ALPHANUMERIC",
          dimensionName: "year",
        },
      },
    ],
  },
  // 2. MOUNTH WISE ACTIVE USER IN CURRENT YEAR
  {
    dimensions: [{ name: "month" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [
      {
        startDate: `${new Date().getFullYear()}-01-01`,
        endDate: `${new Date().getFullYear()}-12-31`,
      },
    ],
    orderBys: [
      {
        dimension: {
          orderType: "ALPHANUMERIC",
          dimensionName: "month",
        },
      },
    ],
  },
  // 3. NUMBER OF ACTIVE USER IN PREVIOUS AND CUREENT DAY
  {
    dimensions: [{ name: "day" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [
      {
        startDate: "3daysAgo",
        endDate: "today",
      },
    ],
    orderBys: [
      {
        dimension: {
          orderType: "ALPHANUMERIC",
          dimensionName: "day",
        },
      },
    ],
  },
  // 4. DAY WISE ACTIVE USER IN CURRENT WEEK
  {
    dimensions: [{ name: "dayOfWeekName" }, { name: "day" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [
      {
        startDate: getCurrentWeekDateRange().startDate,
        endDate: getCurrentWeekDateRange().endDate,
      },
    ],
    orderBys: [
      {
        dimension: {
          orderType: "ALPHANUMERIC",
          dimensionName: "day",
        },
      },
    ],
  },
  // 6. GET LATEST VERSIONS OF IOS APP
  {
    dimensions: [{ name: "appVersion" }, { name: "operatingSystem" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [{ startDate: "2019-01-01", endDate: "today" }],
    dimensionFilter: {
      filter: {
        fieldName: "operatingSystem",
        stringFilter: { matchType: "EXACT", value: "iOS" },
      },
    },
    limit: "1",
    orderBys: [
      {
        dimension: {
          orderType: "CASE_INSENSITIVE_ALPHANUMERIC",
          dimensionName: "appVersion",
        },
        desc: true,
      },
    ],
    metricAggregations: ["TOTAL"],
  },
  // 6. GET LATEST VERSIONS OF Android APP
  {
    dimensions: [{ name: "appVersion" }, { name: "operatingSystem" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [{ startDate: "2019-01-01", endDate: "today" }],
    dimensionFilter: {
      filter: {
        fieldName: "operatingSystem",
        stringFilter: { matchType: "EXACT", value: "android" },
      },
    },
    limit: "1",
    orderBys: [
      {
        dimension: {
          orderType: "CASE_INSENSITIVE_ALPHANUMERIC",
          dimensionName: "appVersion",
        },
        desc: true,
      },
    ],
    metricAggregations: ["TOTAL"],
  },
  // 7. GEOLOCATION DATA FOR MAP
  {
    dimensions: [{ name: "region" }, { name: "country" }, { name: "city" }],
    metrics: [{ name: "activeUsers" }],
    dateRanges: [{ startDate: "2023-01-01", endDate: "2024-12-31" }],
    dimensionFilter: {
      filter: {
        fieldName: "country",
        stringFilter: {
          matchType: "EXACT",
          value: "India",
        },
      },
    },
  },
];

// API ACTION TYPES
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const ALL_CATEGORIES_REQUEST = "ALL_CATEGORIES_REQUEST";
export const ALL_CATEGORIES_SUCCESS = "ALL_CATEGORIES_SUCCESS";
export const ALL_CATEGORIES_FAILURE = "ALL_CATEGORIES_FAILURE";
export const ALL_CATEGORIES_RESET = "ALL_CATEGORIES_RESET";

export const SEARCH_CATEGORIES_REQUEST = "SEARCH_CATEGORIES_REQUEST";
export const SEARCH_CATEGORIES_SUCCESS = "SEARCH_CATEGORIES_SUCCESS";
export const SEARCH_CATEGORIES_FAILURE = "SEARCH_CATEGORIES_FAILURE";
export const SEARCH_CATEGORIES_RESET = "SEARCH_CATEGORIES_RESET";

export const DELETE_CATEGORY_REQUEST = "DELETE_CATEGORY_REQUEST";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";
export const DELETE_CATEGORY_RESET = "DELETE_CATEGORY_RESET";

export const ADD_CATEGORY_REQUEST = "ADD_CATEGORY_REQUEST";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILURE = "ADD_CATEGORY_FAILURE";
export const ADD_CATEGORY_RESET = "ADD_CATEGORY_RESET";

export const UPDATE_CATEGORY_REQUEST = "UPDATE_CATEGORY_REQUEST";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILURE = "UPDATE_CATEGORY_FAILURE";
export const UPDATE_CATEGORY_RESET = "UPDATE_CATEGORY_RESET";

export const TOPICS_REQUEST = "TOPICS_REQUEST";
export const TOPICS_SUCCESS = "TOPICS_SUCCESS";
export const TOPICS_FAILURE = "TOPICS_FAILURE";

export const ADD_TOPIC_REQUEST = "ADD_TOPIC_REQUEST";
export const ADD_TOPIC_SUCCESS = "ADD_TOPIC_SUCCESS";
export const ADD_TOPIC_FAILURE = "ADD_TOPIC_FAILURE";
export const ADD_TOPIC_RESET = "ADD_TOPIC_RESET";

export const DELETE_TOPIC_REQUEST = "DELETE_TOPIC_REQUEST";
export const DELETE_TOPIC_SUCCESS = "DELETE_TOPIC_SUCCESS";
export const DELETE_TOPIC_FAILURE = "DELETE_TOPIC_FAILURE";
export const DELETE_TOPIC_RESET = "DELETE_TOPIC_RESET";

export const UPDATE_TOPIC_REQUEST = "UPDATE_TOPIC_REQUEST";
export const UPDATE_TOPIC_SUCCESS = "UPDATE_TOPIC_SUCCESS";
export const UPDATE_TOPIC_FAILURE = "UPDATE_TOPIC_FAILURE";
export const UPDATE_TOPIC_RESET = "UPDATE_TOPIC_RESET";

export const ACTIVE_CATEGORY_REQUEST = "ACTIVE_CATEGORY_REQUEST";
export const ACTIVE_CATEGORY_SUCCESS = "ACTIVE_CATEGORY_SUCCESS";
export const ACTIVE_CATEGORY_FAILURE = "ACTIVE_CATEGORY_FAILURE";

export const ACTIVE_QUIZZES_STATUS_REQUEST = "ACTIVE_QUIZZES_STATUS_REQUEST";
export const ACTIVE_QUIZZES_STATUS_SUCCESS = "ACTIVE_QUIZZES_STATUS_SUCCESS";
export const ACTIVE_QUIZZES_STATUS_FAILURE = "ACTIVE_QUIZZES_STATUS_FAILURE";
export const ACTIVE_QUIZZES_STATUS_EMPTY = "ACTIVE_QUIZZES_STATUS_EMPTY";

export const ACTIVE_TOPIC_REQUEST = "ACTIVE_TOPIC_REQUEST";
export const ACTIVE_TOPIC_SUCCESS = "ACTIVE_TOPIC_SUCCESS";
export const ACTIVE_TOPIC_FAILURE = "ACTIVE_TOPIC_FAILURE";
export const ACTIVE_TOPIC_RESET = "ACTIVE_TOPIC_RESET";

export const SEARCH_TOPIC_REQUEST = "SEARCH_TOPIC_REQUEST";
export const SEARCH_TOPIC_SUCCESS = "SEARCH_TOPIC_SUCCESS";
export const SEARCH_TOPIC_FAILURE = "SEARCH_TOPIC_FAILURE";

export const GET_ALL_QUWSTIONS_REQUEST = "GET_ALL_QUWSTIONS_REQUEST";
export const GET_ALL_QUESTIONS_SUCCESS = "GET_ALL_QUESTIONS_SUCCESS";
export const GET_ALL_QUESTIONS_FAILURE = "GET_ALL_QUESTIONS_FAILURE";
export const GET_QUESTIONS_BY_ID_REQUEST = "GET_QUESTIONS_BY_ID_REQUEST";
export const GET_QUESTIONS_BY_ID_SUCCESS = "GET_QUESTIONS_BY_ID_SUCCESS";
export const CREATE_QUESTIONS_REQUEST = "CREATE_QUESTIONS_REQUEST";
export const CREATE_QUESTIONS_SUCCESS = "CREATE_QUESTIONS_SUCCESS";
export const CREATE_QUESTIONS_FAILURE = "CREATE_QUESTIONS_FAILURE";
export const DELETE_QUESTIONS_BY_ID_REQUEST = "DELETE_QUESTIONS_BY_ID_REQUEST";
export const DELETE_QUESTIONS_BY_ID_SUCCESS = "DELETE_QUESTIONS_BY_ID_SUCCESS";
export const UPDATE_QUESTIONS_REQUEST = "UPDATE_QUESTIONS_REQUEST";
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";

export const TOPIC_BY_CATEGORYID_REQUEST = "TOPIC_BY_CATEGORYID_REQUEST";
export const TOPIC_BY_CATEGORYID_SUCCESS = "TOPIC_BY_CATEGORYID_SUCCESS";
export const TOPIC_BY_CATEGORYID_FAILURE = "TOPIC_BY_CATEGORYID_FAILURE";
export const TOPIC_BY_CATEGORYID_RESET = "TOPIC_BY_CATEGORYID_RESET";

export const QUIZ_REQUEST = "QUIZ_REQUEST";
export const QUIZ_SUCCESS = "QUIZ_SUCCESS";
export const QUIZ_FAILURE = "QUIZ_FAILURE";
export const QUIZ_RESET = "QUIZ_RESET";

export const SEARCH_QUIZ_REQUEST = "SEARCH_QUIZ_REQUEST";
export const SEARCH_QUIZ_SUCCESS = "SEARCH_QUIZ_SUCCESS";
export const SEARCH_QUIZ_FAILURE = "SEARCH_QUIZ_FAILURE";
export const SEARCH_QUIZ_RESET = "SEARCH_QUIZ_RESET";

export const DELETE_QUIZ_REQUEST = "DELETE_QUIZ_REQUEST";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILURE = "DELETE_QUIZ_FAILURE";
export const DELETE_QUIZ_RESET = "DELETE_QUIZ_RESET";

export const UPDATE_QUIZ_REQUEST = "UPDATE_QUIZ_REQUEST";
export const UPDATE_QUIZ_SUCCESS = "UPDATE_QUIZ_SUCCESS";
export const UPDATE_QUIZ_FAILURE = "UPDATE_QUIZ_FAILURE";
export const UPDATE_QUIZ_RESET = "UPDATE_QUIZ_RESET";

export const ADD_QUIZ_REQUEST = "ADD_QUIZ_REQUEST";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAILURE = "ADD_QUIZ_FAILURE";
export const ADD_QUIZ_RESET = "ADD_QUIZ_RESET";

export const SELECT_QUESTION_REQUEST = "SELECT_QUESTION_REQUEST";
export const SELECT_QUESTION_SUCCESS = "SELECT_QUESTION_SUCCESS";
export const SELECT_QUESTION_FAILURE = "SELECT_QUESTION_FAILURE";

export const CANCEL_QUIZ_REQUEST = "CANCEL_QUIZ_REQUEST";
export const CANCEL_QUIZ_SUCCESS = "CANCEL_QUIZ_SUCCESS";
export const CANCEL_QUIZ_FAILURE = "CANCEL_QUIZ_FAILURE";
export const CANCEL_QUIZ_RESET = "CANCEL_QUIZ_RESET";

export const GET_ALL_LEADERBOARD_REQUEST = "GET_ALL_LEADERBOARD_REQUEST";
export const GET_ALL_LEADERBOARD_SUCCESS = "GET_ALL_LEADERBOARD_SUCCESS";
export const GET_ALL_LEADERBOARD_FAILURE = "GET_ALL_LEADERBOARD_FAILURE";

export const GET_LEADERBOARD_BY_NAME_REQUEST =
  "GET_LEADERBOARD_BY_NAME_REQUEST";
export const GET_LEADERBOARD_BY_NAME_SUCCESS =
  "GET_LEADERBOARD_BY_NAME_SUCCESS";
export const GET_LEADERBOARD_BY_NAME_FAILURE =
  "GET_LEADERBOARD_BY_NAME_FAILURE";

export const GET_SELECT_QUESTION_BY_ID_REQUEST =
  "GET_SELECT_QUESTION_BY_ID_REQUEST";
export const GET_SELECT_QUESTION_BY_ID_SUCCESS =
  "GET_SELECT_QUESTION_BY_ID_SUCCESS";
export const GET_SELECT_QUESTION_BY_ID_FAILURE =
  "GET_SELECT_QUESTION_BY_ID_FAILURE";

export const GET_RANDOM_QUESTION_REQUEST = "GET_RANDOM_QUESTION_REQUEST";
export const GET_RANDOM_QUESTION_SUCCESS = "GET_RANDOM_QUESTION_SUCCESS";
export const GET_RANDOM_QUESTION_FAILURE = "GET_RANDOM_QUESTION_FAILURE";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "ADD_USER_REQUEST";

export const UPDATE_USER_REQUEST = "UPDATRE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const GET_USER_BY_ID_REQUEST = "GET_USER_BY_ID_REQUEST";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAILURE = "GET_USER_BY_ID_FAILURE";

export const ADD_BALANCE_REQUEST = "ADD_BALANCE_REQUEST";
export const ADD_BALANCE_SUCCESS = "ADD_BALANCE_SUCCESS";
export const ADD_BALANCE_FAILURE = "ADD_BALANCE_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const ACTIVE_USER_REQUEST = "ACTIVE_USER_REQUEST";
export const ACTIVE_USER_SUCCESS = "ACTIVE_USER_SUCCESS";
export const ACTIVE_USER_FAILURE = "ACTIVE_USER_FAILURE";

export const GET_USER_BANK_ACCOUNT_REQUEST = "GET_USER_BANK_ACCOUNT_REQUEST";
export const GET_USER_BANK_ACCOUNT_SUCCESS = "GET_USER_BANK_ACCOUNT_SUCCESS";
export const GET_USER_BANK_ACCOUNT_FAILURE = "GET_USER_BANK_ACCOUNT_FAILURE";

export const GET_USER_UPI_ACCOUNT_REQUEST = "GET_USER_UPI_ACCOUNT_REQUEST";
export const GET_USER_UPI_ACCOUNT_SUCCESS = "GET_USER_UPI_ACCOUNT_SUCCESS";
export const GET_USER_UPI_ACCOUNT_FAILURE = "GET_USER_UPI_ACCOUNT_FAILURE";

export const DELETE_USER_UPI_BANK_ACCOUNT_REQUEST =
  "DELETE_USER_UPI_BANK_ACCOUNT_REQUEST";
export const DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS =
  "DELETE_USER_UPI_BANK_ACCOUNT_SUCCESS";
export const DELETE_USER_UPI_BANK_ACCOUNT_FAILURE =
  "DELETE_USER_UPI_BANK_ACCOUNT_FAILURE";

export const GET_ALL_USER_WITHDRAWALS_REQUEST =
  "GET_ALL_USER_WITHDRAWALS_REQUEST";
export const GET_ALL_USER_WITHDRAWALS_SUCCESS =
  "GET_ALL_USER_WITHDRAWALS_SUCCESS";
export const GET_ALL_USER_WITHDRAWALS_FAILURE =
  "GET_ALL_USER_WITHDRAWALS_FAILURE";

export const DELETE_MULTIPUL_QUESTIONS_REQUEST =
  "DELETE_MULTIPUL_QUESTIONS_REQUEST";
export const DELETE_MULTIPUL_QUESTIONS_SUCCESS =
  "DELETE_MULTIPUL_QUESTIONS_SUCCESS";
export const DELETE_MULTIPUL_QUESTIONS_FAILURE =
  "DELETE_MULTIPUL_QUESTIONS_FAILURE";
export const DELETE_MULTIPUL_QUESTIONS_RESET =
  "DELETE_MULTIPUL_QUESTIONS_RESET";

export const GET_USER_ALL_REQUEST = "GET_USER_ALL_REQUEST";
export const GET_USER_ALL_SUCCESS = "GET_USER_ALL_SUCCESS";
export const GET_USER_ALL_FAILURE = "GET_USER_ALL_FAILURE";

export const SEARCH_USER_ALL_REQUEST = "SEARCH_USER_ALL_REQUEST";
export const SEARCH_USER_ALL_SUCCESS = "SEARCH_USER_ALL_SUCCESS";
export const SEARCH_USER_ALL_FAILURE = "SEARCH_USER_ALL_FAILURE";
export const SEARCH_USER_ALL_RESET = "SEARCH_USER_ALL_RESET";

export const GET_USER_ALL_PLAYED_QUIZ_REQUEST =
  "GET_USER_ALL_PLAYED_QUIZ_REQUEST";
export const GET_USER_ALL_PLAYED_QUIZ_SUCCESS =
  "GET_USER_ALL_PLAYED_QUIZ_SUCCESS";
export const GET_USER_ALL_PLAYED_QUIZ_FAILURE =
  "GET_USER_ALL_PLAYED_QUIZ_FAILURE";

export const GET_USER_ALL_TRANSACTIONS_REQUEST =
  "GET_USER_ALL_TRANSACTIONS_REQUEST";
export const GET_USER_ALL_TRANSACTIONS_SUCCESS =
  "GET_USER_ALL_TRANSACTIONS_SUCCESS";
export const GET_USER_ALL_TRANSACTIONS_FAILURE =
  "GET_USER_ALL_TRANSACTIONS_FAILURE";

export const GET_REFERRED_TO_REQUEST = "GET_REFERRED_TO_REQUEST";
export const GET_REFERRED_TO_SUCCESS = "GET_REFERRED_TO_SUCCESS";
export const GET_REFERRED_TO_FAILURE = "GET_REFERRED_TO_FAILURE";

export const GET_CONTACTUS_ALL_REQUEST = "GET_CONTACTUS_ALL_REQUEST";
export const GET_CONTACTUS_ALL_SUCCESS = "GET_CONTACTUS_ALL_SUCCESS";
export const GET_CONTACTUS_ALL_FAILURE = "GET_CONTACTUS_ALL_FAILURE";

export const GET_ASSIGNEE_ALL_REQUEST = "GET_ASSIGNEE_ALL_REQUEST";
export const GET_ASSIGNEE_ALL_SUCCESS = "GET_ASSIGNEE_ALL_SUCCESS";
export const GET_ASSIGNEE_ALL_FAILURE = "GET_ASSIGNEE_ALL_FAILURE";

export const UPDATE_CONTACT_US_REQUEST = "UPDATE_CONTACT_US_REQUEST";
export const UPDATE_CONTACT_US_SUCCESS = "UPDATE_CONTACT_US_SUCCESS";
export const UPDATE_CONTACT_US_FAILURE = "UPDATE_CONTACT_US_FAILURE";

export const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const GET_SCHEDULE_NOTIFICATION_REQUEST =
  "GET_SCHEDULE_NOTIFICATION_REQUEST";
export const GET_SCHEDULE_NOTIFICATION_SUCCESS =
  "GET_SCHEDULE_NOTIFICATION_SUCCESS";
export const GET_SCHEDULE_NOTIFICATION_FAILURE =
  "GET_SCHEDULE_NOTIFICATION_FAILURE";

export const SEND_NOTIFICATION_REQUEST = "SEND_NOTIFICATION_REQUEST";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILURE = "SEND_NOTIFICATION_FAILURE";

export const SEND_SHEDULED_NOTIFICATION_REQUEST =
  "SEND_SHEDULED_NOTIFICATION_REQUEST";
export const SEND_SHEDULED_NOTIFICATION_SUCCESS =
  "SEND_SHEDULED_NOTIFICATION_SUCCESS";
export const SEND_SHEDULED_NOTIFICATION_FAILURE =
  "SEND_SHEDULED_NOTIFICATION_FAILURE";

export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAILURE = "GET_TRANSACTION_FAILURE";

export const GET_USER_QUIESTION_REQUEST = "GET_USER_QUIESTION_REQUEST";
export const GET_USER_QUIESTION_SUCCESS = "GET_USER_QUIESTION_SUCCESS";
export const GET_USER_QUIESTION_FAILURE = "GET_USER_QUIESTION_FAILURE";
export const GET_USER_QUIESTION_RESET = "GET_USER_QUIESTION_RESET";

export const GET_ACTIVITY_LOG_REQUEST = "GET_ACTIVITY_LOG_REQUEST";
export const GET_ACTIVITY_LOG_SUCCESS = "GET_ACTIVITY_LOG_SUCCESS";
export const GET_ACTIVITY_LOG_FAILURE = "GET_ACTIVITY_LOG_FAILURE";

export const GET_COMMISSION_RATES_REQUEST = "GET_COMMISSION_RATES_REQUEST";
export const GET_COMMISSION_RATES_SUCCESS = "GET_COMMISSION_RATES_SUCCESS";
export const GET_COMMISSION_RATES_FAILURE = "GET_COMMISSION_RATES_FAILURE";

export const GET_QUIZ_BOTS_PROBABILITY_REQUEST =
  "GET_QUIZ_BOTS_PROBABILITY_REQUEST";
export const GET_QUIZ_BOTS_PROBABILITY_SUCCESS =
  "GET_QUIZ_BOTS_PROBABILITY_SUCCESS";
export const GET_QUIZ_BOTS_PROBABILITY_FAILURE =
  "GET_QUIZ_BOTS_PROBABILITY_FAILURE";

export const UPDATE_COMMISSION_RATES_REQUEST =
  "UPDATE_COMMISSION_RATES_REQUEST";
export const UPDATE_COMMISSION_RATES_SUCCESS =
  "UPDATE_COMMISSION_RATES_SUCCESS";
export const UPDATE_COMMISSION_RATES_FAILURE =
  "UPDATE_COMMISSION_RATES_FAILURE";

export const UPDATE_QUIZ_BOTS_PROBABILITY_REQUEST =
  "UPDATE_QUIZ_BOTS_PROBABILITY_REQUEST";
export const UPDATE_QUIZ_BOTS_PROBABILITY_SUCCESS =
  "UPDATE_QUIZ_BOTS_PROBABILITY_SUCCESS";
export const UPDATE_QUIZ_BOTS_PROBABILITY_FAILURE =
  "UPDATE_QUIZ_BOTS_PROBABILITY_FAILURE";

export const GET_REVIEW_TIME_REQUEST = "GET_REVIEW_TIME_REQUEST";
export const GET_REVIEW_TIME_SUCCESS = "GET_REVIEW_TIME_SUCCESS";
export const GET_REVIEW_TIME_FAILURE = "GET_REVIEW_TIME_FAILURE";

export const UPDATE_REVIEW_TIME_REQUEST = "UPDATE_REVIEW_TIME_REQUEST";
export const UPDATE_REVIEW_TIME_SUCCESS = "UPDATE_REVIEW_TIME_SUCCESS";
export const UPDATE_REVIEW_TIME_FAILURE = "UPDATE_REVIEW_TIME_FAILURE";

export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAILURE = "GET_MENU_FAILURE";

export const UPDATE_MENU_REQUEST = "UPDATE_MENU_REQUEST";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";
export const UPDATE_MENU_FAILURE = "UPDATE_MENU_FAILURE";

export const SEND_OTP_LOGIN_REQUEST = "SENT_OTP_LOGIN_REQUEST";
export const SEND_OTP_LOGIN_SUCCESS = "SENT_OTP_LOGIN_SUCCESS";
export const SEND_OTP_LOGIN_FAILURE = "SENT_OTP_LOGIN_FAILURE";
export const SEND_OTP_LOGIN_RESET = "SEND_OTP_LOGIN_RESET";

export const VERIFY_OTP_LOGIN_REQUEST = "VERIFY_OTP_LOGIN_REQUEST";
export const VERIFY_OTP_LOGIN_SUCCESS = "VERIFY_OTP_LOGIN_SUCCESS";
export const VERIFY_OTP_LOGIN_FAILURE = "VERIFY_OTP_LOGIN_FAILURE";
export const VERIFY_OTP_LOGIN_RESET = "VERIFY_OTP_LOGIN_RESET";

export const VERSION_POST_REQUEST = "VERSION_POST_REQUEST";
export const VERSION_POST_SUCCESS = "VERSION_POST_SUCCESS";
export const VERSION_POST_FAILURE = "VERSION_POST_FAILURE";

export const VERSION_GET_REQUEST = "VERSION_GET_REQUEST";
export const VERSION_GET_SUCCESS = "VERSION_GET_SUCCESS";
export const VERSION_GET_FAILURE = "VERSION_GET_FAILURE";

export const GET_PLAYER_ALL_REQUEST = "GET_PLAYER_ALL_REQUEST";
export const GET_PLAYER_ALL_SUCCESS = "GET_PLAYER_ALL_SUCCESS";
export const GET_PLAYER_ALL_FAILURE = "GET_PLAYER_ALL_FAILURE";

export const GET_PLAYERS_REQUEST = "GET_PLAYERS_REQUEST";
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS";
export const GET_PLAYERS_FAILURE = "GET_PLAYERS_FAILURE";

export const GET_MANAGE_ACCESS_REQUEST = "GET_MANAGE_ACCESS_REQUEST";
export const GET_MANAGE_ACCESS_SUCCESS = "GET_MANAGE_ACCESS_SUCCESS";
export const GET_MANAGE_ACCESS_FAILURE = "GET_MANAGE_ACCESS_FAILURE";
export const GET_MANAGE_ACCESS_SESSION = "GET_MANAGE_ACCESS_SESSION";

export const POST_MANAGE_ACCESS_REQUEST = "POST_MANAGE_ACCESS_REQUEST";
export const POST_MANAGE_ACCESS_SUCCESS = "POST_MANAGE_ACCESS_SUCCESS";
export const POST_MANAGE_ACCESS_FAILURE = "POST_MANAGE_ACCESS_FAILURE";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const GET_GOOGLE_REQUEST = "GET_GOOGLE_REQUEST";
export const GET_GOOGLE_SUCCESS = "GET_GOOGLE_SUCCESS";
export const GET_GOOGLE_FAILURE = "GET_GOOGLE_FAILURE";

export const GET_SERVER_REQUEST = "GET_SERVER_REQUEST";
export const GET_SERVER_SUCCESS = "GET_SERVER_SUCCESS";
export const GET_SERVER_FAILURE = "GET_SERVER_FAILURE";

export const GET_BANNER_DATA_REQUEST = "GET_BANNER_DATA_REQUEST";
export const GET_BANNER_DATA_SUCCESS = "GET_BANNER_DATA_SUCCESS";
export const GET_BANNER_DATA_FAILURE = "GET_BANNER_DATA_FAILURE";

export const GET_REWARD_DATA_REQUEST = "GET_REWARD_DATA_REQUEST";
export const GET_REWARD_DATA_SUCCESS = "GET_REWARD_DATA_SUCCESS";
export const GET_REWARD_DATA_FAILURE = "GET_REWARD_DATA_FAILURE";

export const UPDATE_BANNER_REQUEST = "UPDATE_BANNER_REQUEST";
export const UPDATE_BANNER_SUCCESS = "UPDATE_BANNER_SUCCESS";
export const UPDATE_BANNER_FAILURE = "UPDATE_BANNER_FAILURE";

export const UPDATE_REWARD_REQUEST = "UPDATE_REWARD_REQUEST";
export const UPDATE_REWARD_SUCCESS = "UPDATE_REWARD_SUCCESS";
export const UPDATE_REWARD_FAILURE = "UPDATE_REWARD_FAILURE";

export const ASSIGN_QUESTION_REQUEST = "ASSIGN_QUESTION_REQUEST";
export const ASSIGN_QUESTION_SUCCESS = "ASSIGN_QUESTION_SUCCESS";
export const ASSIGN_QUESTION_FAILURE = "ASSIGN_QUESTION_FAILURE";
export const ASSIGN_QUESTION_RESET = "ASSIGN_QUESTION_RESET";

export const GET_ASSIGNED_QUESTIONS_REQUEST = "GET_ASSIGNED_QUESTIONS_REQUEST";
export const GET_ASSIGNED_QUESTIONS_SUCCESS = "GET_ASSIGNED_QUESTIONS_SUCCESS";
export const GET_ASSIGNED_QUESTIONS_FAILURE = "GET_ASSIGNED_QUESTIONS_FAILURE";

export const REMOVE_ASSIGNED_QUESTIONS_REQUEST ="REMOVE_ASSIGNED_QUESTIONS_REQUEST";
export const REMOVE_ASSIGNED_QUESTIONS_SUCCESS = "REMOVE_ASSIGNED_QUESTIONS_SUCCESS";
export const REMOVE_ASSIGNED_QUESTIONS_FAILURE ="REMOVE_ASSIGNED_QUESTIONS_FAILURE";

export const DELETE_VERSION_REQUEST = "DELETE_VERSION_REQUEST";
export const DELETE_VERSION_SUCCESS = "DELETE_VERSION_SUCCESS";
export const DELETE_VERSION_FAILURE = "DELETE_VERSION_FAILURE";

export const UPDATE_PLAYER_REQUEST = "UPDATE_PLAYER_REQUEST";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_FAILURE = "UPDATE_PLAYER_FAILURE";

export const DELETE_PLAYER_REQUEST = "DELETE_PLAYER_REQUEST";
export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
export const DELETE_PLAYER_FAILURE = "DELETE_PLAYER_FAILURE";

export const DELETE_SCHEDULE_NOTIFICATION_REQUEST =
  "DELETE_SCHEDULE_NOTIFICATION_REQUEST";
export const DELETE_SCHEDULE_NOTIFICATION_SUCCESS =
  "DELETE_SCHEDULE_NOTIFICATION_SUCCESS";
export const DELETE_SCHEDULE_NOTIFICATION_FAILURE =
  "DELETE_SCHEDULE_NOTIFICATION_FAILURE";

export const RESET_PLAYER_PASSWORD_REQUEST = "RESET_PLAYER_PASSWORD_REQUEST";
export const RESET_PLAYER_PASSWORD_SUCCESS = "RESET_PLAYER_PASSWORD_SUCCESS";
export const RESET_PLAYER_PASSWORD_FAILURE = "RESET_PLAYER_PASSWORD_FAILURE";

export const UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST =
  "UPDATE_QUIZ_SPOT_PERCENTAGE_REQUEST";
export const UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS =
  "UPDATE_QUIZ_SPOT_PERCENTAGE_SUCCESS";
export const UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE =
  "UPDATE_QUIZ_SPOT_PERCENTAGE_FAILURE";

export const GET_QUIZ_SPOT_PERCENTAGE_REQUEST =
  "GET_QUIZ_SPOT_PERCENTAGE_REQUEST";
export const GET_QUIZ_SPOT_PERCENTAGE_SUCCESS =
  "GET_QUIZ_SPOT_PERCENTAGE_SUCCESS";
export const GET_QUIZ_SPOT_PERCENTAGE_FAILURE =
  "GET_QUIZ_SPOT_PERCENTAGE_FAILURE";

export const UPDATE_SCHEDULE_NOTIFICATION_REQUEST =
  "UPDATE_SCHEDULE_NOTIFICATION_REQUEST";
export const UPDATE_SCHEDULE_NOTIFICATION_SUCCESS =
  "UPDATE_SCHEDULE_NOTIFICATION_SUCCESS";
export const UPDATE_SCHEDULE_NOTIFICATION_FAILURE =
  "UPDATE_SCHEDULE_NOTIFICATION_FAILURE";

  
export const GET_AVAILABLE_QUESTION_COUNT_REQUEST = "GET_AVAILABLE_QUESTION_COUNT_REQUEST";
export const GET_AVAILABLE_QUESTION_COUNT_SUCCESS = "GET_AVAILABLE_QUESTION_COUNT_SUCCESS";
export const GET_AVAILABLE_QUESTION_COUNT_FAILURE = "GET_AVAILABLE_QUESTION_COUNT_FAILURE";

export const RESET_STORE = "RESET_STORE";

// Email - mailto:cosmostaker@gmail.com
// Password - $b=mNBlva$
// eyJhbGciOiJIUzUxMiJ9
//   .eyJ1c2VyIjp7Im5hbWUiOiJBZG1pbiIsIm1vYmlsZSI6bnVsbCwiZW1haWxJZCI6ImNvc21vc3Rha2VyQGdtYWlsLmNvbSIsImlkIjoxfSwianRpIjoiMSIsImlhdCI6MTY4NTAxOTAzOX0
//   .iNmByyniqa4CjAqTSPv11ot8rZiUb7geUn -
//   uIPYExVWH1YFYVLNJoYx6EYsupCqfM39qrHQTsolQ1CdH8MKuOQ;
