import React, { useState } from "react";
import { useNavigate } from "react-router";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { createQuestionRequest } from "../../Actions";
import { useDispatch } from "react-redux";
import { style } from "../constant";
import { toast } from "react-toastify";

export default function BulkUpload(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [freeQuestion, setFreeQuestion] = useState(null);
  const { itemsArray, token, uploadQuestions, currentPage } = props;
  const addQuestions = () => {
    const data = uploadQuestions.map((item) => ({
      ...item,
      isFree: freeQuestion,
    }));

    const body = {
      data: data,
      token: token,
    };

    if (uploadQuestions.length < 1) {
      toast.warning("No data found in this file!");
      return;
    }

    if (body.data.some((item) => item.isFree === null)) {
      toast.info(
        "Please select cash question and free question for all items."
      );
    } else {
      dispatch(createQuestionRequest(body));
      navigate("/questionPool");
    }
  };

  const handleAddQuestions = () => {
    addQuestions();
  };

  return (
    <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
      <div className="flex items-center gap-10">
        <div className="flex items-center gap-2">
          <input
            id="default-radio-1"
            type="radio"
            onClick={() => setFreeQuestion(false)}
            value={freeQuestion}
            name="default-radio"
            className="w-4 h-4 text-green-500 bg-gray-100 border-gray-300 cursor-pointer"
          />
          <label
            for="default-radio-1"
            className=" text-lg font-bold text-gray-900"
          >
            Cash questions
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            onClick={() => setFreeQuestion(true)}
            id="default-radio-2"
            type="radio"
            value={!freeQuestion}
            name="default-radio"
            className="w-4 h-4 text-green-500 bg-gray-100 border-gray-300 cursor-pointer"
          />
          <label
            for="default-radio-2"
            className=" text-lg font-bold text-gray-900 "
          >
            Free questions
          </label>
        </div>
      </div>
      {itemsArray?.length ? (
        <>
          <div className="relative overflow-x-auto ml-[-10px] sm:rounded-lg mt-3">
            {itemsArray?.map((item, index) => (
              <div className="bg-white border-b" key={index}>
                <div className="flex flex-col sm:flex-row items-center px-3 py-2 sm:py-4 gap-4">
                  {/* <h5>
                    <img
                      className="w-12"
                      src={item.isFree ? cashQuiz : freeQuiz}
                      alt=""
                    />
                  </h5> */}
                  <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                    <div className="">{item?.level}</div>
                  </div>
                </div>

                <div className="flex flex-wrap">
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.questionEn}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionAEn}</h6>
                    <h6 className="px-6 py-2">B : {item.optionBEn}</h6>
                    <h6 className="px-6 py-2">C : {item.optionCEn}</h6>
                    <h6 className="px-6 py-2">D : {item.optionDEn}</h6>{" "}
                    <h6 className="px-6 py-2">
                      {`Ans:- ${item.correctOptionEn} `}
                    </h6>
                  </div>
                  <div>
                    <td className="px-6 py-2 ">
                      <ReadMoreLess
                        descriptionEn={item.questionHi}
                        maxLength={100}
                      />
                    </td>
                    <h6 className="px-6 py-2">A : {item.optionAHi}</h6>
                    <h6 className="px-6 py-2">B : {item.optionBHi}</h6>
                    <h6 className="px-6 py-2">C : {item.optionCHi}</h6>
                    <h6 className="px-6 py-2">D : {item.optionDHi}</h6>{" "}
                    <h6 className="px-6 py-2">
                      {`Ans:-${item.correctOptionHi}`}
                    </h6>
                  </div>
                </div>
                <div className="flex gap-4 flex-wrap mb-3 px-6">
                  <div className="flex flex-col sm:flex-row gap-2">
                    <div className="bg-blue-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                      {item?.category?.name}
                    </div>
                    <div className="bg-yellow-500 text-white px-3 py-1 rounded-md text-center sm:text-sm">
                      {item?.topic?.name}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="px-4 py-4">
              <button
                className={style.buttonClass}
                onClick={handleAddQuestions}
              >
                Upload Questions
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="font-semibold text-center pt-24">
          No Question Found !!!
        </div>
      )}
    </div>
  );
}
