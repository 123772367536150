import {
  GET_REFERRED_TO_REQUEST,
  GET_REFERRED_TO_SUCCESS,
  GET_REFERRED_TO_FAILURE,
} from "../../Utils/Constants";

export const getReferredToRequest = (payload) => {
  return {
    type: GET_REFERRED_TO_REQUEST,
    payload: payload,
  };
};

export const getReferredToSuccess = (data) => {
  return {
    type: GET_REFERRED_TO_SUCCESS,
    payload: data,
  };
};

export const getReferredToFailure = (error) => {
  return {
    type: GET_REFERRED_TO_FAILURE,
    payload: error,
  };
};
