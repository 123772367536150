import { MdArrowBack, MdDelete, MdModeEditOutline } from "react-icons/md";
import { useLocation, useNavigate } from "react-router";
import avtar from "../../../assets/images/download.png";
import { useEffect, useState } from "react";
import { DeleteModal } from "../../../components/modal/DeleteModal";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../../Utils/Constants";
import { connect, useDispatch } from "react-redux";
import {
  activeUserRequest,
  addBalanceRequest,
  deleteUserRequest,
  getAllUserWithdrawalsRequest,
  getBankAccountsRequest,
  getReferredToRequest,
  getUpiAccountsRequest,
  getUserAllPlayedQuizRequest,
  getUserAllTransectionsRequest,
  getUserByIdRequest,
  resetPasswordRequest,
  resetPlayerPasswordRequest,
} from "../../../Actions";
import { PasswordModal } from "../../../components/modal/passwordModal";
import AllWidthrawal from "./buttonAction/allWidthrawal";
import { Transition } from "@headlessui/react";
import { Pagination } from "../../../components/Pagination";
import { getUserByIdEmpty } from "../../../Actions/User/getUserByIdAction";
import Loading from "../../../components/loading";
import { checkAccess } from "../../../Utils/RoleBasedRoute";
import TooltipWrapper from "../../../components/ui-elements/TooltipWrapper";

const ViewUser = ({
  userById,
  referredToData,
  allWithdrawal,
  bankAccount,
  upiAccount,
  allPlayedQuiz,
  allUserTransections,
  activeUser,
  addBalance,
}) => {
  const navigate = useNavigate();
  const dispetch = useDispatch();
  const location = useLocation();
  const item = location.state;
  const [fullscreen, setFullscreen] = useState(false);
  const [openDeleteCUserModal, setOpenDeleteCUserModal] = useState(false);
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
  const [openAddBalanceModal, setOpenAddbalanceModal] = useState(false);
  const [openAllWithdrawal, setOpenAllWithdrawal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openBankAccount, setOpenBankAccount] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [deleteUserData, setDeleteUserData] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [userAllPlayedQuizArray, setUserAllPlayedQuizArray] = useState([]);
  const [userAllTransectionsArray, setUserAllTransectionsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openUserAllPlayedQuiz, setOpenUserAllPlayedQuiz] = useState(false);
  const [transectionsTotalPages, setTransectionsTotalPages] = useState(1);
  const [transectionsCurrentPage, setTransectionsCurrentPage] = useState(1);
  const [transectionsLoading, setTranectionsLoading] = useState(false);
  const [openTransections, setOpenTransections] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [isChecked, setIsChecked] = useState(userById.userById.data?.isActive);
  const [activeData, setactiveData] = useState({});
  const [fullPic, setFullPic] = useState("");

  useEffect(() => {
    if (userById?.userById.statusCode == 404 && !userById?.loading) {
      navigate(-1);
    }
  }, [userById]);

  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );
  const toggleFullscreen = (profilePic) => {
    setFullscreen(!fullscreen);
    setFullPic(profilePic);
  };

  const handleClose = () => {
    setFullscreen(false);
    setFullPic(null);
  };

  const buttonList = [
    {
      id: 1,
      name: "View Played Quizzes",
      flage: "viewPlayedQuizzes",
      disabled: !checkAccess("QUIZ", "R"),
    },
    {
      id: 2,
      name: "View Bank accounts",
      flage: "viewBankAccounts",
      disabled: !checkAccess("BANK", "R"),
    },
    {
      id: 3,
      name: "View All Transactions",
      flage: "viewAllTransactions",
      disabled: !checkAccess("TRANSACTION", "R"),
    },
    {
      id: 4,
      name: "View Withdrawals",
      flage: "viewWithdrawals",
      disabled: !checkAccess("TRANSACTION", "R"),
    },
    {
      id: 5,
      name: "Reset Passcode",
      flage: "resetPasscode",
      disabled: !checkAccess("PLAYER", "U"),
    },
    {
      id: 6,
      name: "Add balance",
      flage: "addBalance",
      disabled: !checkAccess("PLAYER", "U"),
      place:"top",
      content:"Adds the specified amount to the user's wallet."
    },
  ];

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [isViewReferredToOpen, setIsViewReferredToOpen] = useState(false);

  const handleUserAllTransections = () => {
    const body = {
      userId: item?.id,
      pageNo: transectionsCurrentPage,
      token: token,
    };
    dispetch(getUserAllTransectionsRequest(body));
    setOpenTransections(true);
  };

  const [referredToDataList, setReferredToDataList] = useState([]);
  const [referredTotalPages, setReferredToTotalPages] = useState(1);
  const [referredToCurrentPage, setReferredToCurrentPage] = useState(1);

  const getReferredToData = () => {
    let body = {
      //  data: {},
      referrerId: item?.id,
      pageNo: referredToCurrentPage,
      token,
    };
    dispetch(getReferredToRequest(body));
  };

  useEffect(() => {
    getReferredToData();
  }, [referredToCurrentPage, item?.id]);

  const handleReferredToPageChange = (pNo) => {
    setReferredToCurrentPage(pNo);
  };

  useEffect(() => {
    // console.log(
    //   "Referred To Data : ",
    //   referredToData?.referredToData?.data?.users || []
    // );

    if (referredToData?.referredToData?.data?.totalPages) {
      setReferredToTotalPages(referredToData?.referredToData?.data?.totalPages);
    }

    setReferredToDataList(referredToData?.referredToData?.data?.users || []);
  }, [referredToData]);

  const handleAllWidthrawal = () => {
    const body = {
      userId: item?.id,
      token: token,
    };
    dispetch(getAllUserWithdrawalsRequest(body));
    setOpenAllWithdrawal(true);
    setShowDetails(!showDetails);
    setOpenBankAccount(false);
  };

  const handleChangePassword = (newPassword) => {
    const body = {
      data: {
        userId: item?.id,
        newPassword: newPassword,
      },
      token: token,
    };
    dispetch(resetPlayerPasswordRequest(body));
  };

  const handleAddBalance = (amount) => {
    const body = {
      data: {
        userId: item?.id,
        amount: amount,
      },
      token: token,
    };
    dispetch(addBalanceRequest(body));
  };

  const handleUserBankAccount = () => {
    const body = {
      userId: item?.id,
      token: token,
    };
    dispetch(getBankAccountsRequest(body));
    setOpenBankAccount(!openBankAccount);
    setOpenAllWithdrawal(false);
    setShowDetails(false);
  };

  const handleUserUpiAccount = () => {
    const body = {
      userId: item?.id,
      token: token,
    };
    dispetch(getUpiAccountsRequest(body));
  };

  const getUserById = () => {
    const body = {
      id: item?.id,
      token: token,
    };
    dispetch(getUserByIdRequest(body));
  };

  useEffect(() => {
    getUserById();
  }, [activeUser , item?.id]);

  const handleActiveUser = (a, data) => {
    const body = {
      userId: item?.id,
      enable: !data,
      token: token,
    };
    dispetch(activeUserRequest(body));
    setIsChecked(!isChecked);
  };

  const handleDeleteUser = (data) => {
    const body = {
      data: {
        id: data,
      },
      token: token,
    };
    dispetch(deleteUserRequest(body));
    navigate(-1);
  };

  useEffect(() => {
    if (addBalance?.addBalance?.statusCode === 200) {
      getUserById();
    }
  }, [addBalance?.addBalance]);

  const handleUserAllPlayedQuiz = () => {
    const body = {
      data: {
        userId: item?.id,
        pageNo: currentPage,
      },
      token: token,
    };
    dispetch(getUserAllPlayedQuizRequest(body));
    setOpenUserAllPlayedQuiz(true);
  };

  useEffect(() => {
    if (openUserAllPlayedQuiz) {
      handleUserAllPlayedQuiz();
    }
  }, [currentPage]);

  useEffect(() => {
    if (openTransections) {
      handleUserAllTransections();
    }
  }, [transectionsCurrentPage]);

  const handleButtonClick = (item) => {
    if (item?.flage === "viewWithdrawals") {
      handleAllWidthrawal();
    } else if (item?.flage === "resetPasscode") {
      setOpenResetPasswordModal(true);
    } else if (item?.flage === "addBalance") {
      setOpenAddbalanceModal(true);
    } else if (item?.flage === "viewBankAccounts") {
      handleUserBankAccount();
      handleUserUpiAccount();
    } else if (item?.flage === "viewPlayedQuizzes") {
      handleUserAllPlayedQuiz();
    } else if (item?.flage === "viewAllTransactions") {
      handleUserAllTransections();
    }
    setActiveButton(item?.flage);
  };

  useEffect(() => {
    if (allPlayedQuiz?.loading) {
      setIsLoading(true);
    }
    if (allPlayedQuiz?.error) {
      setIsLoading(false);
    }

    if (allPlayedQuiz?.allPlayedQuiz?.data?.quizzes) {
      setUserAllPlayedQuizArray(allPlayedQuiz?.allPlayedQuiz?.data?.quizzes);
      setIsLoading(false);
    }

    if (allPlayedQuiz?.allPlayedQuiz?.data?.totalPages) {
      setTotalPages(allPlayedQuiz?.allPlayedQuiz?.data?.totalPages);
    }
  }, [allPlayedQuiz]);

  useEffect(() => {
    if (allUserTransections?.loading) {
      setTranectionsLoading(true);
    }
    if (allUserTransections?.error) {
      setTranectionsLoading(false);
    }

    if (allUserTransections?.allUserTransections?.data?.transactions) {
      setUserAllTransectionsArray(
        allUserTransections?.allUserTransections?.data?.transactions
      );
      setTranectionsLoading(false);
    }

    if (allUserTransections?.allUserTransections?.data?.totalPages) {
      setTransectionsTotalPages(
        allUserTransections?.allUserTransections?.data?.totalPages
      );
    }
  }, [allUserTransections]);

  if (userById.loading) {
    return <Loading />;
  }

  if (userById.error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  const tableHeaders = ["Id", "Referred to", "email", "Phone"];

  const dummyTableBody = [
    {
      inviteCode: "JKYIOPI",
      referredTo: "Akshat binjwa",
      email: "akshatbinjwa@dummy.com",
      phone: "6789098788",
    },
    {
      inviteCode: "JGYUIUJ",
      referredTo: "Ambrus banial",
      email: "ambrusbanial@dummy.com",
      phone: "5698787887",
    },
  ];

  return (
    <>
      <>
        <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3">
          <div className="bg-white p-4 shadow-md rounded-lg">
            <div className="flex justify-start cursor-pointer">
              <span
                onClick={() => {
                  navigate("/players", {
                    state: {
                      currentPage: location.state?.currentPage,
                    },
                  });
                }}
                className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
              >
                <MdArrowBack className="text-base" /> Back
              </span>
            </div>
            <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
              <div className="font-bold uppercase text-lg text-center">
                View Player
              </div>
              <div>
                <label className="relative inline-flex items-center">
                  <input
                    type="checkbox"
                    value=""
                    disabled={!checkAccess("PLAYER", "U")}
                    className="sr-only peer"
                    checked={userById.userById.data?.isActive}
                    onChange={() => {
                      setactiveData({
                        isActive: userById.userById.data?.isActive,
                        name: userById.userById.data?.name,
                      });
                      setIsConfirmationDialogOpen(true);
                      // handleActiveUser(userById?.isActive);
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      userById.userById.data?.isActive
                        ? "bg-green-600"
                        : "bg-red-600"
                    } ${
                      !checkAccess("PLAYER", "U")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    } peer-focus:outline-none rounded-full  peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all  peer-checked:bg-green-600`}
                  ></div>
                  <span
                    className={`ms-3 text-sm font-medium ${
                      userById.userById.data?.isActive
                        ? "text-gray-900"
                        : "text-gray-500"
                    } `}
                  >
                    {userById.userById.data?.isActive ? "Active" : "InActive"}
                  </span>
                </label>
              </div>
            </div>
            <>
              <div className="relative overflow-x-auto shadow-md border-t-4 sm:rounded-lg mt-3">
                <div className="flex ">
                  <div className="md:w-1/7 w-fit m-10">
                    {/* <img
                      className="w-40 h-40 rounded-full cursor-pointer"
                      src={userById?.profilePic ? userById?.profilePic : avtar}
                      alt="User Image"
                      onClick={toggleFullscreen}
                    /> */}
                    <img
                      className="md:w-40 h-20 md:h-40 rounded-full cursor-pointer"
                      src={
                        userById.userById.data?.profilePic
                          ? userById.userById.data?.profilePic
                          : avtar
                      }
                      alt="User Image"
                      onClick={() =>
                        toggleFullscreen(userById.userById.data?.profilePic)
                      }
                    />

                    {fullscreen && fullPic && (
                      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#fff] bg-opacity-75">
                        <img
                          className="max-w-full max-h-full cursor-pointer"
                          src={fullPic}
                          alt="Fullscreen Image"
                        />
                        <button
                          type="button"
                          className="absolute top-[100px] right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center "
                          data-modal-hide="authentication-modal"
                          onClick={handleClose}
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                    )}
                    <div className="text-center mt-4 text-lg font-bold">
                      {userById.userById.data?.name}
                    </div>
                    <div className="flex flex-col text-center gap-2">
                      <span className="text-md font-bold w-max mt-4">
                        Invite Code
                      </span>
                      <span className="text-md italic text-2xl text-green-400">
                        {" "}
                        {userById.userById.data?.referralCode}
                      </span>
                    </div>
                  </div>
                  <div className="border-r-2 md:my-4"></div>
                  <div className="flex flex-col md:w-2/3 w-1/2 gap-2 m-10">
                    <div className="flex justify-between h-max">
                      <div className="flex gap-4">
                        <span className="text-md font-bold w-max">
                          E-mail Id
                        </span>
                        <span className="text-md w-max">
                          {userById.userById.data?.emailId}
                        </span>
                      </div>
                      <div className="flex gap-3">
                        <div
                          className={`hover:bg-gray-100 p-2 rounded-full ${
                            !checkAccess("PLAYER", "U")
                              ? "accessDenied"
                              : "hover:cursor-pointer"
                          }`}
                          onClick={() => {
                            if (checkAccess("PLAYER", "U")) {
                              navigate(
                                {
                                  pathname: "/updatePlayer",
                                },
                                { state: userById.userById.data }
                              );
                            }
                          }}
                        >
                          <MdModeEditOutline className="text-2xl" />
                        </div>

                        <div
                          onClick={() => {
                            if (checkAccess("PLAYER", "U")) {
                              setDeleteUserData({
                                name: `Quiz with id ${item?.id}`,
                                id: item?.id,
                              });
                              setOpenDeleteCUserModal(true);
                              //   setSearchValue("");
                            }
                          }}
                          className={`hover:bg-gray-100 p-2 rounded-full ${
                            !checkAccess("PLAYER", "U")
                              ? "accessDenied"
                              : "hover:cursor-pointer"
                          }`}
                        >
                          <MdDelete className="text-2xl" />
                        </div>
                      </div>
                    </div>
                    <div className="flex gap-4 ">
                      <span className="text-md font-bold w-max">Player Id</span>
                      <span className="text-md ">
                        {userById.userById.data?.id}
                      </span>
                    </div>
                    <div className="reletive border-b-2"></div>
                    <div className="flex justify-between">
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Mobile Phone
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.mobile || "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Total Quizzes Played
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.playedQuizCount || "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Joined On
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.createDate || "N/A"}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Referred By
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.referredBy || " N/A "}
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Wallet Balance
                          </span>
                          <span className="text-md">
                            {" "}
                            ₹{userById.userById.data?.wallet}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Total Quizzes Won
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.wonQuizCount}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Last Login
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.lastLogin || " N/A "}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Referred To
                          </span>
                          <span
                            onClick={() => {
                              setIsViewReferredToOpen(!isViewReferredToOpen);
                            }}
                            className="text-md cursor-pointer px-2 text-sm py-1 flex items-center justify-center mt-1 rounded-md text-white bg-green-500"
                          >
                            View
                          </span>
                        </div>
                      </div>
                      <div className="flex flex-col gap-4">
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Total Amount Lost
                          </span>
                          <span className="text-md">
                            {" "}
                            ₹{userById.userById.data?.totalAmountLost}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Total Amount Won
                          </span>
                          <span className="text-md">
                            {" "}
                            ₹{userById.userById.data?.totalAmountWon}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md font-bold w-max">
                            Player Type
                          </span>
                          <span className="text-md">
                            {" "}
                            {userById.userById.data?.role}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isViewReferredToOpen && (
                <div className=" w-full border shadow rounded-lg px-4 py-4 my-4  flex flex-col gap-3">
                  <div>
                    <div className="flex justify-start cursor-pointer">
                      <span
                        onClick={() => {
                          setIsViewReferredToOpen(!isViewReferredToOpen)
                        }}
                        className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
                      >
                        <MdArrowBack className="text-base" /> Back
                      </span>
                    </div>
                  </div>
                  <div className=" uppercase text-lg font-semibold">
                    referred to
                  </div>
                  <div className="relative overflow-x-auto">
                    {!referredToDataList?.length ? (
                      <div className=" w-full flex items-center justify-center font-semibold">
                        <span>No Data Found</span>
                      </div>
                    ) : (
                      <table className="w-full text-sm text-left text-gray-700 mb-4 ">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                          <tr>
                            {tableHeaders?.map((item, index) => (
                              <th
                                key={index}
                                scope="col"
                                className="px-6 py-3"
                              >
                                {item}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {referredToDataList?.map((item, index) => {
                            return (
                              <tr className="bg-white border-b py-[0.30rem] ">
                                <td className="px-6 py-2 ">
                                  <div>
                                    <div>{item?.id}</div>
                                  </div>
                                </td>
                                <td className="px-6 py-2">
                                  <div onClick={()=>
                                    {
                                      navigate('/viewPlayers',{state:{id:item?.id}})
                                    }
                                  }>
                                    <div className=" capitalize cursor-pointer hover:text-blue-500 w-fit ">{item?.name}</div>
                                  </div>
                                </td>
                                <td className="px-6 py-2 ">
                                  <div>
                                    <div>{item?.emailId}</div>
                                  </div>
                                </td>
                                <td className="px-6 py-2">
                                  <div>{item?.mobile}</div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {referredTotalPages > 1 && (
                    <div className="flex justify-end pr-5 pt-4">
                      <Pagination
                        totalPages={referredTotalPages}
                        currentPage={referredToCurrentPage}
                        setCurrentPage={handleReferredToPageChange}
                        // isLoading={allUser?.loading}
                      />
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${
                  fullscreen && fullPic ? "hidden" : "visible"
                } bg-white relative overflow-x-auto rounded-lg  shadow my-4`}
              >
                <div className="w-full mx-auto max-w-screen-xl md:flex md:items-center md:justify-between">
                  <ul className="flex  gap-4 md:pl-5 items-center w-max mt-3 text-smfont-medium text-gray-500  sm:mt-0">
                    {buttonList.map((item, index) => (
                      <li key={index}>
                     {(item.content !== undefined || item.content !== "")
                     ? (
                      <TooltipWrapper content={item.content} position={item.place} id="view-user-tooltip">
                      <button
                          disabled={item?.disabled}
                          className={`px-3 py-2 relative inline-flex bg-green-200 border-2 border-green-500 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:bg-green-400 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                            item?.disabled
                              ? "accessDenied"
                              : "hover:cursor-pointer"
                          }`}
                          onClick={() => handleButtonClick(item)}
                        >
                          {item?.name}
                        </button>
                        </TooltipWrapper>)
                        :( <button
                          disabled={item?.disabled}
                          className={`px-3 py-2 relative inline-flex bg-green-200 border-2 border-green-500 items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group hover:bg-green-400 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 ${
                            item?.disabled
                              ? "accessDenied"
                              : "hover:cursor-pointer"
                          }`}
                          onClick={() => handleButtonClick(item)}
                        >
                          {item?.name}
                        </button>)
                     }
                       
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
            {openAllWithdrawal && (
              <>
                <div>
                  <Transition
                    show={showDetails && activeButton === "viewWithdrawals"}
                  >
                    <Transition.Child
                      enter="transition ease-out duration-300 transform"
                      enterFrom="opacity-0 translate-y-2"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-2"
                    >
                      <AllWidthrawal
                        // tableHeaders={withdrawalTable}
                        dataArray={
                          allWithdrawal?.allWithdrawal?.data?.withdrawals
                        }
                        flage="allWithdrawal"
                      />
                    </Transition.Child>
                  </Transition>
                </div>
              </>
            )}

            {openUserAllPlayedQuiz && (
              <>
                <div>
                  <Transition
                    show={
                      openUserAllPlayedQuiz &&
                      activeButton === "viewPlayedQuizzes"
                    }
                  >
                    <Transition.Child
                      enter="transition ease-out duration-300 transform"
                      enterFrom="opacity-0 translate-y-2"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-2"
                    >
                      <AllWidthrawal
                        // tableHeaders={withdrawalTable}
                        dataArray={userAllPlayedQuizArray}
                        flage="userAllPlayedQuiz"
                      />
                      {totalPages > 1 && (
                        <div className="flex justify-end pr-5 pt-4">
                          <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            isLoading={allPlayedQuiz?.loading}
                          />
                        </div>
                      )}
                    </Transition.Child>
                  </Transition>
                </div>
              </>
            )}

            {openTransections && (
              <>
                <div>
                  <Transition
                    show={
                      openTransections && activeButton === "viewAllTransactions"
                    }
                  >
                    <Transition.Child
                      enter="transition ease-out duration-300 transform"
                      enterFrom="opacity-0 translate-y-2"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-2"
                    >
                      <AllWidthrawal
                        // tableHeaders={withdrawalTable}
                        dataArray={userAllTransectionsArray}
                        flage="viewAllTransactions"
                      />
                      {transectionsTotalPages > 1 && (
                        <div className="flex justify-end pr-5 pt-4">
                          <Pagination
                            totalPages={transectionsTotalPages}
                            currentPage={transectionsCurrentPage}
                            setCurrentPage={setTransectionsCurrentPage}
                            isLoading={allUserTransections?.loading}
                          />
                        </div>
                      )}
                    </Transition.Child>
                  </Transition>
                </div>
              </>
            )}

            {openBankAccount && (
              <>
                <div>
                  <Transition
                    show={
                      openBankAccount && activeButton === "viewBankAccounts"
                    }
                  >
                    <Transition.Child
                      enter="transition ease-out duration-300 transform"
                      enterFrom="opacity-0 translate-y-2"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-200 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-2"
                    >
                      <AllWidthrawal
                        // tableHeaders={bankAccountTable}
                        dataArray={
                          (activeTab === 1 &&
                            bankAccount?.bankAccount?.data?.bankAccounts) ||
                          (activeTab === 2 &&
                            upiAccount?.upiAccount?.data?.upiAccounts)
                        }
                        flage="Bank&Upi"
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        handleTabClick={handleTabClick}
                        token={token}
                        handleUserUpiAccount={handleUserUpiAccount}
                        handleUserBankAccount={handleUserBankAccount}
                      />
                    </Transition.Child>
                  </Transition>
                </div>
              </>
            )}
          </div>
        </div>
        {openDeleteCUserModal && (
          <DeleteModal
            data={item}
            handleDelete={handleDeleteUser}
            setOpenDeleteModal={setOpenDeleteCUserModal}
          />
        )}

        {isConfirmationDialogOpen && (
          <DeleteModal
            data={activeData}
            handleDelete={handleActiveUser}
            setOpenDeleteModal={setIsConfirmationDialogOpen}
            flage={isChecked}
            open={"Active"}
          />
        )}

        {openResetPasswordModal && (
          <PasswordModal
            heading={"Change Passcode"}
            nameLabel={"New Passcode"}
            submitLabel={"Save the Changes"}
            submitLabelsm={"Save Changes"}
            handleSubmit={handleChangePassword}
            openModal={openResetPasswordModal}
            setOpenModal={setOpenResetPasswordModal}
            secondNameLable={"Confirm Password"}
            flage={"resetPassword"}
          />
        )}

        {openAddBalanceModal && (
          <PasswordModal
            heading={"Add Balance"}
            nameLabel={"Enter Amount"}
            submitLabel={"Save the Changes"}
            submitLabelsm={"Save Changes"}
            handleSubmit={handleAddBalance}
            openModal={openAddBalanceModal}
            setOpenModal={setOpenAddbalanceModal}
            // secondNameLable={"Confirm Password"}
            flage={"addBalance"}
          />
        )}
      </>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userById: state?.GetUserByIdReducer,
    referredToData: state?.getReferredToReducer,
    allWithdrawal: state?.GetAllWithdrawalReducer,
    bankAccount: state?.getBankAccountReducer,
    upiAccount: state?.getUpiAccountReducer,
    allPlayedQuiz: state?.getUserAllPlayedQuizReducer,
    allUserTransections: state?.getUserAllTransectionReducer,
    activeUser: state?.activeUserReducer,
    addBalance: state?.addBalanceReducer,
  };
};

export default connect(mapStateToProps)(ViewUser);
