import { call, put } from "redux-saga/effects";
import { postFormData } from "../../components/Config/Apiinterface";
import {
  updateQuizBotsProbabilityFailure,
  updateQuizBotsProbabilitySuccess,
} from "../../Actions";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

export function* updateQuizBotsProbabilitySaga(action) {
  const path = "admin/quiz/botProbability";
  const token = action.payload.token;
  const body = action.payload.data;

  const ToastId = toast.loading("Loading ..");

  try {
    const response = yield debounce(postFormData, path, body, token);
    yield put(updateQuizBotsProbabilitySuccess(response));
    if (response.statusCode === 200) {
      toast.update(ToastId,
        {
          render:response.message,
          type:'success',
          autoClose:2000,
          isLoading:false 
        }
      );
      
    } else {
      toast.update(ToastId,
        {
          render:response.message,
          type:'error',
          autoClose:2000,
          isLoading:false
        }
      );
   
    }
  } catch (err) {
    yield put(updateQuizBotsProbabilityFailure(err));
    
  }
}
