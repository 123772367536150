import React from "react";
import TooltipATM from "./ui-elements/TooltipATM";
import { Tooltip as ReactTooltip } from "react-tooltip";

export const Input = ({
  label,
  register,
  required,
  name,
  type,
  totalWinnings,
  value,
  disabled,
  placeholder,
  hidden,
  errorMessage,
  submitted,
  place = "top",
  content = "",
}) => {
  const isEmpty = value === "";
  return (
    <>
      {content !== "" ? (
        <div className="flex flex-row justify-start align-middle gap-1 mb-2 md:mb-2">
          <label className="block text-sm font-medium text-gray-900 ">
            {label}
          </label>
          <div className="my-auto">
            <TooltipATM
              dataTooltipId={"add-question-tooltip"}
              dataTooltipPlace={place}
              dataTooltipHtml={content}
              className={"cursor-pointer"}
            />
          </div>
        </div>
      ) : (
        <label className="block mb-2 text-sm font-medium text-gray-900 md:mb-2">
          {label}
        </label>
      )}

      <input
        {...register(name, { required })}
        type={type}
        name={name}
        value={value}
        required={required}
        placeholder={placeholder || label}
        onChange={totalWinnings}
        disabled={disabled}
        hidden={hidden}
        autoComplete="off"
        className={`${
          type !== "file" ? "p-2.5" : ""
        } bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full  mb-1 md:mb-2 font-medium ${
          submitted && errorMessage ? "border-red-500" : ""
        }`}
      />
      {/* {(submitted && errorMessage ) && (
        <p className="text-red-500 text-xs mt-1"> {!value && errorMessage}</p>
      )} */}
      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="add-question-tooltip"
        className=" font-semibold"
      />
    </>
  );
};

export const quiz_Mode = [
  {
    id: "Classic",
    name: "Classic",
    data: "Classic",
  },
  {
    id: "Winner takes all",
    name: "Winner takes all",
    data: "Winner takes all",
  },
  {
    id: "Mega contest",
    name: "mega contests(over 2000 spots)",
    data: "Mega contest",
  },
  {
    id: "Equal distribution",
    name: "Equal distribution",
    data: "Equal distribution",
  },
];

const checkTotalSpot = () => {};

export const createQuizButton = [
  {
    id: "1",
    label: "Create Quiz",
    function: () => checkTotalSpot(),
  },
  {
    id: "2",
    name: "Winner Breakup",
    function: () => checkTotalSpot(),
  },
  {
    id: "3",
    label: "Selection Questions",
    function: () => checkTotalSpot(),
  },
];

export const style = {
  buttonClass:
    "w-full md:w-1/4 text-white bg-gradient-to-r from-[#4CAF50] to-[#23304C] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
};

export const levelOptions = [
  {
    id: "Easy",
    name: "Easy",
  },
  {
    id: "Medium",
    name: "Medium",
  },
  {
    id: "Difficult",
    name: "Difficult",
  },
];

export const QuestionsTypeOption = [
  {
    id: "FREE",
    name: "Free",
  },
  {
    id: "CASH",
    name: "Cash",
  },
];

export const questionTypeOption = [
  {
    id: "",
    name: "Select Type",
  },
  {
    id: true,
    name: "Free question",
  },
  {
    id: false,
    name: "Cash question",
  },
];

export const resetQuestionLocalFilters = () => {
  // Questions
  localStorage.removeItem("qCategoryFilter");
  localStorage.removeItem("qTopicFilter");
  localStorage.removeItem("qLevelFilter");
  localStorage.removeItem("qcurrentPage");
  localStorage.removeItem("questionDrop");
};

export const resetUserLocalFilters = () => {
  // User
  localStorage.removeItem("sortValue");
  localStorage.removeItem("isSortOrder");
  localStorage.removeItem("currentPage");
  localStorage.removeItem("searchValue");
  localStorage.removeItem("userDrop");
};

export const resetQuizLocalFilters = () => {
  // quiz
  localStorage.removeItem("quizFilter");
  localStorage.removeItem("quizSort");
  localStorage.removeItem("quizSortOrder");
  localStorage.removeItem("quizTopicFilter");
  localStorage.removeItem("quizCatagoryFilter");
  localStorage.removeItem("quizSearchValue");
  localStorage.removeItem("quizCurrentPage");
  localStorage.removeItem("quizDrop");
};

export const resetCustomerSupport = () => {
  localStorage.removeItem("customerSearchValue");
  localStorage.removeItem("customerSortValue");
  localStorage.removeItem("customerDrop");
  localStorage.removeItem("customerSearchDropdowne");
  localStorage.removeItem("customerCurrentPage");
};

export const transactionResetLocalData = () => {
  localStorage.removeItem("transFilterValue");
  localStorage.removeItem("transSortValue");
  localStorage.removeItem("transSortOrder");
  localStorage.removeItem("transSearchDropdown");
  localStorage.removeItem("transCurrentPage");
  localStorage.removeItem("transSearchValue");
  localStorage.removeItem("transDrop");
  localStorage.removeItem("transactionStartDate");
  localStorage.removeItem("transactionEndDate");
  localStorage.removeItem("dateRange-transactionFilterDate");
};

export const notificationLocalReset = () => {
  localStorage.removeItem("notifyFilterValue");
  localStorage.removeItem("notifyDrop");
  localStorage.removeItem("notifyDrop");
};
