// // scenarioUtils.js
// export const fetchAnalyticsData = (data) => {
//   const allowedScenarios = [
//     "category_change",
//     "topic_change",
//     "quiz_selection",
//     "quiz_joined",
//     "quiz_started",
//     "quiz_resume",
//     "quiz_complete",
//     "quiz_cancel",
//     "quiz_schedule",
//     "add_cash_success",
//     "add_cash_pending",
//     "add_cash_failed",
//     "withdraw_cash",
//     "scratch_card",
//     "user_login",
//     "user_registration",
//     "add_cash",
//   ];
//   const eventsArray = data || [];
 


//   const scenarioMap = {};

//   eventsArray.forEach((event) => {
//     const scenario = event.scenario_name;
//     const platform = event.platform;
//     if (allowedScenarios.includes(scenario)) {
//       if (scenarioMap[scenario]) {
//         if (scenarioMap[scenario].platforms[platform]) {
//           scenarioMap[scenario].platforms[platform].count += 1;
//           scenarioMap[scenario].platforms[platform].eventDetails.push(event);
//         } else {
//           scenarioMap[scenario].platforms[platform] = {
//             count: 1,
//             eventDetails: [event],
//           };
//         }
//       } else {
//         scenarioMap[scenario] = {               
//           platforms: {
//             [platform]: {
//               count: 1,
//               eventDetails: [event],
//             },
//           },
//         };
//       }
//     }
//   });

//   const uniqueScenarios = Object.keys(scenarioMap);

//   return { scenarioMap, uniqueScenarios };
// };

export const fetchAnalyticsData = (data) => {
  const scenarioMap = {};
  const uniqueScenarios = [];

  data.forEach((item) => {
    const { scenario_name, screen_name, values } = item;
    if (!scenarioMap[scenario_name]) {
      scenarioMap[scenario_name] = {
        count: 0,
        platforms: {
          [screen_name]: {
            count: 1,
            eventDetails: [values],
          },
        },
      };
      uniqueScenarios.push(scenario_name);
    } else {
      scenarioMap[scenario_name].count += 1;
      if (scenarioMap[scenario_name].platforms[screen_name]) {
        scenarioMap[scenario_name].platforms[screen_name].count += 1;
        scenarioMap[scenario_name].platforms[screen_name].eventDetails.push(
          values
        );
      } else {
        scenarioMap[scenario_name].platforms[screen_name] = {
          count: 1,
          eventDetails: [values],
        };
      }
    }
  });

  return { scenarioMap, uniqueScenarios };
};
