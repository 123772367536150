import React, { useState } from "react";
import CheckboxGroup from "./selectOption";
import { useDebouncedInput } from "../../customHook";
import icon from ".";
import { toast } from "react-toastify";
export default function QuestionForm(props) {
  const {
    register,
    setValue,
    error,
    selectedOption,
    setSelectedOption,
    watch,
    setFlag,
    flag,
    isImage,
    item,
  } = props;

  const options = [
    { option: "A", prevImage: item?.optionsEn?.A?.imageUrl },
    { option: "B", prevImage: item?.optionsEn?.B?.imageUrl },
    { option: "C", prevImage: item?.optionsEn?.C?.imageUrl },
    { option: "D", prevImage: item?.optionsEn?.D?.imageUrl },
  ];

  const callbackFunction = (value) => {
    setTimeout(() => {
      fetch(
        `https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=hi&dt=t&q=${encodeURIComponent(
          value
        )}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (flag && data[0] && data[0][0] && data[0][0][0]) {
            setValue("descriptionHi", data[0][0][0]);
          }
        })
        .catch((err) => console.error(err));
    }, 300);
    setValue("descriptionHi", "");
  };
  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");


  // const handleImageChangeEn = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const name = file.name;
  //     const extension = name.split(".").pop();
  //     const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;
  //     setFileName(shortenedName + "." + extension);
  //   }
  // };

  // Image handle with dimension validation

  const handleImageChangeEn = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;

      const img = new Image();
      img.onload = () => {
        if (
          Math.abs(img.width - 600) <= 100 &&
          Math.abs(img.height - 300) <= 100
        ) {
          setFileName(shortenedName + "." + extension);
        } else {
          toast.info("Question image dimensions should be approx 600 x 300");
          setFileName("")
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const handleImageChangeHi = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;
      setFileName1(shortenedName + "." + extension);
    }
  };

  const [, setInputValue] = useDebouncedInput(callbackFunction, 1000);
  const onChangeEn = (e) => {
    if (e.target.value) {
      setInputValue(e.target.value);
      setFlag(true);
    } else {
      setInputValue(e.target.value);
      setValue("descriptionHi", "");
    }
  };
  return (
    <>
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 gap-8">
          <div className="space-y-6">
            {/* Question Description in English */}
            <div className="flex items-center gap-4">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Question Description in English
                </label>
                <textarea
                  rows="2"
                  name="descriptionEn"
                  {...register("descriptionEn")}
                  onChange={onChangeEn}
                  required
                  className="w-full border border-gray-50 rounded-lg p-1 text-sm bg-gray-50"
                ></textarea>
              </div>
              <div className=" relative">
                <label className="block text-sm font-medium text-gray-700">
                  Upload Image
                </label>
                <label className="block border border-gray-50 rounded-lg w-max p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                  <input
                    type="file"
                    className="hidden"
                    id="imageInput"
                    onChange={handleImageChangeEn}
                  />

                  {/* <span className="text-center text-gray-500 w-full flex gap-1 text-sm">
                    <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                    <span className="truncate">
                      { fileName ? fileName : "Choose a file"}
                    </span>
                  </span> */}
                  <span className="text-center text-gray-500 w-full flex gap-1 text-sm">
                    <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                    <span className="truncate">
                      {fileName
                        ? fileName
                        : item?.descriptionEn?.imageUrl
                        ? (() => {
                            // Extract the filename and remove any query parameters
                            const fullFileName = item.descriptionEn.imageUrl
                              .split("/o/")[1]
                              ?.split("?")[0];
                            const nameParts = fullFileName.split(".");
                            const baseName = nameParts[0];
                            const extension = nameParts[nameParts.length - 1];

                            // Truncate the base name and add the extension
                            return baseName.length > 5
                              ? `${baseName.slice(0, 5)}...${extension}`
                              : fullFileName;
                          })()
                        : "Choose a file"}
                    </span>
                  </span>
                </label>
                {!fileName && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 absolute">
                    * image size should be 600x300 *
                  </span>
                )}
              </div>
            </div>
            {/* Select Answer (English) */}
            <div>
              <div className="flex items-center gap-1">
                {/* Left-aligned label */}
                <label className="text-sm font-medium text-gray-700">
                  Select Answer
                </label>
                {isImage && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 ">
                    * image size should be 400x400 *
                  </span>
                )}
              </div>

              <CheckboxGroup
                register={register}
                watch={watch}
                options={options}
                placholder={"Option"}
                Ans={"answerEn.option"}
                name={"optionsEn"}
                setValue={setValue}
                error={error}
                isImage={isImage}
              />
            </div>
          </div>
          {/* Right Section - Hindi */}
          <div className="space-y-6">
            {/* प्रश्न विवरण हिंदी में */}
            <div className="flex items-center gap-4">
              <div className="w-full">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  प्रश्न विवरण हिंदी में
                </label>
                <textarea
                  rows="2"
                  name="descriptionHi"
                  {...register("descriptionHi")}
                  required
                  className="w-full border border-gray-50 rounded-lg p-1 text-sm bg-gray-50"
                ></textarea>
              </div>
              <div className=" relative">
                <label className="block text-sm font-medium text-gray-700">
                  तस्वीर अपलोड करें
                </label>
                <label className="block border border-gray-50 rounded-lg w-max p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                  <input
                    type="file"
                    className="hidden"
                    id="imageInput"
                    onChange={handleImageChangeHi}
                  />
                  <span className="text-center text-gray-500 w-full flex gap-1 text-sm">
                    <img src={icon} className="w-6 h-6 p-1" alt="icon" />
                    <span className="truncate">
                      {/* {fileName1 ? fileName1 : "Choose a file"} */}
                      {fileName
                        ? fileName
                        : item?.descriptionEn?.imageUrl
                        ? (() => {
                            // Extract the filename and remove any query parameters
                            const fullFileName = item.descriptionEn.imageUrl
                              .split("/o/")[1]
                              ?.split("?")[0];
                            const nameParts = fullFileName.split(".");
                            const baseName = nameParts[0];
                            const extension = nameParts[nameParts.length - 1];

                            // Truncate the base name and add the extension
                            return baseName.length > 5
                              ? `${baseName.slice(0, 5)}...${extension}`
                              : fullFileName;
                          })()
                        : "Choose a file"}
                    </span>
                  </span>
                </label>
                {!fileName && (
                  <span className=" text-[0.710rem] font-semibold text-blue-400 absolute">
                    * image size should be 600x300 *
                  </span>
                )}
              </div>
            </div>
            {/* Select Answer (Hindi) */}
            {!isImage && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  उत्तर चुनें
                </label>
                <CheckboxGroup
                  register={register}
                  watch={watch}
                  options={options}
                  placholder={"विकल्प"}
                  Ans={"answerHi.option"}
                  name={"optionsHi"}
                  setValue={setValue}
                  error={error}
                  isImage={isImage}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
