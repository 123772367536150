import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router";
import icon from "../../layout/addQuestionLayout"; // Adjust the path as needed

export default function EditQuestionForm() {
  const options = ["A", "B", "C", "D"];
  const location = useLocation();
  const receivedata = location.state;

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [isImage, setIsImage] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileName1, setFileName1] = useState("");
  const [fileNames, setFileNames] = useState({}); // Manage file names for options

  useEffect(() => {
    if (receivedata) {
      // Set question descriptions
      setValue("descriptionEn", receivedata.descriptionEn?.text || "");
      setValue("descriptionHi", receivedata.descriptionHi?.text || "");

      // Initialize options for English
      if (receivedata.optionsEn) {
        options.forEach((option) => {
          const optionData = receivedata.optionsEn[option];
          if (optionData) {
            setValue(`optionsEn.${option}.text`, optionData.text || "");
            setFileNames((prev) => ({
              ...prev,
              [option]: optionData.imageUrl || "",
            }));
          }
        });
      }

      // Initialize options for Hindi
      if (receivedata.optionsHi) {
        options.forEach((option) => {
          const optionData = receivedata.optionsHi[option];
          if (optionData) {
            setValue(`optionsHi.${option}.text`, optionData.text || "");
            setFileNames((prev) => ({
              ...prev,
              [option]: optionData.imageUrl || "",
            }));
          }
        });
      }

      // Other fields
      setValue("isImagePresentInQuestion", receivedata.isImagePresentInQuestion || false);
      setValue("isFree", receivedata.isFree || false);
    }
  }, [receivedata, setValue]);

  const handleImageChangeEn = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;
      setFileName(shortenedName + "." + extension);
    }
  };

  const handleImageChangeHi = (e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 10 ? name.slice(0, 10) + "..." : name;
      setFileName1(shortenedName + "." + extension);
    }
  };

  const handleFileChange = (option, e) => {
    const file = e.target.files[0];
    if (file) {
      const name = file.name;
      const extension = name.split(".").pop();
      const shortenedName = name.length > 6 ? name.slice(0, 6) + "..." : name;
      setFileNames((prev) => ({
        ...prev,
        [option]: shortenedName + "." + extension,
      }));
    }
  };

  const onSubmit = (data) => {
    // console.log("Edited Data:", data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="container mx-auto space-y-6">
        <h1 className="text-xl font-bold">Edit Question Form</h1>
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-2 gap-8">
            {/* Left Section - English */}
            <div className="space-y-6">
              {/* Question Description in English */}
              <div className="flex items-center gap-4">
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Question Description in English
                  </label>
                  <textarea
                    rows="2"
                    {...register("descriptionEn", { required: true })}
                    className="w-full border border-gray-300 rounded-lg p-2 text-sm"
                  ></textarea>
                  {errors.descriptionEn && (
                    <p className="text-red-500 text-sm">
                      This field is required.
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Upload Image
                  </label>
                  <label className="block border rounded-lg p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleImageChangeEn}
                    />
                    <span className="text-sm text-gray-500 flex gap-1">
                      <img src={icon} className="w-6 h-6" alt="icon" />
                      <span>{fileName || "Choose a file"}</span>
                    </span>
                  </label>
                </div>
              </div>

              {/* Options for English */}
              <div>
                <label className="text-sm font-medium text-gray-700">
                  English Options
                </label>
                {options.map((option) => (
                  <div
                    className="flex gap-4 items-center p-2 flex-wrap"
                    key={option}
                  >
                    <div className="w-full flex p-1">
                      <input
                        {...register(`optionsEn.${option}.text`)}
                        type="text"
                        placeholder={`Option ${option} (English)`}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 font-medium"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Section - Hindi */}
            <div className="space-y-6">
              {/* प्रश्न विवरण हिंदी में */}
              <div className="flex items-center gap-4">
                <div className="w-full">
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    प्रश्न विवरण हिंदी में
                  </label>
                  <textarea
                    rows="2"
                    {...register("descriptionHi", { required: true })}
                    className="w-full border border-gray-300 rounded-lg p-2 text-sm"
                  ></textarea>
                  {errors.descriptionHi && (
                    <p className="text-red-500 text-sm">
                      This field is required.
                    </p>
                  )}
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    तस्वीर अपलोड करें
                  </label>
                  <label className="block border rounded-lg p-2.5 bg-gray-50 cursor-pointer hover:bg-gray-100">
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleImageChangeHi}
                    />
                    <span className="text-sm text-gray-500 flex gap-1">
                      <img src={icon} className="w-6 h-6" alt="icon" />
                      <span>{fileName1 || "Choose a file"}</span>
                    </span>
                  </label>
                </div>
              </div>

              {/* Options for Hindi */}
              <div>
                <label className="text-sm font-medium text-gray-700">
                  विकल्प हिंदी में
                </label>
                {options.map((option) => (
                  <div
                    className="flex gap-4 items-center p-2 flex-wrap"
                    key={option}
                  >
                    <div className="w-full flex p-1">
                      <input
                        {...register(`optionsHi.${option}.text`)}
                        type="text"
                        placeholder={`Option ${option} (Hindi)`}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 font-medium"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Save Changes
          </button>
        </div>
      </div>
    </form>
  );
}
