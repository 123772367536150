import {
  GET_REWARD_DATA_REQUEST,
  GET_REWARD_DATA_SUCCESS,
  GET_REWARD_DATA_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  rewardData: {},
  loading: false,
  error: null,
};

const getRewardDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REWARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case GET_REWARD_DATA_SUCCESS:
      return {
        ...state,
        rewardData: action.payload,
        loading: false,
        error: null,
      };

    case GET_REWARD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default getRewardDataReducer;
