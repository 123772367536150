import {
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAILURE,
} from "../../Utils/Constants";

const initialState = {
  updateBannerData: {},
  loading: false,
  error: null,
};

const updateBannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BANNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case UPDATE_BANNER_SUCCESS:
      return {
        ...state,
        updateBannerData: action.payload,
        loading: false,
        error: null,
      };

    case UPDATE_BANNER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default updateBannerReducer;
