import "./custom.css";
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { WinnerBreakupModal } from "../modal/WinnerBreakupModal";
import { Input, style } from "../constant";
import { SelectQuizMode } from "../../layout";
import CheckboxBtn from "../ui-elements/CheckboxBtn";
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from "react-router";
import { SpinnerLoader } from "../Loaders/SpinnerLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../ui-elements/TooltipATM";

const CreateQuiz = (props) => {  
  const {
    onSubmit,
    categories,
    handleStartTimeChange,
    topics,
    register,
    handleSubmit,
    checkTotalSpot,
    openQuizAddModal,
    setOpenQuizAddModal,
    winningBreackup,
    totalWinnings,
    handleSortValues,
    setTotalWinningAmount,
    setValue,
    setQuizMod,
    setIsRepeating,
    isRepeating,
    isEnabled,
    setIsEnabled,
    isSubmitSuccessful,
    totalWinningAmount,
    cashChecked,
    botChecked,
    specialChecked,
    freeChecked,
    setCashChecked,
    setBotChecked,
    setSpecialChecked,
    setFreeChecked,
    isPrime,
    setIsPrime,
    isDaily,
    setIsDaily,
    isWeekly,
    setIsWeekly,
    setFrequency,
    AddQuiz,
    submited,
    minUser,
    isPractice,
    setIsPractice,
    specialQuizUsers,
    getValues,
    cr,
    winingData,
    availableQuestions,
    setIsFree
  } = props;

  const getDate15MinutesFromNow = () => {
    let date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    return date;
  };

  const [startTime, setStartTime] = useState(getDate15MinutesFromNow());
  const [isChecked, setIsChecked] = useState(false);

  const handleTimeChange = (date) => {
    setStartTime(date);
    handleStartTimeChange(date);
  };

  const navigate = useNavigate();

  const checkboxData = [
    {
      id: 1,
      label: "Cash",
      flage: "cash",
      place: "right",
      content:
        "If enabled, the quiz type will be a cash quiz, which will have an entry fee and a<br/> winning amount. The winning amount will be calculated accordingly.  <br/>Note:<br/><ul><li>1. The cash parameter can be used with other parameters such as Special, Prime, <br/>etc., altering the quiz type/behavior. (Refer to specific parameter details to<br/> understand their interactions.)  </li><li>2. The quiz will only go live if Entry Fee × Filled Spots ≤ Total Winning Amount;<br/> otherwise, it will be canceled. (This behavior may change if combined with other<br/> parameters like Special.)</li></ul>",
    },
    {
      id: 2,
      label: "Free",
      flage: "free",
      place: "right",
      content:
        "If enabled, the quiz type will be a free quiz, with an entry fee <br/>of 0 and no winning amount<br/>Note:<br/><ul><li>1. The quiz will start at the given time if at least one user joins.</li></ul>",
    },
    {
      id: 2,
      label: "Practice",
      flage: "practise",
      place: "bottom-right",
      content:
        "If enabled, the quiz will be an Immediate quiz, which can be played instantly.<br/>Note: <br/><ul><li>1. If Quiz-Repeating is enabled, the same quiz can be played by multiple users.  <li/><li>2. If Quiz-Repeating is not enabled, the quiz will be marked as completed <br/>as soon as a single user plays it. </li><li> 3. Daily and Weekly options should not be enabled for this type of quiz. </li></ul>",
    },
    {
      id: 8,
      label: "Special",
      flage: "special",
      place: "bottom-right",
      content:
        "If enabled, the quiz winning amount can be set manually.<br/>Note:<br/><ul<li>1. Bots cannot be added to a special quiz.</li><li>2. The quiz depends on the Quiz Spot Percentage module; it will<br/> only go live if the percentage condition is satisfied.</li></ul>",
    },
    {
      id: 3,
      label: "Enable",
      flage: "Quiz-Enable",
      place: "bottom-bottom-left",
      content:
        "If enabled, the quiz will be visible to users and listed <br/>in the app; otherwise, it will not be visible in the app.",
    },
    {
      id: 4,
      label: "Quiz-Repeating",
      flage: "Quiz-Reapating",
      place: "bottom-left",
      content:
        "This should be enabled if the quiz needs to be duplicated or auto-created <br/>at the desired frequency, which can be daily or weekly.<br/> Note:<br/><ul><li> 1. Duplication Scenario: If the spots for the quiz are filled and there are <br/>at least 10 seconds remaining before the quiz goes live, another quiz <br/>will be created with the same parameters.</li><li> 2. Auto-Creation Scenario: If the frequency is set to daily or weekly, a new <br/>quiz with the same parameters will be created 36 hours <br/>before the start time.</li></ul>",
    },
    {
      id: 5,
      label: "Prime",
      flage: "Prime",
      place: "bottom-left",
      content:
        "If enabled, this type of quiz will appear at the top of the quiz <br/>listing in the app, regardless of its start time.",
    },
    {
      id: 6,
      label: "Daily",
      flage: "Daily",
      place: "bottom-left",
      content:
        "If enabled, the current quiz will be auto-created using the specified <br/>daily or weekly frequency. These quizzes will be created 36 hours <br/>before their start time.  <br/>Note:<br/><ul<li>1. This feature can only be used if the Quiz-Repeating parameter is enabled.</li></ul>",
    },
    {
      id: 7,
      label: "Weekly",
      flage: "Weekly",
      place: "left",
      content:
        "If enabled, the current quiz will be auto-created using the specified <br/>daily or weekly frequency. These quizzes will be created 36 hours <br/>before their start time.  <br/>Note:<br/><ul<li>1. This feature can only be used if the Quiz-Repeating parameter is enabled.</li></ul>",
    },
    {
      id: 8,
      label: "Add Bots",
      flage: "bots",
      place: "left",
      content:
        "If enabled, bots will join and play the quiz. <br/>Note:<br/><ul<li> 1. Bots' participation in the quiz depends on the Bot Quiz Joining <br/>Probability. Bots will only join if the required percentage of <br/>users has already joined the quiz.  </li><li>2. The Bot Winning Probability can be set from the Bot Probability <br/>module. The winning percentage for bots will range from 0 to the<br/>specified value (e.g., if set to 50%, the bot's winning probability will <br/>vary between 0 and 50, not exactly 50) </li> </ul>",
    },
  ];

  function handleIsReapting() {
    setIsRepeating(!isRepeating);
    if (!isRepeating) {
      setIsDaily(false);
      setIsWeekly(false);
    }
  }

  function handleIsEnbled() {
    setIsEnabled(!isEnabled);
  }

  function handleIsPrime() {
    setIsPrime(!isPrime);
  }

  function handleIsDaily() {
    setIsDaily(true);
    setIsWeekly(false);
    setFrequency("Daily");
  }

  function handleIsWeekly() {
    setIsWeekly(true);
    setIsDaily(false);
    setFrequency("Weekly");
  }

  const handleClick = (item) => {
    if (item.flage === "Quiz-Enable") {
      handleIsEnbled();
    } else if (item.flage === "Quiz-Reapating") {
      handleIsReapting();
    } else if (item.flage === "cash") {
      handleCashChange();
    } else if (item.flage === "bots") {
      handleBotChange();
    } else if (item.flage === "special") {
      handleSpecialChange();
    } else if (item.flage === "free") {
      handleFreeChange();
    } else if (item.flage === "Prime") {
      handleIsPrime();
    } else if (item.flage === "Daily") {
      handleIsDaily();
    } else if (item.flage === "Weekly") {
      handleIsWeekly();
    } else if (item.flage === "practise") {
      handlePracticeChange();
    }
  };

  const handleCashChange = () => {
    if (!cashChecked) {
      setCashChecked(true);
      setFreeChecked(false);
      setSpecialChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
      setIsFree(false);
    }
  };
  const handleBotChange = () => {
    if (!botChecked) {
      setBotChecked(true);
      setCashChecked(true);
      setFreeChecked(false);
      setSpecialChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
    } else {
      setBotChecked(false);
    }
  };
  const handleSpecialChange = () => {
    if (!specialChecked) {
      setSpecialChecked(true);
      setCashChecked(true);
      setFreeChecked(false);
      setBotChecked(false);
      setIsPractice(false);
      setIsChecked(false);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
      setIsFree(false);
    } else {
      setSpecialChecked(false);
      setCashChecked(true);
    }
  };

  const handleFreeChange = () => {
    if (!freeChecked) {
      setValue("entryFees", 0);
      setValue("totalWinnings", 0);
      setTotalWinningAmount(0);
      const e = {
        target: {
          name: "totalWinnings",
          value: 0,
        },
      };
      totalWinnings(e);
      setFreeChecked(true);
      setIsPractice(false);
      setCashChecked(false);
      setBotChecked(false);
      setSpecialChecked(false);
      setIsChecked(true);
      setIsRepeating(false);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
      setIsFree(true);
    }
  };
  const handlePracticeChange = () => {
    // setIsRepeating(false);
    if (!isPractice) {
      setValue("entryFees", 0);
      setValue("totalWinnings", 0);
      setTotalWinningAmount(0);
      const e = {
        target: {
          name: "totalWinnings",
          value: 0,
        },
      };
      setValue("totalSpots", 1);
      totalWinnings(e);
      setFreeChecked(false);
      setCashChecked(false);
      setBotChecked(false);
      setSpecialChecked(false);
      setIsChecked(true);
      setIsPractice(true);
      setIsRepeating(true);
      setIsDaily(false);
      setIsWeekly(false);
      setIsPrime(false);
      setIsFree(true);
    }
  };

  let calculateTotalWin =
    winingData.totalSpots * winingData.entryFees -
    (winingData.totalSpots * winingData.entryFees * cr) / 100;
  const handleTotalWinningsChange = (e) => {
    const value = e?.target?.value;
    if (specialChecked) {
      setTotalWinningAmount(value);
    } else if (value <= calculateTotalWin) {
      setTotalWinningAmount(value);
    } else {
      setTotalWinningAmount(totalWinningAmount);
    }
  };
  const categoryOptions = categories?.map((item) => ({
    id: item.id,
    name: item.nameEng,
  }));

  const topicsOptions = topics?.map((item) => ({
    id: item?.id,
    name: item?.nameEng,
  }));

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh] relative ">
          <div className="flex justify-start cursor-pointer">
            <span
              onClick={() => navigate(-1)}
              className="bg-blue-100 hover:bg-blue-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded  border border-blue-400 inline-flex items-center justify-center"
            >
              <MdArrowBack className="text-base" /> Back
            </span>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 mt-4 pb-3">
            <div className="font-bold uppercase text-lg text-center">
              Create Quiz
            </div>
          </div>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-2">
              <div className="flex gap-2 flex-wrap space-x-3">
                {checkboxData.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-row justify-start align-middle"
                  >
                    <div>
                      <CheckboxBtn
                        handleClick={() => handleClick(item)}
                        label={item.label}
                        checked={
                          (item.flage === "cash" && cashChecked) ||
                          (item.flage === "bots" && botChecked) ||
                          (item.flage === "special" && specialChecked) ||
                          (item.flage === "free" && freeChecked) ||
                          (item.flage === "Quiz-Enable" && isEnabled) ||
                          (item.flage === "Quiz-Reapating" && isRepeating) ||
                          (item.flage === "Prime" && isPrime) ||
                          (item.flage === "Daily" && isDaily) ||
                          (item.flage === "Weekly" && isWeekly) ||
                          (item.flage === "practise" && isPractice)
                        }
                        disabled={
                          (item.flage === "practise" ||
                            item.flage === "Weekly" ||
                            item.flage === "Daily") &&
                          isPractice
                        }
                      />
                    </div>
                    <div className="my-auto">
                      <TooltipATM
                        dataTooltipId={"add-question-tooltip"}
                        dataTooltipPlace={item?.place}
                        dataTooltipHtml={item?.content}
                        className={"cursor-pointer"}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="grid gap-4 mb-4 sm:grid-cols-2 lg:grid-cols-3">
              <SelectQuizMode
                categories={categoryOptions}
                topics={topicsOptions}
                handleSortValues={handleSortValues}
                setQuizMod={setQuizMod}
                register={register}
                disabled={freeChecked || isPractice}
              />
              <div>
                <Input
                  type="number"
                  name="entryFees"
                  label="Entry Fees"
                  register={register}
                  totalWinnings={totalWinnings}
                  required={!freeChecked && !isPractice}
                  disabled={freeChecked || isPractice}
                  place="right"
                  content="The fee required to join the quiz."
                />
              </div>
              <div>
                <Input
                  type="number"
                  name="totalSpots"
                  label="Total Spots"
                  register={register}
                  disabled={isPractice}
                  totalWinnings={freeChecked ? null : totalWinnings}
                  required={!freeChecked && !isPractice}
                  place="bottom"
                  content="The total number of users allowed to join the quiz."
                />
              </div>
              <div>
                <Input
                  name="noOfQuestions"
                  label="Total number of questions"
                  register={register}
                  place="left"
                  content="The total number of questions that will be served to users when the quiz is live."
                  required
                />
              </div>
              <div>
                <Input
                  name="duration"
                  label="Quiz Duration (mins)"
                  place="right"
                  content="Time in minutes for which the quiz will remain live before entering the review state."
                  register={register}
                  required
                />
              </div>
              <div className="w-full">
                {isPractice ? (
                  <>
                  <div className="flex flex-row justify-start align-middle gap-1 mb-2 md:mb-2">
                  <label className="block text-sm font-medium text-gray-900">
                      Start Time
                    </label>
                      <div className="my-auto">
                        <TooltipATM
                          dataTooltipId={"add-question-tooltip"}
                          dataTooltipPlace={"top"}
                          dataTooltipHtml={"The quiz will go live at this specified time. To play the quiz, it <br/>must be joined before this time. The time format should be <br/>'DD-MM-YYYY HH24:MI:SS' (e.g., 10-01-2025 14:05:44)."}
                          className={"cursor-pointer"}
                        />
                      </div>
                    </div>
                    <input
                      type="text"
                      value={"IMMEDIATELY"}
                      disabled={isPractice}
                      autoComplete="off"
                      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2 font-medium`}
                    />
                  </>
                ) : (
                  <>
                    <div className="flex flex-row justify-start align-middle gap-1 mb-2 md:mb-2">
                      <label className="block text-sm font-medium text-gray-900 ">
                        {"Start Time"}
                      </label>
                      <div className="my-auto">
                        <TooltipATM
                          dataTooltipId={"add-question-tooltip"}
                          dataTooltipPlace={"top"}
                          dataTooltipHtml={"The quiz will go live at this specified time. To play the quiz, it <br/>must be joined before this time. The time format should be <br/>'DD-MM-YYYY HH24:MI:SS' (e.g., 10-01-2025 14:05:44)."}
                          className={"cursor-pointer"}
                        />
                      </div>
                    </div>

                    <DatePicker
                      key={isSubmitSuccessful ? "reset" : "datepicker"}
                      selected={startTime}
                      onChange={handleTimeChange}
                      showTimeSelect
                      required
                      dateFormat="dd-MM-yyyy HH:mm:ss"
                      minDate={new Date()}
                      className="custom-date-picker bg-gray-50 border border-gray-900 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-1 md:mb-2  font-medium "
                    />
                  </>
                )}
              </div>
              <div className="flex flex-col sm:flex-row gap-5">
                <div className="sm:w-full">
                  <Input
                    type="number"
                    name="totalWinnings"
                    label="Total Winnings Amount"
                    register={register}
                    required={!freeChecked && !isPractice}
                    disabled={isPractice}
                    value={freeChecked ? 0 : totalWinningAmount}
                    totalWinnings={handleTotalWinningsChange}
                    content="This amount is calculated using the formula: (Entry Fee * Total Spots) -<br/> Commission Amount (which is 20% unless modified). For special quizzes,<br/> this amount can be adjusted."
                    place="left"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => checkTotalSpot()}
                  disabled={isChecked}
                  className="sm:w-full md:mt-4 lg:mt-4 sm:mt-0 text-left text-blue-500"
                >
                  <span>{"Winner Breakup"}</span>
                </button>
              </div>
              <div>
                <Input
                  name="easyCount"
                  label="Enter Easy Questions"
                  register={register}
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  required
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="right"
                />
              {availableQuestions!==null && availableQuestions !== undefined && 
                <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Easy Question: {availableQuestions?.presentEasyCount}</p>
              }
              </div>
              <div>
                <Input
                  name="mediumCount"
                  label="Enter Medium Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="top"
                />
                  {availableQuestions!==null && availableQuestions !== undefined && 
                <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Medium Question: {availableQuestions?.presentMediumCount}</p>
                  }
              </div>
              <div>
                <Input
                  name="difficultCount"
                  label="Enter Difficult Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="left"
                />
                  {availableQuestions!==null && availableQuestions !== undefined && 
                <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Difficult Question: {availableQuestions?.presentDifficultCount}</p>
                  }
              </div>

              {/* Adding Images question count field below  */}

              <div>
                <Input
                  name="easyImageCount"
                  label="Enter Image Easy Questions"
                  register={register}
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="left"
                  required
                />
                  {availableQuestions!==null && availableQuestions !== undefined && 
                <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Easy Image: {availableQuestions?.presentEasyImageCount}</p>
                  }
              </div>
              <div>
                <Input
                  name="mediumImageCount"
                  label="Enter Image Medium Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="top"
                />
                  {availableQuestions!==null && availableQuestions !== undefined && 
                <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Medium Image: {availableQuestions?.presentMediumImageCount}</p>
                  }
              </div>
              <div>
                <Input
                  name="difficultImageCount"
                  label="Enter Image Difficult Questions"
                  register={register}
                  required
                  errorMessage={
                    "Please enter 0 for proceed or this field is required"
                  }
                  submitted={submited}
                  content="The count provided here will be used to serve users questions based on <br/>the specified levels and their respective counts. <br/> Note:<br/><ul><li>1. The Easy/Medium/Difficult text or image question counts mentioned <br/>in their respective columns must not exceed the total number of questions<br/> in the quiz. (The backend will throw an error if this condition is not met.)  </li><li>2. The count provided here will be verified against the question pool. If <br/>the pool does not have enough questions to match the specified count,<br/> the backend will throw an error. </li></ul>"
                  place="left"
                
                />
                  {availableQuestions!==null && availableQuestions !== undefined && 
               <p className={` text-[#046C4E] text-sm block w-full mb-0 font-bold`}>Pool Difficult Image: {availableQuestions?.presentDifficultImageCount}</p>
                  }
              </div>
            </div>
            <div className="flex gap-5 flex-wrap items-center">
              <button type="submit" className={style.buttonClass}>
                {AddQuiz.loading ? (
                  <SpinnerLoader size={"sm"} />
                ) : (
                  <span className=" md:inline-block">{"Create Quiz"}</span>
                )}
              </button>
              {specialChecked && specialQuizUsers ? (
                <>
                  <span className="text-sm font-bold text-green-700">
                    Minimum {specialQuizUsers} User(s) are required to join
                  </span>
                </>
              ) : (
                <>
                  {botChecked ? (
                    <span className="text-sm font-bold text-green-700">
                      Minimum 1 User is required to join
                    </span>
                  ) : minUser ? (
                    <span className="text-sm font-bold text-green-700">
                      Minimum {Math.ceil(minUser)} User(s) are required to join
                    </span>
                  ) : (
                    ""
                  )}
                </>
              )}
            </div>
          </form>
        </div>
      </div>

      {openQuizAddModal && (
        <WinnerBreakupModal
          heading={"Show Breackup of Quiz"}
          nameLabel={"New Quiz"}
          submitLabel={"Submit"}
          submitLabelsm={"Save Changes"}
          openModal={openQuizAddModal}
          setOpenModal={setOpenQuizAddModal}
          data={winningBreackup()}
        />
      )}

      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="add-question-tooltip"
        className=" font-semibold"
      />
    </>
  );
};

export default CreateQuiz;
