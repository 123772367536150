import {
  UPDATE_REWARD_REQUEST,
  UPDATE_REWARD_SUCCESS,
  UPDATE_REWARD_FAILURE,
} from "../../Utils/Constants";

export const updateRewardRequest  = (payload) => {
  return {
    type: UPDATE_REWARD_REQUEST,
    payload: payload,
  };
};

export const updateRewardSuccess = (data) => {
  return {
    type: UPDATE_REWARD_SUCCESS,
    payload: data,
  };
};

export const updateRewardFailure = (error) => {
  return {
    type: UPDATE_REWARD_FAILURE,
    payload: error,
  };
};
