import { call, put } from "redux-saga/effects";
import { postFormData, UpdateAPI } from "../components/Config/Apiinterface";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";
import { assignQuestionFailure, assignQuestionSuccess } from "../Actions";

export function* AssignQuestionSaga(action) {
  const path = "admin/quiz/player/assignQuestions";
  const token = action.payload.token;
  const body = action.payload.data;

  const ToastId = toast.loading("Updating ...")
    try {
      const responce = yield debounce(postFormData, path,body,token);
      yield put(assignQuestionSuccess(responce));
      if (responce.statusCode === 200) {
        toast.update(ToastId, {
          render: responce.message,
          type:'success',
          isLoading:false,
          autoClose:2000
        });
      } else {
        toast.update(ToastId, {
          render: responce.message,
          type:'info',
          autoClose:2000,
          isLoading:false
        });
      }
    } catch (err) {
      yield put(assignQuestionFailure(err));
    }
}
