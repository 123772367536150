import React, { useState } from "react";
import {
  MdOutlineKeyboardDoubleArrowDown,
  MdOutlineKeyboardDoubleArrowUp,
} from "react-icons/md";
import BarChartQN from "./BarcChart";
import { SiGoogleanalytics } from "react-icons/si";
import { formatNumber } from "../../Utils/numberConversion";
import androidIcon from "../../assets/images/android.png";
import iosIcon from "../../assets/images/apple.png";
import TooltipATM from "../ui-elements/TooltipATM";

const DashboardCard = ({
  title,
  value,
  percentage,
  dataPrefernce,
  barData,
  message,
  freeQuiz,
  cashQuiz,
  android,
  ios,
  placeTooltip,
  contentTooltip
}) => {
  const [showChart, setShowChart] = useState(false);

  return (
    <div className="mx-2 h-[10rem] border flex-grow mb-2 min-w-64 transition-all duration-150 ease-in-out shadow-md rounded-lg px-3 py-3 w-max overflow-hidden">
      {showChart && (
        <div className="md:w-[83%] w-full top-12 right-0 mt-1 z-50 h-screen absolute flex justify-center items-center backdrop-blur-[1.5px]">
          <div className="absolute bg-white flex md:w-[60%] w-[90%] h-fit justify-center items-center z-50 border-2 rounded-md shadow-md p-3">
            <BarChartQN
              title={title}
              onChange={() => setShowChart(false)}
              apiData={barData}
            />
          </div>
        </div>
      )}

      <div>
        {/* Title and Analytics Icon */}
        <div className="text-[1.05rem] flex justify-between text-gray-500 font-semibold">
          <div className="flex flex-row gap-1 justify-center align-middle text-center align-content-center">
          {title}
          <div className="m-auto">
            <TooltipATM
              dataTooltipId={"dashboard-tooltip"}
              dataTooltipPlace={placeTooltip}
              dataTooltipHtml={contentTooltip }
              className={"cursor-pointer"}
            />
            </div>
       </div>
          <SiGoogleanalytics
            className="text-black "
            // onClick={() => setShowChart(true)}
          />
        </div>

        <div className="flex justify-between items-center mt-2 ">
          {/* Total Value */}
          <div className="text-[2.1rem] font-bold">
            { formatNumber(value)}
          </div>

          {/* Free Quiz and Cash Quiz Labels */}
          <div className="flex gap-8">
            {freeQuiz !== null && freeQuiz !== undefined && (
              <div className="text-center mt-3">
                <div className="text-[0.8rem] font-semibold text-gray-500">
                  Free Quiz
                </div>
                <div className="text-black text-[1.5rem] font-bold">
                  {formatNumber(freeQuiz)}
                </div>
              </div>
            )}

            {cashQuiz !== null && cashQuiz !== undefined && (
              <div className="text-center mt-3">
                <div className="text-[0.8rem] font-semibold text-gray-500">
                  Cash Quiz
                </div>
                <div className="text-black text-[1.5rem] font-bold">
                  {formatNumber(cashQuiz)}
                </div>
              </div>
            )}
          </div>
          <div className="flex gap-8 ">
            {android !== null && android !== undefined && (
              <div className="text-center mt-3">
                <img
                  src={androidIcon}
                  alt="Android"
                  className="h-6d w-6 mx-auto"
                />
                <div className="text-black text-[1.5rem] font-bold">
                  {formatNumber(android)}
                </div>
              </div>
            )}
            {ios !== null && ios !== undefined && (
              <div className="text-center mt-3">
                <img src={iosIcon} alt="iOS" className="h-6 w-6 mx-auto" />
                <div className="text-black text-[1.5rem] font-bold">
                  {formatNumber(ios)}
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Percentage (if present) */}
        <div className="flex gap-2 mt-2">
          {percentage && percentage !== null ? (
            <div
              className={`flex items-center px-2 -mt-10 -ml-4 text-[0.8rem] ${
                percentage > 0 ? "text-green-600" : "text-red-600"
              } rounded-full`}
            >
              {percentage > -1 ? (
                <MdOutlineKeyboardDoubleArrowUp className="text-xl font-bold text-green-600" />
              ) : (
                <MdOutlineKeyboardDoubleArrowDown className="text-xl font-bold text-red-600" />
              )}
              <div className="my-1">
                {percentage > 0 ? percentage : percentage * -1}%
              </div>
            </div>
          ) : (
            <div className="h-0">{""}</div>
          )}
        </div>

        {/* Message */}
        <div className="text-[0.7rem] my-1 text-gray-500">{message}</div>
      </div>
    </div>
  );
};

export default DashboardCard;
