import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router";
import { Search } from "../Search";
import ReadMoreLess from "../ui-elements/ReadMoreLess";
import { MdDelete } from "react-icons/md";
import cashQuiz from "../../assets/images/cashQuiz.svg";
import freeQuiz from "../../assets/images/freeQuiz.svg";
import { FiUpload } from "react-icons/fi";
import { BiExport } from "react-icons/bi";
import { FaFileExport } from "react-icons/fa6";
import { TbServerOff } from "react-icons/tb";
import Loading from "../loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import TooltipWrapper from "../ui-elements/TooltipWrapper";

export default function Table(props) {
  const navigate = useNavigate();
  const {
    isLoading,
    searchQuizProp,
    itemsArray,
    setOpenDeleteModal,
    setDeleteData,
    searchByQuestionId,
    isSubmittingFilter,
    handleCheckboxChange,
    checkedBoxes,
    handleCheckboxId,
    setOpenDeleteQuestionModal,
    setDeleteQuestionData,
    setCheckedBoxes,
    searchValue,
    countOfQuestion,
    handleQuestionExport,
    handleDeleteQuestion,
    handleDelete,
    handleEdit,
  } = props;

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChangeAll = () => {
    setSelectAll(!selectAll);
    const allCheckboxIds = itemsArray?.map((item) => item.id);
    setCheckedBoxes(selectAll ? [] : allCheckboxIds);
  };

  const handleSelectAll = () => {
    handleCheckboxChangeAll();
  };

  if (isLoading) {
    return <Loading noGap={true} />;
  }

  return (
    <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] gap-2">
      <div className="md:hidden block text-left font-bold">
        Count: <span className=" font-normal ">{countOfQuestion}</span>
      </div>
      <div className="flex justify-between items-center flex-wrap gap-2">
        <div className="md:block text-left font-bold uppercase w-full sm:w-auto">
          Count: <span className="font-bold">{countOfQuestion}</span>
        </div>
        <div className="flex gap-2 flex-wrap">
          <Search
            label={`Category`}
            placeholder={"Enter Question Id"}
            name={"Search"}
            searchCallbackfn={searchByQuestionId}
            isSearching={searchQuizProp?.loading}
            searchValue={searchValue}
          />
          <TooltipWrapper
            id="add-question-tooltip"
            content="Used to add text-based or image-based questions."
          >
            <button
              disabled={!checkAccess("QUESTIONPOOL", "C")}
              onClick={() => {
                navigate("/addQuestion");
              }}
              className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                !checkAccess("QUESTIONPOOL", "C")
                  ? "accessDenied"
                  : "hover:cursor-pointer"
              }`}
            >
              Add Question
              <FaPlus />
            </button>
          </TooltipWrapper>

          <TooltipWrapper
            id="add-question-tooltip"
            content="Used to add text-based questions using an Excel sheet."
          >
            <button
              disabled={!checkAccess("QUESTIONPOOL", "C")}
              onClick={() => {
                navigate("/BulkUpload");
              }}
              className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                !checkAccess("QUESTIONPOOL", "C")
                  ? "accessDenied"
                  : "hover:cursor-pointer"
              }`}
            >
              Bulk Upload
              <FaPlus />
            </button>
          </TooltipWrapper>

          <TooltipWrapper
            id="add-question-tooltip"
            content="Used to export the currently displayed questions. The sheet will contain<br/> the same number of questions as shown in the count."
          >
            <button
              disabled={!checkAccess("QUESTIONPOOL", "R")}
              onClick={() => {
                handleQuestionExport();
              }}
              className={`bg-transparent hover:bg-[#4CAF50] text-[#4CAF50] font-semibold text-xs md:text-base hover:text-slate-700 md:py-1 px-1 md:px-3 border hover:border-transparent rounded flex items-center gap-2 whitespace-nowrap ${
                !checkAccess("QUESTIONPOOL", "R")
                  ? "accessDenied"
                  : "hover:cursor-pointer"
              }`}
            >
              Export
              <FaFileExport />
            </button>
          </TooltipWrapper>
        </div>
      </div>

      {itemsArray && itemsArray.length > 0 ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
          {isSubmittingFilter && (
            <div className="flex gap-2 m-2">
              <div>
                <button onClick={handleSelectAll}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleCheckboxChangeAll}
                  />
                </button>
              </div>
              <div>
                <button
                  disabled={!checkAccess("QUESTIONPOOL", "D")}
                  onClick={() => {
                    setDeleteQuestionData({
                      name: `Question with id ${checkedBoxes}`,
                      id: checkedBoxes,
                    });
                    setOpenDeleteQuestionModal(true);
                  }}
                >
                  <MdDelete
                    className={`text-2xl text-red-500 ${
                      !checkAccess("QUESTIONPOOL", "D")
                        ? "accessDenied"
                        : "hover:cursor-pointer"
                    }`}
                  />
                </button>
              </div>
              <div>
                <span>Delete Questions</span>
              </div>
            </div>
          )}

          {itemsArray.map((item, index) => (
            <div
              className="bg-white border-b p-4 sm:rounded-lg shadow-md mb-4"
              key={index}
            >
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4">
                {isSubmittingFilter && (
                  <input
                    type="checkbox"
                    className="form-checkbox w-5 h-5 text-blue-500"
                    onClick={() => handleCheckboxChange(item.id)}
                    checked={checkedBoxes?.includes(item.id)}
                  />
                )}

                <h5 className="text-lg font-semibold text-gray-800">
                  {`Question Id: #${item?.id}`}
                </h5>

                <div className="flex items-center justify-center">
                  <img
                    className="w-10 h-10 object-contain"
                    src={item.isFree ? freeQuiz : cashQuiz}
                    alt="Quiz Type"
                  />
                </div>

                <div className="flex self-center bg-green-500 text-white px-3 py-1 rounded-md sm:text-sm">
                  {item?.level}
                </div>
              </div>

              <div className="flex flex-col sm:flex-row mt-4 gap-4">
                {/* English Section */}
                <div className="w-full sm:w-1/2">
                  <div>
                    {item?.descriptionEn?.imageUrl && (
                      <img
                        className="w-full h-60 object-fit mb-4"
                        src={item?.descriptionEn?.imageUrl}
                        alt="Description Image"
                      />
                    )}
                    <h6 className="font-normal">
                      {" "}
                      <span className=" font-semibold">Q.</span>{" "}
                      {item?.descriptionEn?.text}
                    </h6>

                    <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                      {item.optionsEn?.A?.imageUrl ||
                      item.optionsEn?.B?.imageUrl ||
                      item.optionsEn?.C?.imageUrl ||
                      item.optionsEn?.D?.imageUrl ? (
                        <div>
                          {/* A and B */}
                          <div className="flex gap-4 mb-4">
                            {["A", "B"].map((option) => (
                              <div key={option} className="w-1/2">
                                <h6 className="mt-2 text-sm font-medium text-gray-800">
                                  <span>{`${option}. `}</span>
                                  {item.optionsEn?.[option]?.imageUrl && (
                                    <img
                                      className="inline-block w-40 h-20 mx-2 object-fit"
                                      src={item.optionsEn?.[option]?.imageUrl}
                                      alt={`Option ${option}`}
                                    />
                                  )}
                                  {item.optionsEn?.[option]?.text}
                                </h6>
                              </div>
                            ))}
                          </div>
                          {/* C and D */}
                          <div className="flex gap-4">
                            {["C", "D"].map((option) => (
                              <div key={option} className="w-1/2">
                                <h6 className="mt-2 text-sm font-medium text-gray-800">
                                  <span>{`${option}. `}</span>
                                  {item.optionsEn?.[option]?.imageUrl && (
                                    <img
                                      className="inline-block w-40 h-20 mx-2 object-fit"
                                      src={item.optionsEn?.[option]?.imageUrl}
                                      alt={`Option ${option}`}
                                    />
                                  )}
                                  {item.optionsEn?.[option]?.text}
                                </h6>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ["A", "B", "C", "D"].map((option) => (
                          <div key={option} className="w-full ">
                            <h6 className=" text-sm font-semibold text-gray-800">
                              <span>{`${option}. `}</span>
                              {item.optionsEn?.[option]?.text}
                            </h6>
                          </div>
                        ))
                      )}
                    </div>
                    <h6 className="mt-2 font-semibold text-black text-sm">
                      {"Ans:- " +
                        item?.answerEn?.option +
                        ") " +
                        item?.answerEn?.answer}
                    </h6>
                  </div>
                </div>

                {/* Add Hindi Section similarly */}
                <div className="w-full sm:w-1/2">
                  <div>
                    {item?.descriptionHi?.imageUrl && (
                      <img
                        className="w-full h-60 object-fit mb-4"
                        src={item?.descriptionEn?.imageUrl}
                        alt="Description Image"
                      />
                    )}
                    <h6 className="font-normal">
                      {" "}
                      <span className=" font-semibold pr-1">Q.</span>
                      {item?.descriptionHi?.text}
                    </h6>

                    <div className="h-1/2 flex flex-col justify-start gap-4 mt-4">
                      {item.optionsHi?.A?.imageUrl ||
                      item.optionsHi?.B?.imageUrl ||
                      item.optionsHi?.C?.imageUrl ||
                      item.optionsHi?.D?.imageUrl ? (
                        <div>
                          {/* A and B */}
                          <div className="flex gap-4 mb-4">
                            {["A", "B"].map((option) => (
                              <div key={option} className="w-1/2">
                                <h6 className="mt-2 text-sm font-medium text-gray-800">
                                  <span>{`${option}. `}</span>
                                  {item.optionsHi?.[option]?.imageUrl && (
                                    <img
                                      className="inline-block w-40 h-20 mx-2 object-fit"
                                      src={item.optionsHi?.[option]?.imageUrl}
                                      alt={`Option ${option}`}
                                    />
                                  )}
                                  {item.optionsHi?.[option]?.text}
                                </h6>
                              </div>
                            ))}
                          </div>
                          {/* C and D */}
                          <div className="flex gap-4">
                            {["C", "D"].map((option) => (
                              <div key={option} className="w-1/2">
                                <h6 className="mt-2 text-sm font-medium text-gray-800">
                                  <span>{`${option}. `}</span>
                                  {item.optionsHi?.[option]?.imageUrl && (
                                    <img
                                      className="inline-block w-40 h-20 mx-2 object-fit"
                                      src={item.optionsHi?.[option]?.imageUrl}
                                      alt={`Option ${option}`}
                                    />
                                  )}
                                  {item.optionsHi?.[option]?.text}
                                </h6>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : (
                        ["A", "B", "C", "D"].map((option) => (
                          <div key={option} className="w-full">
                            <h6 className="text-sm font-semibold text-gray-800">
                              <span>{`${option}. `}</span>
                              {item.optionsHi?.[option]?.text}
                            </h6>
                          </div>
                        ))
                      )}
                    </div>
                    <h6 className="mt-2 text-black text-sm font-semibold">
                      {"Ans:- " +
                        item?.answerHi?.option +
                        ") " +
                        item?.answerHi?.answer}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                {/* Edit Button */}
                <button
                  onClick={() => handleEdit(item)}
                  //  navigate("/updateQuestion",{state:question});
                  className="bg-green-500 text-white px-3 py-1 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(item.id)}
                  className="bg-red-500 text-white px-3 py-1 rounded ml-2"
                >
                  Delete
                </button>

                {/* Category Display */}
                <div className="px-3 py-2 bg-blue-500 text-white rounded-md">
                  <span>{item.category?.nameEng}</span>
                </div>

                {/* Topic Display */}
                <div className="px-3 py-2 bg-yellow-500 text-white rounded-md">
                  <span>{item.topic?.nameEng}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <TbServerOff className="text-5xl text-gray-300" />
          <span className="text-gray-500 text-lg">No Data Available</span>
        </div>
      )}
    </div>
  );
}
