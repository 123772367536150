import React, { useEffect, useState } from "react";
import { Search } from "../Search";
import { Pagination } from "../Pagination";
import madelSvg from "../../assets/svg/medal 1.svg";
import avtar from "../../assets/images/download.png";
import { MdRefresh } from "react-icons/md";
import {
  getLeaderbordUserQuestionRequest,
  getLeaderbordUserQuestionReset,
  removeAssignedQuestionRequest,
} from "../../Actions";
import { useDispatch } from "react-redux";
import { TbServerOff } from "react-icons/tb";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { DeleteModal } from "../modal/DeleteModal";

const Tabs = (props) => {
  const [activeTab, setActiveTab] = useState(1);
  const dispatch = useDispatch();
  const [removeModalIsOpen, setremoveModalIsOpen] = useState(false);

  const {
    itemsArray,
    getSearchLeaderboard,
    searchLeaderboard,
    // selectQuestion,
    totalPages,
    currentPage,
    setCurrentPage,
    isLoading,
    searchValue,
    isLive = { isLive },
    getData,
    // handleLeaderboardUserQuestion,
    navigate,
    id,
    token,
    setSearchValue,
    userQuestions,
    quizStatus,
    categoryId,
    categoryNameEng,
    topicId,
    topicNameEng,
  } = props;

  let [isStatusUpcoming, setIsStatusUpcoming] = useState(
    () => quizStatus == "Upcoming"
  );
  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (quizStatus == "Upcoming") {
      setIsStatusUpcoming(true);
    } else {
      setIsStatusUpcoming(false);
    }
  }, [quizStatus]);

  const handleLeaderboardUserQuestion = (userId) => {
    const body = {
      token,
      id: id,
      userId: userId,
    };
    // if (userId) {
    dispatch(getLeaderbordUserQuestionRequest(body));
    // }
  };

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  const tableHead = [
    "Participant Name",
    "Points",
    "Rank",
    "Cash Won",
    "Questions",
  ];

  const tableHeadUpcoming = [
    "Participant Name",
    "Number",
    "Manage Questions",
    "Questions",
    "Mode",
  ];

  const handleViewQuestions = (userId) => {
    handleLeaderboardUserQuestion(userId); // Fetch questions for the selected user
    navigate(`/UserQuestions`);
  };

  useEffect(() => {
    dispatch(getLeaderbordUserQuestionReset());
  }, []);

  const calculateTime = (data) => {
    if (data < 1) {
      return "-";
    }
    if (data === 1) {
      return "1 Sec";
    }
    if (data > 1 && data < 60) {
      return `${data} Secs`;
    }
    const minutes = Math.floor(data / 60);
    const seconds = data % 60;
    if (seconds === 0) {
      return `${minutes} Mins`;
    }
    return `${minutes}:${seconds} Mins`;
  };

  const handleRemoveClick = (userData) => {
    setremoveModalIsOpen(true);
    setUserId(userData?.id);
  };

  const handleRemoveClickYes = () => {
    const body = {
      token: token,
      data: { userId: userId, quizId: id },
    };
    dispatch(removeAssignedQuestionRequest(body));
  };

  const handleViewAssignedQuestions = (userData) => {
    const dataToPass = {
      userId: userData,
      quizId: id,
      categoryId,
      topicId,
      categoryNameEng,
      topicNameEng
    };

    navigate("/viewQuestions", { state:  dataToPass });
  };

  return (
    <div className="w-full">
      <div>
        <div className="flex">
          <div className="border border-gray-400 p-2 rounded-lg w-[250px]">
            <button
              className={`w-full p-2.5 rounded-lg ${
                activeTab === 1 ? "text-white bg-[#182241]" : "text-[#182241]"
              }`}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex text-center gap-2">
                <div>
                  <img src={madelSvg} />
                </div>
                <div>
                  {isStatusUpcoming ? <div>JOINERS</div> : "LEADERBOARD"}{" "}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>

      {activeTab === 1 && (
        <>
          <div className="bg-white shadow-md rounded-lg h-[100%] overflow-hidden mt-4">
            <button
              onClick={() => {
                getData();
                setSearchValue("");
              }}
              className="float-right mr-4 "
            >
              <MdRefresh className="w-6 h-6 mt-2" />
            </button>
            <div className="">
              <div className="lg:flex w-full lg:justify-between items-center">
                <div className="mb-4 lg:mb-0 mt-2 pl-2">
                  <p className="text-xl uppercase font-bold">
                    {" "}
                    {isStatusUpcoming ? "Joiners List" : "LEADERBOARD"}{" "}
                  </p>
                </div>
                <div className="w-full lg:w-1/3 pr-2">
                  <Search
                    label={`Category`}
                    placeholder={"Enter Participants Name"}
                    name={"Search"}
                    searchCallbackfn={getSearchLeaderboard}
                    isSearching={searchLeaderboard?.loading}
                    searchValue={searchValue}
                  />
                </div>
              </div>
              {itemsArray?.length ? (
                <table className="w-full mt-2 text-sm text-left rtl:text-right text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    {isStatusUpcoming ? (
                      <>
                        <tr>
                          {tableHeadUpcoming?.map((item, i) => (
                            <th scope="col" className="px-6 py-3" key={i}>
                              {item}
                            </th>
                          ))}
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr>
                          {tableHead?.map((item, i) => (
                            <th scope="col" className="px-6 py-3" key={i}>
                              {item}
                            </th>
                          ))}
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody>
                    {isStatusUpcoming ? (
                      <>
                        {itemsArray?.map((item, indx) => {
                          return (
                            <tr
                              className="bg-white border-b hover:bg-gray-50"
                              key={indx}
                            >
                              <th
                                scope="row"
                                className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={
                                    item.profilePic ? item.profilePic : avtar
                                  }
                                  alt="Jese image"
                                />
                                <div className="ps-3">
                                  <div
                                    className={`text-base font-semibold hover:text-blue-800 ${
                                      !checkAccess("", "")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      if (checkAccess("PLAYER", "R")) {
                                        navigate(
                                          {
                                            pathname: "/viewPlayers",
                                          },
                                          {
                                            state: { ...item, id: item.userId },
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              </th>
                              <td className="px-3 py-4 pl-10">
                                <div>{item?.mobile}</div>
                              </td>

                              <td>
                                <div className="flex pl-10 items-center gap-4">
                                  <button
                                    disabled={!checkAccess("QUESTIONPOOL", "R")}
                                    className={`text-green-400 font-semibold ${
                                      !checkAccess("QUESTIONPOOL", "R")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      // handleViewQuestions(item.userId);
                                      let itemObj = {
                                        item,
                                        categoryId,
                                        categoryNameEng,
                                        topicId,
                                        topicNameEng,
                                        userId: item?.id,
                                        quizId: id,
                                      };
                                      navigate("/manageQuestion", {
                                        state: itemObj,
                                      });
                                    }}
                                  >
                                    Edit
                                  </button>
                                  <button
                                    disabled={!checkAccess("QUESTIONPOOL", "R")}
                                    className={`text-red-400 font-semibold ${
                                      !checkAccess("QUESTIONPOOL", "R")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      handleRemoveClick(item);
                                    }}
                                  >
                                    Remove
                                  </button>
                                </div>
                              </td>
                              <td className="pl-3">
                                {item?.isQuestionAssigned ? (
                                  <button
                                    disabled={!checkAccess("QUESTIONPOOL", "R")}
                                    className={`text-black hover:opacity-75 ${
                                      !checkAccess("QUESTIONPOOL", "R")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      handleViewAssignedQuestions(item.id);
                                    }}
                                  >
                                    View Questions
                                  </button>
                                ) : (
                                  <button
                                    disabled={!checkAccess("QUESTIONPOOL", "R")}
                                    className={` text-gray-400 ${
                                      !checkAccess("QUESTIONPOOL", "R")
                                        ? "accessDenied"
                                        : ""
                                    } cursor-default`}
                                   
                                  >
                                    View Questions
                                  </button>
                                )}
                              </td>
                              <td className=" pl-3 font-semibold uppercase">
                                {item?.isQuestionAssigned ? (
                                  <span className=" cursor-default px-2 py-1 border-2 border-green-400 rounded-md">
                                    Manual
                                  </span>
                                ) : (
                                  <span className=" cursor-default px-2 py-1 border-2  border-red-400 rounded-md">
                                    Random
                                  </span>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </>
                    ) : (
                      itemsArray?.map((item, index) => (
                        <>
                          {item.rank === 0 ? (
                            <tr
                              className="bg-white border-b hover:bg-gray-50"
                              key={index}
                            >
                              <th
                                scope="row"
                                className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={
                                    item.profilePic ? item.profilePic : avtar
                                  }
                                  alt="Jese image"
                                />
                                <div className="ps-3">
                                  <div
                                    className={`text-base font-semibold hover:text-blue-800 ${
                                      !checkAccess("", "")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      if (checkAccess("PLAYER", "R")) {
                                        navigate(
                                          {
                                            pathname: "/viewPlayers",
                                          },
                                          {
                                            state: { ...item, id: item.userId },
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    {item.userName}
                                  </div>
                                  <div className="font-normal text-gray-500">
                                    {/* bonnie@flowbite.com */}
                                  </div>
                                </div>
                              </th>
                              <td className="px-6 py-4 pl-10">0</td>
                              <td className="px-6 py-4 pl-10">--</td>
                              <td className="px-6 py-4 pl-10">Rs. 0 </td>
                              <td>
                                <button
                                  className={`text-blue-500 ${
                                    !checkAccess("QUESTIONPOOL", "R")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  disabled={!checkAccess("QUESTIONPOOL", "R")}
                                  onClick={() => {
                                    handleViewQuestions(item.userId);
                                  }}
                                >
                                  View Questions
                                </button>
                              </td>
                            </tr>
                          ) : (
                            <tr
                              className="bg-white border-b hover:bg-gray-50"
                              key={index}
                            >
                              <th
                                scope="row"
                                className="flex items-center px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                <img
                                  className="w-10 h-10 rounded-full"
                                  src={
                                    item.profilePic ? item.profilePic : avtar
                                  }
                                  alt="Jese image"
                                />
                                <div className="ps-3">
                                  <div
                                    className={`text-base font-semibold hover:text-blue-800 ${
                                      !checkAccess("", "")
                                        ? "accessDenied"
                                        : "hover:cursor-pointer"
                                    }`}
                                    onClick={() => {
                                      if (checkAccess("PLAYER", "R")) {
                                        navigate(
                                          {
                                            pathname: "/viewPlayers",
                                          },
                                          {
                                            state: { ...item, id: item.userId },
                                          }
                                        );
                                      }
                                    }}
                                  >
                                    {item.userName}
                                  </div>
                                  <div className="font-normal text-gray-500">
                                    {/* bonnie@flowbite.com */}
                                  </div>
                                </div>
                              </th>
                              <td className="px-6 py-4 pl-10">
                                <div>Pts. {item.points}</div>
                                <div className="text-sm">
                                  {calculateTime(item?.completedTime)}
                                </div>
                              </td>
                              <td className="px-6 py-4 pl-10">{item.rank}</td>
                              <td className="px-6 py-4 pl-10">
                                Rs. {item.cashWon}
                              </td>
                              <td>
                                <button
                                  disabled={!checkAccess("QUESTIONPOOL", "R")}
                                  className={`text-blue-500 ${
                                    !checkAccess("QUESTIONPOOL", "R")
                                      ? "accessDenied"
                                      : "hover:cursor-pointer"
                                  }`}
                                  onClick={() => {
                                    handleViewQuestions(item.userId);
                                  }}
                                >
                                  View Questions
                                </button>
                              </td>
                            </tr>
                          )}
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center m-8 flex flex-col justify-center items-center">
                  <TbServerOff className="text-[2rem] font-bold  m-2" />
                  Data Not Found
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-between h-auto">
            {totalPages > 1 && !searchValue && (
              <div className="flex justify-end pr-5 pt-4">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  isLoading={isLoading}
                />
              </div>
            )}
          </div>
          {/* {openRandomlyScreen && <RandomlyData item={userQuestionsArray} />} */}
          {removeModalIsOpen && (
            <DeleteModal
              data={
                "Are you sure you want to remove manual assigned questions ?"
              }
              open="textField"
              handleDelete={() => {
                handleRemoveClickYes();
              }}
              setOpenDeleteModal={setremoveModalIsOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Tabs;
