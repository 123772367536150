import {
  GET_REWARD_DATA_REQUEST,
  GET_REWARD_DATA_SUCCESS,
  GET_REWARD_DATA_FAILURE,
} from "../../Utils/Constants";

export const getRewardDataRequest = (payload) => {
  return {
    type: GET_REWARD_DATA_REQUEST,
    payload: payload,
  };
};

export const getRewardDataSuccess = (data) => {
  return {
    type: GET_REWARD_DATA_SUCCESS,
    payload: data,
  };
};

export const getRewardDataFailure = (error) => {
  return {
    type: GET_REWARD_DATA_FAILURE,
    payload: error,
  };
};
