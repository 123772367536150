import {
  ASSIGN_QUESTION_REQUEST,
  ASSIGN_QUESTION_SUCCESS,
  ASSIGN_QUESTION_FAILURE,
  ASSIGN_QUESTION_RESET,

  GET_ASSIGNED_QUESTIONS_REQUEST,
  GET_ASSIGNED_QUESTIONS_SUCCESS,
  GET_ASSIGNED_QUESTIONS_FAILURE,
  
  REMOVE_ASSIGNED_QUESTIONS_REQUEST,
  REMOVE_ASSIGNED_QUESTIONS_SUCCESS,
} from "../Utils/Constants";
//  all belows are submitting assign question to user
export const assignQuestionRequest = (payload) => {
  return {
    type: ASSIGN_QUESTION_REQUEST,
    payload: payload,
  };
};
export const assignQuestionSuccess = (data) => {
  return {
    type: ASSIGN_QUESTION_SUCCESS,
    payload: data,
  };
};
export const assignQuestionFailure = (error) => {
  return {
    type: ASSIGN_QUESTION_FAILURE,
    payload: error,
  };
};
export const assignQuestionReset = () => {
  return {
    type: ASSIGN_QUESTION_RESET,
  };
};
//  below are get assigned questions only to user
export const getAssinedQuestionsRequest = (payload) => {
  return {
    type: GET_ASSIGNED_QUESTIONS_REQUEST,
    payload: payload,
  };
};
export const getAssinedQuestionSuccess = (payload) => {
  return {
    type: GET_ASSIGNED_QUESTIONS_SUCCESS,
    payload: payload,
  };
};
export const getAssinedQuestionFailure = (payload) => {
  return {
    type: GET_ASSIGNED_QUESTIONS_FAILURE,
    payload: payload,
  };
};
// remove assigned questions
export const removeAssignedQuestionRequest = (payload) => {
  return {
    type: REMOVE_ASSIGNED_QUESTIONS_REQUEST,
    payload: payload,
  };
};
export const removeAssignedQuestionSuccess = (data) => {
  return {
    type: REMOVE_ASSIGNED_QUESTIONS_SUCCESS,
    payload: data,
  };
};
export const removeAssignQuestionFailure = (error) => {
  return {
    type: removeAssignQuestionFailure,
    payload: error,
  };
};
