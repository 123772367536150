import {
  UPDATE_BANNER_REQUEST,
  UPDATE_BANNER_SUCCESS,
  UPDATE_BANNER_FAILURE,
} from "../../Utils/Constants";

export const updateBannerRequest  = (payload) => {
  return {
    type: UPDATE_BANNER_REQUEST,
    payload: payload,
  };
};

export const updateBannerSuccess = (data) => {
  return {
    type: UPDATE_BANNER_SUCCESS,
    payload: data,
  };
};

export const updateBannerFailure = (error) => {
  return {
    type: UPDATE_BANNER_FAILURE,
    payload: error,
  };
};
