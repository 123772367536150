import { call, put } from "redux-saga/effects";
import { postFormData } from "../../components/Config/Apiinterface";
import {
  updateQuizSpotPercentageFailure,
  updateQuizSpotPercentageSuccess,
} from "../../Actions";
import { toast } from "react-toastify";
import debounce from "../../Utils/useDebounce";

export function* updateQuizSpotPercentageSaga(action) {
  const path = "admin/quiz/special/requiredSpotsPercent";
  const token = action.payload.token;
  const body = action.payload.data;

  const ToastId = toast.loading("Updating ..")
  try {
    const responce = yield debounce(postFormData, path, body, token);
    yield put(updateQuizSpotPercentageSuccess(responce));
    if (responce.statusCode === 200) {
      toast.update(ToastId, {
        render: responce.message,
        type:"success",
        autoClose:2000,
        isLoading:false
      });
    } else {
      toast.update(ToastId, {
        render: responce.message,
        type:"error",
        autoClose:2000,
        isLoading:false
      });
    }
  } catch (err) {
    yield put(updateQuizSpotPercentageFailure(err));
  }
}
