import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import {
  getCommissionRateRequest,
  updateCommissionRateRequest,
} from "../../Actions";
import { MdModeEditOutline } from "react-icons/md";
import { PasswordModal } from "../../components/modal/passwordModal";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { useNavigate } from "react-router";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";

const CommissionRates = ({ commissionRate, updateRate }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [openUpdateCommisionModal, setOpenUpdateCommissionModal] =
    useState(false);
  const [rateArray, setRateArray] = useState({});
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const tableHeaders = ["Id", "Commission Rate", "Update Date", "Edit"];

  const onSubmit = (item) => {
    const body = {
      data: {
        rateInPercent: item,
      },
      token: token,
    };
    dispatch(updateCommissionRateRequest(body));
  };

  const getCommissionRate = () => {
    const body = {
      token: token,
    };
    dispatch(getCommissionRateRequest(body));
  };

  useEffect(() => {
    getCommissionRate();
  }, [updateRate]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  useEffect(() => {
    if (
      commissionRate?.commissionRate?.statusCode === 403 ||
      updateRate?.updateRate?.statusCode === 403
    ) {
      window.location.href = "/";
    }
    if (commissionRate?.loading) {
      setIsLoading(true);
    }
    setTimeout(() => {
      if (commissionRate?.loading === false) {
        setFormOpen(false);
      }
    }, [1000]);

    if (commissionRate?.error) {
      setIsLoading(false);
    }

    if (commissionRate?.commissionRate.data) {
      setRateArray(commissionRate?.commissionRate.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [commissionRate, updateRate]);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Commission Rate
            </div>
            <TooltipATM
              dataTooltipId={"comission-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={
                "This specified value in percentage is calculated at the time of adding the quiz and is deducted from the quiz's winning amount, <br/> Essentially, this is the amount the company earns from each quiz. This commission rate does not apply to quizzes <br/> where the winning amount can be set higher than the calculated value, such as Special Quizzes."
              }
              className={" cursor-pointer"}
            />
          </div>
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b " key={rateArray.id}>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.id}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.rateInPercent} %</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.updateDate}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {" "}
                          <div className="flex gap-x-3 justify-center">
                            <div
                              className={`hover:bg-gray-100 p-2 rounded-full ${
                                !checkAccess("COMMISSIONRATE", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("COMMISSIONRATE", "U")) {
                                  setOpenUpdateCommissionModal(true);
                                }
                              }}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {openUpdateCommisionModal && (
        <PasswordModal
          heading={"Update Commission Rate"}
          nameLabel={"Enter Rate"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={openUpdateCommisionModal}
          setOpenModal={setOpenUpdateCommissionModal}
          flage={"commitionRate"}
          prevValue={`${rateArray.rateInPercent}%`}
        />
      )}
      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="comission-tooltip"
        className=" font-semibold"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    commissionRate: state?.commissionRatesReducer,
    updateRate: state?.updateCommissionRatesReducer,
  };
};

export default connect(mapStateToProps)(CommissionRates);
