import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipWrapper = ({
  id,
  content,
  children,
  position = "top",
  className = "",
}) => {
  return (
    <div className="relative flex items-center">
      <div
        data-tooltip-id={id}
        data-tooltip-html={content}
        className={className}
      >
        {children}
      </div>
      <Tooltip
        id={id}
        place={position}
        data-tooltip-variant="dark"
        data-tooltip-delay-show={"50"}
      />
    </div>
  );
};

export default TooltipWrapper;
