// src/redux/sagas.js

import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { updatePlayerFailure, updatePlayerSuccess } from "../../../Actions/User/player_all/playerEditAction";
import { putRequestAPI } from "../../../components/Config/Apiinterface";

export function* updatePlayerSaga(action) {
  const path = "admin/player/update";

  const token = action.payload.token;
  const body = action.payload;

  const toastId = toast.loading("Updating");

  try {
    const response = yield call(putRequestAPI, token, body, path);
    yield put(updatePlayerSuccess(response));
    if (response.statusCode === 200 || response.statusCode === 201) {
      toast.update(toastId,
        {
          render:response?.message,
          type:"success",
          autoClose:2000,
          isLoading:false,
        }
      );
    } else {
      toast.update(toastId, {
        render: response?.message,
        autoClose:2000,
        type:"success",
        isLoading:false
      });
    }
  } catch (error) {
    alert("Something went wrong");
    yield put(updatePlayerFailure(error));
  }
}

export default updatePlayerSaga;
