import React, { useEffect, useState } from "react";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineDashboard } from "react-icons/ai";
import { QuizHeaderLogo } from "../assets/svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "../Actions/LogInAction";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { FaRobot, FaServer } from "react-icons/fa";
import { GiVerticalBanner } from "react-icons/gi";
import { RiFolderSettingsLine } from "react-icons/ri";

import {
  MdLogout,
  MdOutlineQuiz,
  MdClose,
  MdOutlineSupervisorAccount,
  MdNotificationsActive,
  MdQuestionMark,
  MdManageAccounts,
  MdPreview,
} from "react-icons/md";
import { IoLogoAppleAppstore } from "react-icons/io5";
import { BsPatchQuestion } from "react-icons/bs";
import { PiUsersThreeFill } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { sendOtpLoginReset, verifyOtpLoginReset } from "../Actions";
import { checkAccess } from "../Utils/checkAccess";
import { decrypt } from "../Utils/RoleBasedRoute";
import { BiLineChart } from "react-icons/bi";
 
export const Sidebar = ({ showSidebar, setShowSidebar }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const accessArray = decrypt("PRIVILEGES");

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClose = () => {
    if (window.innerWidth < 768) setShowSidebar((prev) => !prev);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    dispatch(sendOtpLoginReset());
    dispatch(verifyOtpLoginReset());
    sessionStorage.clear("myKey");
    localStorage.clear("myKey");
    navigate("/login");
  };

  return (
    <div
      className={`select-none fixed z-10 w-52 md:w-60 bg-gradient-to-b from-[#ffff] from-10% via-[#4CAF50] to-[#4CAF50] h-[100vh] border-r-[1px] border-[#f0f0f0] shadow-md sidebar slide-right ${
        showSidebar ? "block" : "hidden"
      }`}
    >
      <div className="h-full flex flex-col">
        <div className="pl-4 md:pl-6 relative">
          <Link to="/">
            <span className="hover:cursor-pointer">
              <QuizHeaderLogo />
            </span>
          </Link>
          <div
            className="absolute top-3 right-2 bg-gray-200 text-lg rounded-full p-1 hover:bg-gray-300 transition block md:hidden"
            onClick={() => setShowSidebar(false)}
          >
            <MdClose />
          </div>
        </div>
        <div className="overflow-y-auto">
          <div className="flex flex-col drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.2)]">
            {sidebarcontent.map((item, index) => {
              const hasAccess = checkAccess(item.moduleName, accessArray);
              const linkClasses = `pl-4 md:pl-8 py-3 flex items-center gap-2 transition hover:bg-[#a9e7c2] text-slate-700 relative ${
                hasAccess ? "" : "cursor-not-allowed opacity-90"
              }`;

              const iconClasses = `${
                hasAccess ? "text-black" : "text-gray-500"
              } font-bold text-xl`;

              const textClasses = `${
                hasAccess ? "text-black" : "text-gray-500"
              } text-sm font-semibold`;

              return hasAccess ? (
                <NavLink to={item.path} key={item.name} onClick={handleClose}>
                  <div className={linkClasses}>
                    <item.icon className={iconClasses} />
                    <div className={textClasses}>{item.name}</div>
                    <div className="sidebar longbar absolute bg-[#4e5b75] top-0 right-0 bottom-0 w-1"></div>
                  </div>
                </NavLink>
              ) : (
                <div className={linkClasses} key={index}>
                  <item.icon className={iconClasses} />
                  <div className={textClasses}>{item.name}</div>
                  <div className="sidebar longbar absolute bg-[#4e5b75] top-0 right-0 bottom-0 w-full"></div>
                </div>
              );
            })}
          </div>

          <div className="">
            <button
              type="button"
              aria-controls="dropdown-example"
              onClick={toggleDropdown}
              className="pl-4 md:pl-8 py-3 w-full flex items-center gap-2 transition hover:bg-[#a9e7c2] text-slate-700 relative"
            >
              <IoSettings className="font-bold text-black text-xl" />
              <div className="text-sm text-black font-semibold">Settings</div>
              <svg
                className="w-3 h-3 ml-10"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            <div
              id="dropdown-example"
              className={`py-2  ${
                isDropdownOpen ? "" : "hidden"
              } flex flex-col overflow-y-auto h-fit drop-shadow-[0_1.2px_1.2px_rgba(255,255,255,0.2)]`}
            >
              {dropdownContent.map((item, index) => {
                const hasAccess = checkAccess(item.moduleName, accessArray);
                const linkClasses = `pl-4 md:pl-12 py-3 flex items-center gap-2 transition hover:bg-[#a9e7c2] text-slate-700 relative ${
                  hasAccess ? "" : "cursor-not-allowed opacity-90"
                }`;
                const iconClasses = `${
                  hasAccess ? "text-black" : "text-gray-500"
                } font-bold text-xl`;
                const textClasses = `${
                  hasAccess ? "text-black" : "text-gray-500"
                } text-sm font-semibold`;

                return hasAccess ? (
                  <NavLink to={item.path} key={item.name} onClick={handleClose}>
                    <div className={linkClasses}>
                      <item.icon className={iconClasses} />
                      <div className={textClasses}>{item.name}</div>
                      <div className="sidebar longbar absolute bg-[#4e5b75] top-0 right-0 bottom-0 w-1"></div>
                    </div>
                  </NavLink>
                ) : (
                  <div className={linkClasses} key={index}>
                    <item.icon className={iconClasses} />
                    <div className={textClasses}>{item.name}</div>
                    <div className="sidebar longbar absolute bg-[#4e5b75] top-0 right-0 bottom-0 w-full"></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="mt-auto">
          <div
            className="text-gray-100 pl-4 md:pl-8 py-3 flex items-center gap-2 transition hover:bg-[#e7f7ff] hover:cursor-pointer hover:text-gray-900"
            onClick={handleLogout}
          >
            <MdLogout className="font-bold text-xl" />
            <div className="text-sm font-semibold">Logout</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const sidebarcontent = [
  {
    name: "Dashboard",
    icon: AiOutlineDashboard,
    path: "/",
    moduleName: "DASHBOARD",
  },
  {
    name: "Category",
    icon: RxDashboard,
    path: "/categories",
    moduleName: "CATEGORY",
  },
  {
    name: "Quizzes",
    icon: MdOutlineQuiz,
    path: "/quizzes",
    moduleName: "QUIZ",
  },
  {
    name: "Questions Pool",
    icon: BsPatchQuestion,
    path: "/questionPool",
    moduleName: "QUESTIONPOOL",
  },
  {
    name: "Players",
    icon: MdOutlineSupervisorAccount,
    path: "/players",
    moduleName: "PLAYER",
  },
  {
    name: "Customer Support",
    icon: PiUsersThreeFill,
    path: "/customerSupport",
    moduleName: "CUSTOMERSUPPORT",
  },
  {
    name: "Notifications",
    icon: MdNotificationsActive,
    path: "/notification",
    moduleName: "NOTIFICATION",
  },
  {
    name: "Transactions",
    icon: FaMoneyBillTrendUp,
    path: "/transaction",
    moduleName: "TRANSACTION",
  },
];

const dropdownContent = [
  {
    name: "Manage Access",
    icon: MdManageAccounts,
    path: "/manageAccess",
    moduleName: "MANAGEACCESS",
  },
  {
    name: "Commission Rates",
    icon: RiMoneyDollarCircleFill,
    path: "/commissionRate",
    moduleName: "COMMISSIONRATE",
  },
  {
    name: "Quiz Spots Percent",
    icon: BiLineChart,
    path: "/quizSpotsPercent",
    moduleName: "QUIZSPOTPERCENT",
  },
  {
    name: "Bots Probability",
    icon: FaRobot,
    path: "/quizBotProbability",
    moduleName: "QUIZBOTPROBABILITY",
  },
  {
    name: "Review Time",
    icon: MdPreview,
    path: "/reviewTime",
    moduleName: "REVIEWTIME",
  },
  {
    name: "Version Control",
    icon: IoLogoAppleAppstore,
    path: "/versionControl",
    moduleName: "VERSIONCONTROL",
  },
  {
    name: "Server Info",
    icon: FaServer,
    path: "/serverInfo",
    moduleName: "SERVERINFO",
  },
  {
    name: "Banner Setting",
    icon: GiVerticalBanner,
    path: "/banner",
    moduleName: "BANNER",
  },
  {
    name: "Reward Management",
    icon: RiFolderSettingsLine,
    path: "/reward",
    moduleName: "REWARD",
  },
];
