import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";

function TooltipATM({
  dataTooltipId,
  dataTooltipPlace,
  dataTooltipContent,
  dataTooltipHtml,
  className,
}) {
  return (
    <div className="">
      {dataTooltipContent ? (
        <span 
          data-tooltip-id={dataTooltipId}
          data-tooltip-place={dataTooltipPlace}
          data-tooltip-variant="dark"
          data-tooltip-delay-show={"50"}
          data-tooltip-content={dataTooltipContent}
          className={className}
        >
          <IoMdInformationCircleOutline />
        </span>
      ) : (
        <span
          data-tooltip-id={dataTooltipId}
          data-tooltip-place={dataTooltipPlace}
          data-tooltip-variant="dark"
          data-tooltip-delay-show={"50"}
          data-tooltip-html={dataTooltipHtml}
          className={className}
        >
          <IoMdInformationCircleOutline />
        </span>
      )}
    </div>
  );
}

export default TooltipATM;
