// src/redux/sagas.js
import { put, call } from "redux-saga/effects";
// import { UpdateAPI } from "../components/Config/Apiinterface";
import { GetAPI, putRequestAPI } from "../components/Config/Apiinterface";
import { assignQuestionSuccess, assignQuestionFailure } from "../Actions";
import { toast } from "react-toastify";
import debounce from "../Utils/useDebounce";
import {
  getAssinedQuestionFailure,
  getAssinedQuestionSuccess,
} from "../Actions/AssignQuestionToUser";

export function* getAssignedQuestionSaga(action) {

  const token = action.payload.token;
  const body = action.payload.data;

  const path = `admin/quiz/player/assignedQuestionList?quizId=${body?.quizId}&userId=${body?.userId}`;


  try {
    const result = yield debounce(GetAPI, token, path);
    yield put(getAssinedQuestionSuccess(result));
    if (result.statusCode === 200) {
      // toast.success(result.message);
    } else {
      toast.warning(result.message);
    }
  } catch (error) {
    yield put(getAssinedQuestionFailure(error));
  }

}

export default getAssignedQuestionSaga;