import { call, put } from "redux-saga/effects";
import { GetAPI } from "../../components/Config/Apiinterface";
import debounce from "../../Utils/useDebounce";
import { getBannerDataFailure, getBannerDataSuccess } from "../../Actions/settings/GetBannerDataAction";

export function* getBannerDataSaga(action) {
  const token = action.payload;
  const path = `admin/banner`;
  try {
    const response = yield debounce(GetAPI, token?.token, path);
    yield put(getBannerDataSuccess(response));
  } catch (error) {
    yield put(getBannerDataFailure(error));
  }
}
