import { ModalOptionsSelector } from "../../components/select/ModalOptionsSelector";
import { quiz_Mode } from "../../components/constant";

export function SelectQuizMode(props) {
  const { topics, categories, handleSortValues, item, disabled } = props;
  return (
    <>
      <ModalOptionsSelector
        id="categoryOptions"
        header="Category"
        initialValue={
          item?.category?.name ? item?.category?.name : "Select Category"
        }
        options={categories}
        callbackFn={handleSortValues}
        content="The category under which this quiz will be created."
        place="right"
      />
      <ModalOptionsSelector
        id="topicOptions"
        header="Topic"
        initialValue={item?.topic?.name ? item?.topic?.name : "Select Topic"}
        options={topics}
        callbackFn={handleSortValues}
        place="bottom"
        content="The topic under which this quiz will be created."
      />

      {!disabled && (
        <ModalOptionsSelector
          id="quizModeOptions"
          header="Quiz-mode"
          options={quiz_Mode}
          callbackFn={handleSortValues}
          place="bottom"
          content="There are 4 quiz modes available:<ul><li>1.Winner Takes All: Only one winner (Rank 1) will be declared.</li><li>2.Classic: The number of winners will be half of the filled spots.</li><li>3. Mega Contest: The number of winners will be half of the filled spots.</li><li>4.Equal Distribution: Everyone will be a winner.</li></ul>Note:<br/><ul><li>1. Only users with at least one correct answer can be winners. </li><li> 2. The number of winners may be less than expected as it depends <br/>on the users' correct answering probability.</li>3. If users have the same points and took the same amount of time to <br/>answer, their rank will be different, but the prize amount will be <br/>distributed equally. For example, if 3 users win, and the top 2 <br/>have the same points and rank, the prize for Rank 1 and Rank 2 <br/>will be added together, divided by 2, and awarded equally to both.</li></ul>"
        />
      )}
    </>
  );
}
