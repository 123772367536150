import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../../Utils/Constants";
import { getReviewTimeRequest, updateReviewTimeRequest } from "../../Actions";
import { MdModeEditOutline } from "react-icons/md";
import { PasswordModal } from "../../components/modal/passwordModal";
import {
  notificationLocalReset,
  resetCustomerSupport,
  resetQuestionLocalFilters,
  resetQuizLocalFilters,
  resetUserLocalFilters,
  transactionResetLocalData,
} from "../../components/constant";
import Loading from "../../components/loading";
import { checkAccess } from "../../Utils/RoleBasedRoute";
import { Tooltip as ReactTooltip } from "react-tooltip";
import TooltipATM from "../../components/ui-elements/TooltipATM";

const ReviewTime = ({ reviewTime, updateReviewTime }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [rateArray, setRateArray] = useState({});
  const [updateReviewTimeModel, setOpenUpdateReviewTimeModel] = useState(false);
  const encrypted =
    sessionStorage.getItem("myKey") || localStorage.getItem("myKey");
  const token = CryptoJS.AES.decrypt(encrypted, SECRET_KEY).toString(
    CryptoJS.enc.Utf8
  );

  const tableHeaders = ["Id", "Review Time", "Update Date", "Edit"];

  const onSubmit = (item) => {
    const body = {
      data: {
        reviewTime: item,
      },
      token: token,
    };
    dispatch(updateReviewTimeRequest(body));
  };

  const getReviewTime = () => {
    const body = {
      token: token,
    };
    dispatch(getReviewTimeRequest(body));
  };

  useEffect(() => {
    getReviewTime();
  }, [updateReviewTime]);

  useEffect(() => {
    if (
      reviewTime?.reviewTime?.statusCode === 403 ||
      updateReviewTime?.updateReviewTime?.statusCode === 403
    ) {
      window.location.href = "/";
    }
    if (reviewTime?.loading) {
      setIsLoading(true);
    }
    setTimeout(() => {
      if (reviewTime?.loading === false) {
        setFormOpen(false);
      }
    }, [1000]);

    if (reviewTime?.error) {
      setIsLoading(false);
    }

    if (reviewTime?.reviewTime.data) {
      setRateArray(reviewTime?.reviewTime.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [reviewTime, updateReviewTime]);

  useEffect(() => {
    resetQuestionLocalFilters();
    resetUserLocalFilters();
    resetQuizLocalFilters();
    resetCustomerSupport();
    transactionResetLocalData();
    notificationLocalReset();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[80vh]">
          <div className="font-semibold text-center pt-10">
            Something went Wrong. Please try again
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="bg-[#e8f5e9] min-h-[100vh] px-2 md:px-14 pt-14 md:pt-20 flex flex-col gap-3 select-none">
        <div className="bg-white p-4 shadow-md rounded-lg min-h-[55vh] h-[85vh] gap-2">
          <div className="flex justify-between items-center">
            <div className="font-bold uppercase text-lg text-center">
              Review Time
            </div>
            <TooltipATM
              dataTooltipId={"version-tooltip"}
              dataTooltipPlace={"left"}
              dataTooltipHtml={
                "After the quiz goes live and its duration ends, it will enter review mode, <br/> The quiz will remain in review mode for the duration specified here, in minutes"
              }
              className={" cursor-pointer"}
            />
          </div>
          <>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-3">
              <table className="w-full text-sm text-left text-gray-700 ">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                  <tr>
                    {tableHeaders?.map((item, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="px-6 py-3 text-center"
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b " key={rateArray.id}>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.id}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {rateArray.reviewTime}{" "}
                          {rateArray.reviewTime === 1 ? "min" : "mins"}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>{rateArray.updateDate}</div>
                      </div>
                    </td>
                    <td className="px-6 py-2 text-center">
                      <div>
                        <div>
                          {" "}
                          <div className="flex gap-x-3 justify-center">
                            <div
                              className={`hover:bg-gray-100 p-2 rounded-full ${
                                !checkAccess("REVIEWTIME", "U")
                                  ? "accessDenied"
                                  : "hover:cursor-pointer"
                              }`}
                              onClick={() => {
                                if (checkAccess("REVIEWTIME", "U")) {
                                  setOpenUpdateReviewTimeModel(true);
                                }
                              }}
                            >
                              <MdModeEditOutline className="text-2xl" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        </div>
      </div>
      {updateReviewTimeModel && (
        <PasswordModal
          heading={"Update Review Time"}
          nameLabel={"Enter Time (mins)"}
          submitLabel={"Save the Changes"}
          submitLabelsm={"Save Changes"}
          handleSubmit={onSubmit}
          openModal={updateReviewTimeModel}
          setOpenModal={setOpenUpdateReviewTimeModel}
          flage={"review"}
          prevValue={`${rateArray.reviewTime}`}
        />
      )}
      <ReactTooltip
        style={{ fontSize: "12.5px" }}
        id="version-tooltip"
        className=" font-semibold"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    reviewTime: state?.reviewTimeReducer,
    updateReviewTime: state?.updateReviewTimeReducer,
  };
};

export default connect(mapStateToProps)(ReviewTime);
